/* eslint-disable */

import type { BankLang } from "../types/BankLang";
import { translateTempImpl } from "../TranslatableLanguage";

export function BankLangDef(): BankLang {
  return {
    translateTemp: (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB),
    
    AccountBalance: "Kontobalans",
    AccountDetails: "Kontoinformation",
    AccountDetailsModal_Description_Bankgiro: "För över pengar till det här kontot genom att använda ditt egna bankgironummer. Om din bank kräver ett OCR-nummer, använd {0}.",
    AccountDetailsModal_Description_SeClearingAccount: "För över pengar till det här kontot genom att använda ditt egna bankkontonummer.",
    AccountDetailsModal_Description2: "Det kan ta 1-3 bankdagar innan din överföring visas på kontot. Om du har några frågor eller behöver hjälp, vänligen ",
    AccountDetailsModal_ScanQrCode: "Skanna QR-koden med din bank-app",
    AccountNumber: "Kontonummer",
    AccountOwner: "Kontoägare",
    ActivateAndRenewYourNewCard: "Aktivera ditt förnyade kort",
    ActivateBokioBusinessAccount: "Ansök om Bokio Företagskonto",
    ActivateBokioBusinessAccountToStartUsingCards: "Aktivera Bokio Företagskonto för att börja använda kort i Bokio!",
    ActivateBusinessAccount_Modal_AdditionalChecksNotice: "Läs igenom noggrant och försäkra dig om att allt nedanför är korrekt.",
    ActivateBusinessAccount_Modal_Description: "Vi behöver verifiera att informationen om dig och ditt företag stämmer. Öppna din BankID-app för att logga in.",
    ActivateBusinessAccount_Modal_NotShared: "Jag är den enda som har tillgång till mitt konto med användarnamn {0}.",
    ActivateBusinessAccount_Modal_OwnerOfEmail: "Jag bekräftar att jag är ägaren av {0} och att mitt personnummer är {1}.",
    ActivateBusinessAccount_Modal_RealCompany: "{0} med organisationsnummer {1} är inte ett testföretag.",
    ActivateBusinessAccount_Modal_RealCompany_Tooltip: "Efter du aktiverat Bokio Företagskonto på detta företag kommer du inte kunna aktivera det igen för organisation {0}",
    ActivateCard: "Aktivera kort",
    ActivatedOn: "Aktiverades den",
    ActivateInternationalPayments: "Aktivera internationella betalningar",
    ActivateWithBankId: "Aktivera med BankID",
    ActivateYourAccount: "Aktivera ditt konto",
    ActivateYourCard: "Aktivera ditt kort",
    ActiveBusinessCards: "aktiva företagskort",
    ActiveBusinessCards_P: "aktiva företagskort",
    ActiveBusinessCards_S: "aktivt företagskort",
    AddBeneficiary: "Lägg till verklig huvudman",
    AddPayment_AddPayment: "Lägg till betalning",
    AddPayment_CategoryOrConsumerIsNotSupported: "Denna betalningstyp eller användare stöds inte",
    AddPayment_CountryAndCurrency: "Land och valuta",
    AddPayment_PaymentMode: "Betalningssätt",
    AddPayment_PaymentNumber: "{0} nummer",
    AddReceiver: "Lägg till mottagare",
    AddSender: "Lägg till avsändare",
    AllowContactless: "Tillåt kontaktlös betalning med blipp",
    ApplyToBokioBusinessAccountModal_Description: "För att kunna ansluta till **{0}** måste du först ansöka om **Bokio Företagskonto**.\n\nMed Bokio Företagskonto får du integrerat bankkonto, företagskort och andra banktjänster som gör att du kan betala leverantörer och anställda, ta betalt av kunder, hantera överföringar till Skatteverket och mycket mer, direkt i Bokio. Företagskontot ingår i din prisplan.",
    ApplyToBokioBusinessAccountModal_Title: "För {0} krävs Bokio Företagskonto",
    ApprovedBy: "Godkänd av",
    AtmWithdrawals: "Uttag på bankomat",
    Audit_MultipleWarnings: "Betalningen har flera problem",
    AvailableAmount: "Tillgängligt belopp",
    AvailableBalance: "Tillgängligt saldo",
    Bank_PageTitle: "Bank och skattekonton",
    BankAccount: "Bankkonto",
    BankAccount_Accounts: "Konton",
    BankAccountDetails: "Bankkontoinformation",
    BankAccountType: "Typ av bankkonto",
    BankAccountType_Credit: "Kreditkort",
    BankAccountType_Depository: "Kapitalkonto",
    BankAccountType_Depot: "Depå",
    BankAccountType_Investment: "Investkonto",
    BankAccountType_Loan: "Lån",
    BankAccountType_Other: "Okänt",
    BankAccountType_Saving: "Sparkonto",
    BankConnect_AvailableBanks: "Se tillgängliga banker",
    BankConsent_Accepted: "Accepterad",
    BankConsent_ConsentStatus: "Status",
    BankConsent_Description: "Ifrågavarande företag som jag företräder (”Bolaget”) uppdrar härigenom Svea Bank AB (\"Svea\") att fortlöpande dela information om Bolagets bankkonton hos Svea (inklusive betalnings- och transaktionsrelaterad data) med Bokio AB (\"Bokio\") (\"Uppdraget\").\n\nDetta Uppdrag är giltigt från och med dagens datum, till dess att Uppdraget återkallas genom att Svea tagit emot skriftligt meddelande därom från en behörig företrädare för Bolaget.\n\nEn återkallelse av Uppdraget kan påverka Bokios möjligheter att tillhandahålla vissa tjänster som är integrerade i Bokios bokföringstjänst till Bolaget. Exempelvis kan det innebära att Bokio inte kan tillhandahålla tjänster för:\n- att lägga till betalningar för leverantörsfakturor, löner eller utgifter.\n- automatisk registrering av transaktioner.\n- tillägg av kvitto kopplat till betalningar.\n\nGenom att välja \"Ja\" nedan (i) uppdras Svea att utföra Uppdraget, (ii) bekräftar Bolaget att det har tagit del av och godkänner samtliga villkor för Uppdraget och (iii) bekräftar jag att jag har erforderlig behörighet att företräda Bolaget.",
    BankConsent_GiveConsent_RadioLabel: "Ja - jag bekräftar att jag lämnar Uppdraget till Svea",
    BankConsent_Revoke_Warning: "Varning: En återkallelse av Uppdraget kan påverka Bokios möjligheter att tillhandahålla vissa tjänster som är integrerade i Bokios bokföringstjänst.",
    BankConsent_RevokeConsent_RadioLabel: "Nej - jag lämnar inte Uppdraget till Svea. Jag är medveten om att detta innebär att Bokio inte kan tillhandahålla alla tjänster",
    BankConsent_Revoked: "Ej accepterad",
    BankgiroNumber: "Bankgironummer",
    BankgiroNumberInInboxMessage: "Om du har ett bankgironummer så har det skickats från Svea Bank till din",
    BankId_Cancelled_ErrorMessage: "Authentiseringen via BankID avbröts",
    BankIdPolling_CancelledByUser: "Bank id signeringen avbröts",
    BankIdPolling_Rejected: "Bank id signeringen misslyckade",
    BankIdPolling_UnHandledPollStatus: "Ett ohanterat fel inträffade, försök igen eller kontakta supporten",
    BankInbox_DownloadAttachment: "Ladda ned bilaga",
    BankInbox_EmptyStateMessage: "Här kommer du ta emot meddelanden gällande din bank",
    BankInbox_MarkAsRead: "Markera som läst",
    BankInbox_Title: "Bankinkorg",
    BankPaymentRecipientInfo_ClearingNumberToolTip_InputField: "Clearingnummer är det nummer som identifierar den bank och det kontor som ett konto tillhör (skrivs oftast före kontonumret, förutom för Nordea personkonto där det inte används alls).",
    BankPreregistrations_Application: "Ansökan",
    BankPreregistrations_ApplicationInProgressNotice_Title: "Du har en pågående ansökan. Du måste skicka in den innan du kan publicera en ny.",
    BankPreregistrations_ApplicationType: "Ansökningstyp",
    BankPreregistrations_Apply: "Ansök",
    BankPreregistrations_CheckCompleted_Cta: "Uppdatera status",
    BankPreregistrations_CheckCompleted_Info: "Du har {0} ansökning(ar) i granskning. Kolla om dessa är klara.",
    BankPreregistrations_CompleteApplication: "Gör klart ansökan",
    BankPreregistrations_EntityType: "Organisation eller person?",
    BankPreregistrations_EntityType_Organisation: "Organisation",
    BankPreregistrations_EntityType_PrivatePerson: "Privatperson",
    BankPreregistrations_HowToFix_Application: "Om informationen i ansökan är fel behöver du ta bort denna och sedan skicka en ny ansökan med rätt information",
    BankPreregistrations_HowToFix_SupplierOrRecipient: "Om leverantören eller mottagaren är fel kan du redigera denna från betalflödet så den matchar ansökan",
    BankPreregistrations_HowToFix_Title: "Hur du fixar till så de matchar",
    BankPreregistrations_IbanAlreadyRegistered: "Detta Iban-nummer var redan sparat",
    BankPreregistrations_Matches_Heading: "Matchande mottagare",
    BankPreregistrations_MustSyncFirst: "Ansökningarna har inte synkats nyligen. Innan du raderar denna behöver du göra klart pågående ansökningar.",
    BankPreregistrations_ReceiverInfo: "Konton du kan skicka internationella betalningar till",
    BankPreregistrations_RegisterCreditor: "Registrera mottagare",
    BankPreregistrations_Title: "Internationalla mottagare/avsändare",
    BankPreregistrations_WaitingForAml: "Behöver svar",
    BankPreregistrations_WaitingForSvea: "I granskning",
    BankReconnectWarning: "Din bankkoppling till {0} har gått ut. Vänligen återanslut för att fortsätta hämta transaktioner.",
    BBA_BankTransactions_OutOfSync_NoticeContent: "Utan ditt medgivande kan vi inte erbjuda dig alla våra smarta funktioner. I det här fallet innebär det att vi inte kan hämta dina transaktioner för att ge dig en överblick.",
    BBA_BankTransactions_OutOfSync_NoticeTitle: "Dina transaktioner med Bokio Företagskonto är inte synkade!",
    Bba_ContactSupport: "Behöver du hjälp får du gärna {kontakta vår support}.",
    BBA_NewBank_AccountNumber_Text: "Nytt bankkontonummer",
    Bba_OrderMastercard: "Beställ företagskort",
    BBA_SveaOnboarding_Consent_Header: "Uppdrag att dela kontoinformation",
    BBA_SveaOnboarding_ContactDetails_Description: "Ange namn och telefonnummer till kontaktperson. Uppgifterna kommer att användas av Svea Bank och i enlighet med deras integritetspolicy.",
    BBA_SveaOnboarding_ContactDetails_Header: "Kontaktuppgifter",
    BbaBanner_Campaign_StartBonusDescription: "startbonus",
    BbaBannerFeatures_AvailableCountries: "tillgängliga länder",
    BbaBannerFeatures_Bankgiro: "Bankgiro",
    BbaBannerFeatures_Businesscard: "Företagskort",
    BbaBannerFeatures_CapitalAccount: "Kapitalkonto",
    BbaBannerFeatures_CashBack: "{0}% cashback på kortköp och {1}% återbäring på ditt kontosaldo {2}",
    BbaBannerFeatures_CashBack_PricePlan: "(för {0})",
    BbaBannerFeatures_OutgoingInternationalPayments: "Internationella utgående betalningar {0}",
    BbaBannerFeatures_SeeCountries: "(se {0})",
    BbaBannerFeatures_Swish: "Swish Företag {0}",
    BbaBannerFeatures_SwishFee: "(fri månadsavgift {0} under 2024)",
    BbaBannerFeatures_ThisIsBba: "Det här är Bokio Företagskonto:",
    BbaBannerFeatures_TransactionAccount: "Transaktionskonto",
    BbaBannerFeatures_Upcoming: "Kommande:",
    BbaMigrationChooseBookkeeping_AccountActivated_NoticeDescription: "Välj det bokförings-/betalkonto du vill koppla till ditt nya bankkonto.",
    BbaMigrationChooseBookkeeping_AccountActivated_NoticeTitle: "Ditt Bokio Företagskonto har aktiverats",
    BbaMigrationChooseBookkeepingAccount_Description: "Eftersom du ska byta bank rekommenderar vi att du använder ett annat konto än det du använde för ditt tidigare företagskonto. Att använda till exempel 1932 istället för 1930 spelar ingen roll för din bokföring, kontot fungerar på samma sätt som 1930. Den enda skillnaden är den sista siffran.",
    BbaNotAvailableInFreePlanModal_Content_1: "I samband med uppdateringen av våra prisplaner ingår inte längre Bokio Företagskonto som gratisfunktion. För att behålla ditt företagskonto behöver du uppgradera till {Premium}.",
    BbaNotAvailableInFreePlanModal_Content_2: "Vill du inte uppgradera måste du tömma ditt företagskonto senast {31 januari 2023}. Därefter kommer företagskontot spärras och inte längre vara möjligt att använda. Skulle du ha pengar kvar på ditt Bokio Företagskonto efter den 31 januari och Bokio behöver hantera utbetalning av saldo, förbehåller sig Bokio rätten att betala medel överstigande 100 kr till ditt skattekonto. Medel under 100 kr kommer inte att betalas ut. {Läs mer här}.",
    BbaNotAvailableInFreePlanModal_Title: "Uppgradera din plan för att behålla Bokio Företagskonto",
    BBAOnboarding_SveaConfirmAndSignYourAgreement_BoxDescription: "Ladda hem och läs igenom avtalet innan du signerar. För att hålla ditt konto säkert så kommer du alltid behöva logga in med BankID när din företagsinformation är verifierad.",
    BBAOnboarding_SveaConfirmAndSignYourAgreement_BoxTitle: "Bekräfta och signera ditt avtal",
    BBAOnboarding_SveaConfirmAndSignYourAgreement_TermsAndConditions: "Jag har läst och förstått avtalet.",
    BbaOnboardingPopoverContent_Description: "Bokio Företagskonto gör att du kan ta betalt, betala och automatiskt bokföra fakturor och löner direkt i Bokio. För att kunna börja använda Bokio Företagskonto måste du aktivera det.",
    BbaPromotionVideoEmbedPath: "https://www.youtube.com/embed/HA4gZEgqILM?rel=0&amp;enablejsapi=1",
    Bic: "Bic",
    BillingOfService: "Betalning av tjänst",
    BlockCard: "Spärra kortet",
    BlockedColumnName_AccountNumber: "Kontonummer",
    BokioBusinessAccount: "Bokio Företagskonto",
    BokioBusinessAccount_Svea_Activate_Button: "Påbörja ansökan",
    BokioBusinessAccount_Svea_Activate_ComplianceCaption: "Bokio Företagskonto tillhandahålls av Svea Bank AB (publ). I samband med att du ansöker om Bokio Företagskonto kommer dina personuppgifter att delas med Svea och en kreditprövning kommer att utföras. Svea är personuppgiftsansvarig för de personuppgifter som samlas in och används vid tillhandahållandet av Bokio Företagskonto.",
    BokioBusinessAccount_Svea_Activate_Step1_Description: "Kontrollera dina uppgifter och välj om du vill inkludera bankgiro.",
    BokioBusinessAccount_Svea_Activate_Step1Migration_Description: "Kontrollera att dina företagsuppgifter stämmer.",
    BokioBusinessAccount_Svea_Activate_Step2_Description: "Ange kontaktuppgifter och signera ditt avtal med BankID.",
    BokioBusinessAccount_Svea_Activate_Step3_Description: "Besvara frågor om dig och ditt företag.",
    BokioBusinessAccount_Svea_Activate_Step4_Description: "Har du valt att inkludera bankgiro får du nu möjlighet att flytta ett redan befintligt eller skaffa ett nytt.",
    BokioBusinessAccount_Svea_Activate_Step4Migration_Description: "När ansökan blivit godkänd får du aktivera ditt konto. Pengarna kommer automatiskt flyttas över inom 1-2 bankdagar.",
    BokioBusinessAccount_Svea_Activate_Step5_Description: "När din ansökan är godkänd av Svea kan du koppla företagskontot till ett bokförings-/betalkonto.",
    BokioBusinessAccount_Svea_Activate_Step5Migration_Description: "Koppla ditt företagskonto till ett nytt bokförings-/betalkonto.",
    BokioBusinessAccountMigrationScene_AccessDenied: "Uppgradering av Bokio Företagskonto kan bara göras av användaren som kan signera betalningar i nuvarande Bokio Företagskonto.",
    BokioBusinessAccountMigrationScene_PageTitle: "Uppgradera Bokio Företagskonto",
    BokioBusinessAccountSvea_Activation_Description: "Att ansöka om företagskonto i Bokio går både snabbt och är smidigt utan pappersarbete eller några uppstartskostnader. Det enda du behöver göra är att klicka på knappen nedan och sedan göra följande steg:",
    BokioBusinessAccountSvea_Migration_ComplianceCaption: "I samband med att din ansökan om att uppgradera ditt Bokio Företagskonto blir godkänd avslutas även ditt nuvarande Bokio Företagskonto och ditt avtal med Bokio AB om finansiella tjänster upphör.",
    BokioBusinessAccountSvea_Migration_Description: "För att få tillgång till de nya tjänsterna i Bokio Företagskonto inkl. Mastercard debetkort behöver du göra en ny ansökan för ditt företag. Du behåller ditt bankgironummer (det flyttas med automatiskt) och när din ansökan blivit godkänd kommer saldot på ditt nuvarande företagskonto överföras till det nya bankkontot.",
    BokioBusinessAccountSvea_Migration_FirstStepBlock_Title: "Ansökan för Bokio Företagskonto:",
    BokioBusinessAccountSvea_Migration_SecondStepBlock_Title: "Aktivera och för över pengarna till ditt nya Bokio Företagskonto:",
    BokioBusinessAccountSveaReadyToOnboardBlock_Description: "Med Bokio Företagskonto får du ett integrerat bankkonto, företagskort och andra banktjänster som gör att du kan sköta ekonomin lika enkelt som din bokföring. Betala leverantörer och anställda, ta betalt av kunder, hantera överföringar till Skatteverket och mycket mer, direkt i Bokio. **Bokio Företagskonto ingår i din prisplan.**\n",
    BokioBusinessAccountSveaReadyToOnboardBlock_Header_Badge_Text: "Nytt: Swish Företag",
    BokioBusinessAccountSveaReadyToOnboardBlock_StartedByAnotherUser: "En annan användare har redan börjat aktiveringen av Bokio Företagskonto",
    BokioBusinessAccountSveaReadyToOnboardBlock_Title: "Ansök om Bokio Företagskonto",
    BokioCard: "Bokiokort",
    BokioCards: "Bokiokort",
    Cancelled: "Avbruten",
    CancelOnboarding_ConfirmModal_Description: "Är du säker på att du vill avsluta den pågående registreringen för Bokio Företagskonto?",
    CancelOnboarding_ConfirmModal_LeaveFeedback: "Skriv gärna varför du vill avsluta din pågående registrering för Bokio Företagskonto (frivilligt)",
    CancelOnboarding_ConfirmModal_Title: "Avsluta registrering för Bokio Företagskonto",
    CancelOrder: "Avbryt beställning",
    CancelPayment: "Avbryt betalning",
    CancelPayment_ConfirmMessage_ConfirmationModal: "Är du säker på att du vill avbryta de valda betalningarna?",
    CancelSetup: "Avsluta registrering",
    Card: "Kort",
    Card_ActivateAndRenew: "Aktivera och förnya",
    Card_InRenewal_NoticeInfo: "Ditt nya kort är på väg! Du kan aktivera och förnya det när det levererats. Inte fått ditt kort ännu? {Kontakta support}",
    Card_Mask: "Kort - xxxx {0}",
    Card_ReplaceCard_ConfirmModalDone: "Ditt kort är nu ersatt med ett nytt kort. Din kortbeställning är bekräftad och kommer att levereras till din valda adress inom 5-7 arbetsdagar.",
    CardDeliveredTo: "Kortet levererades till",
    CardDeliveryPrompt_Description_1: "Kort levereras vanligtvis inom 2 veckor.",
    CardDeliveryPrompt_Description_1_Renewal: "Ditt nya kort är på väg! Du kan aktivera ditt förnyade kort när det har levererats.",
    CardDeliveryPrompt_Description_2: "Ej mottaget ännu?",
    CardDetails: "Kortdetaljer",
    CardDetailsActivateSection_CardHoldersPhoneNumber_InputFieldLabel: "Kortinnehavarens telefonnummer",
    CardDetailsActivateSection_CardHoldersPhoneNumber_InputFieldToolTip: "Telefonnumret kommer kopplas till kortet och PIN-koden kommer skickas via SMS.",
    CardDetailsActivateSection_LastFourDigitsOfCard_InputFieldLabel: "Skriv in de sista 4 siffrorna från kortet du fick",
    CardDetailsSecuritySettingsSection_ContactSupportDescription: "Vill du veta mer om hur du använder ditt kort? Behöver du beställa ett nytt? Få svar på frågor om ditt Bokiokort i vår {hjälpguide}. Behöver du mer hjälp kan du {kontakta vår support}.",
    CardHolderName: "Kortinnehavarens namn",
    CardIsAlreadyOrdered: "Kortet är redan beställt.",
    CardLimitCurrencyWeek: "Kortgräns ({0}/vecka)",
    CardListScene_CannotOrderCardBecauseActiveUserIsNotTheOnboardingUser_EmptyState: "Än så länge kan bara användaren som verifierade företagsuppgifterna för Bokio Företagskonto beställa kort. Vi kommer att möjliggöra för fler användare framöver.",
    CardOrder_Success_Info: "Vi har mottagit din order och kommer leverera kortet till din folkbokföringsadress inom 2 veckor.",
    Cards_CardDelivery: "Kortet kommer att levereras till:",
    CardStatus: "Kortstatus",
    CardWillBeDeliveredTo: "Kortet kommer levereras till",
    Category: "Kategori",
    ChangePinCode: "Byt PIN-kod",
    ChangeReceipt: "Byt kvitto",
    ChooseAccountAndRecipient: "Välj konto och mottagare",
    ChooseBookkeepingAccount_AccountWillBeCreatedLater: "Bokföringskonto {0} kommer skapas när ditt företagskonto är aktiverat. Du kan sedan se och ändra inställningar för kontot under Inställningar och Betalkonton.",
    ChooseBookkeepingAccount_Description: "Är 1930 upptaget kommer vi att rekommendera ett annat konto, som exempelvis 1932. Att använda 1932 istället för 1930 spelar absolut ingen roll för din bokföring, det är ett konto i samma kontoklass som 1930. Det enda som skiljer är slutsiffran.",
    ChooseCardName: "Välj namn som skall trycker på kortet",
    ChooseCompanyName: "Välj företagsnamn",
    ChooseOrCreateNewRecipient: "Välj eller skapa en ny mottagare",
    CompanyCard: "Företagskort",
    CompanyName: "Företagsnamn",
    CompanyToggle: "Slå på för att få företagsnamnet tryckt på kortet.",
    CompletedTransactions: "Färdiga transaktioner",
    ConfirmCardActivationModal_Success_Description: "Ditt kort är aktiverat och redo att användas.",
    ConfirmPaymentCancelation: "Bekräfta avbokning av betalning",
    ConfirmTerminateCard: "Bekräfta avslutande av kort",
    ConfirmWithBankId: "Bekräfta med BankID",
    ConnectAccount: "Koppla konto",
    ConnectedAccount: "Kopplat konto",
    ConnectToBankBlock_Description: "Med bankkoppling håller du din bokföring uppdaterad, får stenkoll på dina konton och sparar tid. Alla transaktioner hämtas automatiskt till din Att göra, redo att bokföras med några få klick.",
    ConnectToBankBlock_Perk1: "Säker direktkoppling till din bank",
    ConnectToBankBlock_Perk2: "Bokför flera transaktioner samtidigt",
    ConnectToBankBlock_Perk3: "Skapa egna regler för hur transaktioner ska bokföras",
    ConnectToBankBlock_Perk4: "Undvik misstag och missa aldrig några transaktioner",
    ConnectToBankBlock_Perk5: "Ingen begränsning i antal kopplingar",
    ConnectToBankBlock_ReadMore: "Läs mer om att koppla bank",
    ConnectToBankBlock_ReadMore_Link: "https://www.bokio.se/hjalp/bokio-foretagskonto-bankkoppling/bankkoppling/automatisk-bankkoppling/",
    ConnectToBankBlock_UserCountBadge: "{0} användare har kopplat sin bank",
    ConnectYourAccount: "Välj bokföringskonto",
    ConnectYourNewBokioBusinessAccount_Heading: "Koppla ditt Bokio Företagskonto",
    ConnectYourNewBokioBusinessAccount_WithoutConsent: "Varning: En återkallelse av Uppdraget kan påverka Bokios möjligheter att tillhandahålla vissa tjänster som är integrerade i Bokios bokföringstjänst.",
    ConnectYourNewBokioBusinessAccount_WithoutConsent_Paragraph: "För att slutföra uppgifterna och få tillgång till alla integrationer direkt i Bokio behöver du antingen uppdatera ditt medgivande eller gå till Svea Bank.",
    ContinueActivation: "Fortsätt aktivering",
    ContinueBbaKycApplication_TodoGroupName: "BbaMigration_TodoGroupName",
    ContinueWithBankID: "Fortsätt med BankID",
    CopyAccountNumber: "Kopiera kontonummer",
    CopyClearingNumber: "Kopiera clearingnummer",
    CopyIban: "Kopiera IBAN",
    CountryOfCitizenship: "Medborgarskap",
    CountryOfResidence: "Hemland",
    CreateInternationalPaymentForm_Bic_InputField: "BIC (Swift)",
    CreateInternationalPaymentForm_BIC_RadioField: "BIC (tidigare kallat Swift)",
    CreateInternationalPaymentForm_BicToolTip_InputField: "Mottagarbankens identifieringskod (tidigare kallad Swift-address). Koden visar vilken bank din betalning går till. Ange BIC koden om du kan den och klicka OK. Om du inte kan den klicka på Sök BIC kod. BIC koder är obligatoriska för utlandsbetalningar.",
    CreateInternationalPaymentForm_EnterAmount_InputField: "Ange summa ({0})",
    CreateInternationalPaymentForm_IbanToolTip_InputField: "IBAN, eller International Bank Account Number, är en internationell metod för att ange bankkontonummer som används för SEPA betalningar till andra länder.",
    CreateInternationalPaymentForm_RecipientDetails_BoxContentTitle: "Detaljer för mottagare",
    CreatePaymentScene_AddSignAndPay_Button: "Lägg till, signera och betala",
    CreatePaymentScene_AddToPayment_Button: "Lägg till betalning",
    CreatePaymentScene_BankgiroPlusgiro_RadioField: "Bankgiro/Plusgiro",
    CreatePaymentScene_CountryAndCurrency_BoxContentTitle: "Land och valuta",
    CreatePaymentScene_MoreCountryCommingSoon: "Fler länder kommer senare.",
    CreatePaymentScene_PageTitle: "Ny betalning eller överföring",
    CreatePaymentScene_ReceivingCountry_SelectField: "Mottagarens land",
    CreatePaymentScene_SaveThisRecipient: "Spara denna mottagare för att använda senare",
    CreatePaymentScene_SaveThisSupplier: "Spara denna leverantör",
    CreatePaymentSuccess_SupplierInvoicePaymentAdded: "Din betalning av leverantörsfaktura är redo att signeras.",
    CreatePaymentSuccess_SupplierInvoicePaymentScheduled: "Din leverantörsfaktura är nu schemalagd för betalning.",
    CreatePaymentWithNewRecipient: "Betalning/överföring till ny mottagare",
    CreateSwedishPaymentForm_giroNumber_InputField: "Bankgiro/Plusgiro nummer",
    CreateSwedishPaymentForm_MessageToolTip_InputField: "Ibland används 'meddelande' istället för OCR-nummer. Det kan bestå av kund-, referens- eller beställningsnummer. Säkerställ att mottagaren kan knyta betalningen till ditt företag med hjälp av meddelandet.",
    CreateSwedishPaymentForm_OcrNumberToolTip_InputField: "OCR-nummer består av max 25 siffror och innehåller information om betalningen. ",
    CreateSwedishPaymentForm_ReferenceForReceiver_InputField: "Text för mottagare",
    CustomiseCard: "Anpassa kort",
    DeleteBeneficiary: "Ta bort verklig huvudman",
    DeleteRecipient: "Ta bort mottagare",
    DeleteRecipient_ConfirmMessage: "Är du säker på att du vill ta bort denna mottagare?",
    EditBeneficiary: "Redigera verklig huvudman",
    EnableBanking_BankAccountConnectionRow_NeedsReconnection: "Behöver återanslutas",
    EnableBanking_BankAccountSelectModal_Description: "Välj de bankkonton som du vill koppla till Bokio.",
    EnableBanking_BankAccountSelectModal_Title: "Välj bankkonto(n)",
    EnableBanking_MigrationNotice_Content: "Bokio använder nu autentiseringstjänsten Enable Banking istället för Klarna. Bankkopplingen fungerar precis som tidigare, men med den fördelen att vi nu stödjer fler banker än förut.",
    EnableBanking_MigrationNotice_Title: "Vi går över från Klarna till Enable Banking",
    EnableBanking_SelectBankModal_ContinueButtonText: "Fortsätt autentiseringen",
    EnableBanking_SelectBankModal_PsuType_Business: "Företagskonto",
    EnableBanking_SelectBankModal_PsuType_Personal: "Privatkonto",
    EnableBanking_SelectBankModal_SelectBank: "Välj bank",
    EnableBanking_SelectBankModal_SelectBankPlaceholder: "Sök efter din bank",
    EnableBanking_SelectBankModal_SelectPsuType: "Kontotyp",
    EnableBanking_SelectBankModal_TermsPreview: "Vi använder onlinetjänsten Enable Banking som autentiseringstjänst. Deras tjänst möjliggör en säker och stabil förbindelse med din bank.",
    EnableBanking_SelectBankModal_Title: "Koppla bank",
    EnableBankingBankAccountSelectionModal_ConfirmCancelModal_Description: "Om du avbryter nu kommer inga ändringar att sparas. Det innebär att din bank inte kommer att kopplas till Bokio.",
    EnableBankingBankAccountSelectionModal_ConfirmCancelModal_Title: "Är du säker på att du vill avbryta?",
    ExportBankStatementAsPdfForTerminatedBba: "Exportera kontoutdrag för avslutat Bokio Företagskonto",
    ExportDetailsAsPdf: "Exportera detaljer som PDF",
    ExportStatement_Description: "Exporteras som PDF för den valda datumperioden.",
    ExportStatementAsPdf: "Exportera kontoutdrag som PDF",
    ExportStatementPdfFileName: "Bokio Företagskonto.pdf",
    Faq_Bba_Link: "https://www.bokio.se/hjalp/bokio-foretagskonto-bankkoppling/bokio-foretagskonto/vanliga-fragor-om-bokio-foretagskonto/",
    GetStartedWithBokioBusinessAccount: "Kom igång med Bokio Företagskonto",
    GoToPayments: "Gå till betalningar",
    GotoSveaBank_WithoutConsent_ButtonText: "Gå till Svea Bank",
    GoToTransactions: "Gå till transaktioner",
    GroupPayment_ErrorInPayment_Description: "En eller flera betalningsspecifikationer innehåller fel",
    GroupPayment_ErrorInPayment_Title: "Fel i betalningsspecifikation",
    IBAN: "IBAN",
    InitiateBankIDSign: "Initiera BankID signering",
    InternationalPayments: "Internationella betalningar",
    InternationalPayments_BillingOfService_Description: "Läs mer om kostnader för internationella betalningar under",
    InternationalPurchases: "Köp utomlands",
    InvalidSavedRecipientWarning: "Den valda {0} har felaktig information. Vänligen redigera innan du fortsätter.",
    KlarnaConnectionKnownIssueException_CanNotSyncForSEB_WillContactUserWhenFixed_Description: "Kopplingen till SEB fungerar inte för tillfället. Klarna och SEB arbetar för att lösa detta så snart som möjligt. Vi kontaktar dig när problemet är löst. Om du har några frågor vänligen {kontakta vår support}.",
    KlarnaConnectionKnownIssueException_CanNotSyncForSEB_WillContactUserWhenFixed_Title: "Automatisk bankimport från SEB fungerar för närvarande inte på grund av tekniska problem",
    KYCNext: "När din KYC är granskad och godkänd kan du fortsätta här genom att koppla ditt bokföringskonto till ditt nya Bokio Företagskonto. Vill du vara förberedd för nästa steg? Läs mer om {hur man väljer bokföringskonto}.",
    KYCReviewStatus: "Status för KYC granskning",
    KYCReviewStatus_Description_ApprovedCanConnectBba: "Dina ansökan är godkänd. Vänligen koppla ditt Bokio Företagskonto.",
    KYCReviewStatus_Processing: "Granskas",
    KYCSubmitted: "Tack för att du skickade KYC.",
    LinkedPhoneNumber: "Kopplat telefonnummer",
    LoadReservedTransactions: "Visa reserverade transaktioner",
    LogoutModal_Description: "Din säkra anslutning till ditt Bokio Fötetagskonto håller på att gå ut.\nVill du fortsätta vara inloggad?",
    LogoutModal_TimeRemaining: "Tid kvar {0}",
    LogoutModal_Title: "Sessionen går ut",
    MakePayments: "Göra betalningar",
    ManageCards: "Hantera kort",
    ManualBankImport: "Manuell bankimport",
    ManualPayment: "Manuell betalning",
    MirChangeSignatoryScene_PageTitle: "Byt användare som kan signera betalningar i Bokio Företagskonto",
    MirChangeSignatoryScene_Success: "Kontakta supporten så hjälper vi dig byta användare som kan signera betalningar i Bokio Företagskonto.",
    MirOnboarding_AccountDetails_BoxDescription: "För närvarande kommer det bara vara du som har rätt att signera betalningar och beställa kort i ditt Företagskonto.",
    MirOnboarding_AdditionalQuestionsKYC_StepName: "Några frågor (KYC)",
    MirOnboarding_AgreeUsingBankIdForFutureLogin_Checkbox: "Jag förstår att jag kommer behöva logga in med BankID efter att företagsinformationen är verifierad.",
    MirOnboarding_AllSignatoriesMustBeAtLeast18YearsOld_Error: "Alla firmatecknare måste vara minst 18 år gamla.",
    MirOnboarding_AlreadyOnboarded: "Företaget med organisationsnummer {0} är redan registrerat för Bokio Företagskonto. Om detta är ett problem var god kontakta supporten.",
    MirOnboarding_AlreadyUsingDifferentPersonalId: "Du har använt ett annat personnummer för att registrera dig för Bokio Företagskonto förut. Kontrollera att du använder ditt eget personnummer när du registrerar dig för Bokio Företagskonto.",
    MirOnboarding_AuthenticateWithBankId: "Logga in med BankID",
    MirOnboarding_ChooseYearlyTurnOver_SelectFieldLabel: "Välj årsomsättning",
    MirOnboarding_ConfirmAndSign_StepName: "Bekräfta och signera",
    MirOnboarding_ConfirmAndSignYourAgreement_BoxDescription: "Ladda hem och läs igenom avtalet innan du signerar. För att hålla ditt konto säkert så kommer du alltid behöva logga in med BankID när din företagsinformation är verifierad.",
    MirOnboarding_ConfirmAndSignYourAgreement_BoxDescription_Success: "Du har signerat avtalet.",
    MirOnboarding_ConfirmAndSignYourAgreement_BoxTitle: "Bekräfta och signera ditt avtal",
    MirOnboarding_ConfirmAndSignYourAgreement_InviteOthersToSignTheAgreement: "Bjud in andra att signera avtalet",
    MirOnboarding_ConfirmAndSignYourAgreement_InviteOthersToSignTheAgreement_Description_AfterActiveUserSigned: "Initiera så andra firmatecknare kan signera avtalet. De kommer behöva bekräfta sin identitet med BankID.",
    MirOnboarding_ConfirmAndSignYourAgreement_InviteOthersToSignTheAgreement_Description_BeforeActiveUserSigned: "Du måste själv signera avtalet innan du kan initiera signeringsprocessen för andra.",
    MirOnboarding_EditCompanySettings: "Redigera företagsinställningar",
    MirOnboarding_FailedToCreateMirOrganisation_Error: "Misslyckades med att skapa organisationen",
    MirOnboarding_FailedToSaveKyc_Error: "Misslyckades med att spara kyc data",
    MirOnboarding_GoToCancelActication: "Gå till avsluta aktivering",
    MirOnboarding_InvalidPlan_Free: "Gratis prisplanen är inte tillgänglig på grund av hög efterfrågan. Vänligen välj en annan prisplan för att fortsätta.",
    MirOnboarding_IsInvalidCompanyInfo_Error: "Din företagsinformation överensstämmer inte med officiella uppgifter. Du måste uppdatera dem.",
    MirOnboarding_IsInvalidOrganisationNumber_Error: "Ditt organisationsnummer stämmer inte med officiella uppgifter. Du måste uppdatera det. Om du nyligen startat ditt företag så tar det ofta en dag innan informationen uppdateras hos Bolagsverket. Testa därför gärna imorgon igen och {kontakta support} om det då inte fungerar.",
    MirOnboarding_IsNotSignatory_Error: "Du är inte en av företagets firmatecknare och kan därför inte verifiera företagsinformationen",
    MirOnboarding_Kyc_SubmitCompanyDetails: "Skicka in företagsinformationen",
    MirOnboarding_KycBokioBusinessAccountUsage_BoxDescription: "Välj alla alternativ som passar in på ditt bolag.",
    MirOnboarding_KycBokioBusinessAccountUsage_BoxTitle: "Hur kommer du använda Bokio Företagskonto?",
    MirOnboarding_KycCashSalesPercentageAnswer_From0To25: "Mindre än 25%",
    MirOnboarding_KycCashSalesPercentageAnswer_From26To50: "26% till 50%",
    MirOnboarding_KycCashSalesPercentageAnswer_From51To75: "51% till 75%",
    MirOnboarding_KycCashSalesPercentageAnswer_From76To100: "76% till 100%",
    MirOnboarding_KycCashSalesPercentageQuestion: "Hur stor del av din försäljning sker kontant?",
    MirOnboarding_KycFrequencyAnswer_AFewTimesAYear: "Några gånger per år",
    MirOnboarding_KycFrequencyAnswer_EveryDay: "Varje dag",
    MirOnboarding_KycFrequencyAnswer_EveryMonth: "Varje månad",
    MirOnboarding_KycFrequencyAnswer_EveryWeek: "Varje vecka",
    MirOnboarding_KycFrequencyAnswer_LessOften: "Färre gånger",
    MirOnboarding_KycGeneralQuestions_BoxDescription: "Vi behöver lära känna ditt företag lite bättre innan du börjar använda våra tjänster. Vänligen besvara frågorna nedan ärligt.",
    MirOnboarding_KycGeneralQuestions_BoxTitle: "Allmänna frågor",
    MirOnboarding_KycQuestion_CountryOfResidenceOrCitizenshipNotSweden: "Är du bosatt eller medborgare i ett annat land än Sverige?",
    MirOnboarding_KycQuestion_ForeignTransactions: "Planerar du att använda kontot för betalningar till utlandet?",
    MirOnboarding_KycQuestion_Frequency: "Hur ofta kommer du göra insättningar/uttag?",
    MirOnboarding_KycQuestion_InWhichCountries: "Inom vilka länder?",
    MirOnboarding_KycQuestion_InWhichCountry: "Inom vilket land?",
    MirOnboarding_KycQuestion_IsHandlingCash: "Hanterar ditt företag kontanter?",
    MirOnboarding_KycQuestion_IsLiableToPayTaxInTheUS: "Betalar du skatt i USA?",
    MirOnboarding_KycQuestion_IsPEP: "Är du en politiskt exponerad person?",
    MirOnboarding_KycQuestion_IsResidenceForTaxPurposesNotSweden: "Är din skattehemvist utanför Sverige?",
    MirOnboarding_KycQuestion_IsSellingOutsideEU: "Säljer ditt företag produkter eller tjänster utanför EU/EEA?",
    MirOnboarding_KycQuestion_Turnover: "Vad är din årsomsättning?",
    MirOnboarding_KycQuestionsAboutBeneficiaries_BoxTitle: "Frågor om företagets verkliga huvudmän",
    MirOnboarding_KycTurnoverAnswer_From3MTo30MSek: "3 till 30 miljoner SEK",
    MirOnboarding_KycTurnoverAnswer_LessThan3MSek: "Mindre än 3 miljoner SEK",
    MirOnboarding_KycTurnoverAnswer_MoreThan30MSek: "Mer än 30 miljoner SEK",
    MirOnboarding_KycTurnoverAnswer_NewCompany: "Nytt företag",
    MirOnboarding_MissingAddress_Error: "Saknar företagets adress",
    MirOnboarding_MissingContactInfo_Error: "Saknar information om kontaktperson",
    MirOnboarding_MultipleSignatories_Error: "Vi har idag inte stöd för företag som bara har flera firmatecknare, t.ex. styrelsen. Var vänlig kom tillbaka senare när vi har lagt till stöd för detta.",
    MirOnboarding_NoBeneficialOwner_Error: "Företaget saknar verklig huvudman",
    MirOnboarding_OnboardingCompleted: "Introduktionen färdig",
    MirOnboarding_OrganisationTypeNotSupported_Error: "\nVi stöder för närvarande inte den här företagstypen men arbetar ständigt med att öka supporten. Kom tillbaka senare när vi stöder fler typer.",
    MirOnboarding_PageTitle: "Aktivera Bokio Företagskonto",
    MirOnboarding_QuestionsAbout: "Frågor om",
    MirOnboarding_ReadTermsAndConditions_Checkbox: "Jag har läst och förstått avtalet.",
    MirOnboarding_ReviewDetails_StepName: "Granska detaljer",
    MirOnboarding_ReviewDetailsStepBenefits_Title: "Detta ingår i ditt Bokio Företagskonto:",
    MirOnboarding_ReviewDetailsStepNotSupported_1: "Kontonummer och IBAN",
    MirOnboarding_ReviewDetailsStepNotSupported_2: "Ta emot internationella betalningar",
    MirOnboarding_ReviewDetailsStepNotSupported_3: "Autogiro",
    MirOnboarding_ReviewDetailsStepNotSupported_4: "Uttag från ditt Skattekonto hos Skatteverket",
    MirOnboarding_ReviewDetailsStepNotSupported_Title: "Finns ej stöd för ännu: {hur påverkar det mig?}",
    MirOnboarding_ReviewDetailsStepRequirePaidPlan_1: "Använd Bokios mobileapp för att betala leverantörer, kolla kontoutdrag och få push-notifikationer",
    MirOnboarding_ReviewDetailsStepRequirePaidPlan_2: "Automatisk avprickning och bokföring av fakturor",
    MirOnboarding_ReviewDetailsStepRequirePaidPlan_3: "Betala löner och utlägg direkt från Bokio",
    MirOnboarding_ReviewDetailsStepRequirePaidPlan_Title: "Kräver prisplanen Balans eller Business",
    MirOnboarding_SelectSignatory_BoxDescription: "Enligt Bolagsverkets firmateckningsstruktur ska dessa grupper av avtalstecknare signera för bolaget. Kontrollera så att uppgifterna är korrekta innan du fortsätter. Du kommer inte att kunna ändra detta senare och alla i gruppen behöver signera individuellt. Eftersom du är kontoägare behöver du alltid signera.",
    MirOnboarding_SelectSignatory_BoxTitle: "Vem ska signera för bolaget?",
    MirOnboarding_SelectSignatory_PreDescription: "För att verifiera din företagsinformation behöver du välja firmatecknare, signera avtal och svara på kundkännedom (KYC). När din företagsinformation är verifierad är du redo att börja använda ditt Företagskonto.",
    MirOnboarding_Signatories_Status_Waiting: "Väntar",
    MirOnboarding_TermsAndConditions_FileName: "Allmänna villkor Bokios betaltjänster",
    MirOnboardingPrompt_CompleteTheSignUp: "Slutför",
    MirOnboardingPrompt_CompleteTheSignUpWithBankId: "Slutför med BankID",
    MirOnboardingPrompt_NoRemainingBankGiros_AllOut: "Vi har för tillfället ett högt tryck på Bokio Företagskonto och har stängt för fler ansökningar.",
    MirOnboardingPrompt_NoRemainingBankGiros_FreePlan_Dec202022: "Just nu är det högt tryck på {Bokio Företagskonto} och vi kan för närvarande enbart erbjuda tjänsten till de som tecknar {Premium-} eller {Proplanen}. {Uppgradera din plan} för att komma igång direkt.",
    MirOnboardingPrompt_ProcessingYourAccount_StateDescription_1: "Dina KYC-svar håller på att granskas och vi meddelar dig när ditt Bokio Företagskonto är färdigt att användas.",
    MirOnboardingPrompt_ProcessingYourAccount_StateDescription_2: "{Kontakta gärna support} om du har några frågor.",
    NameOnCard: "Namn på kort",
    NoCardsToShow: "Inga kort finns att visas",
    NoPaymentsToShow: "Inga betalningar att visa.",
    NoPaymentsToShow_WhenFiltersApplied: "Inga betalningar matchade de applicerade filtren.",
    NoRecipientsFound: "Inga mottagare hittades",
    NoReservedTransactions: "Inga reserverade transaktioner",
    NormalSveaOnboarding_ContinueNotice_Description: "Fortsätt din ansökan där du lämnade. Väntar du på svar från Svea kan du öppna och se den senaste statusen här.",
    NormalSveaOnboarding_ContinueNotice_Title: "Du har påbörjat en ansökan till Bokio Företagskonto",
    NoTransactionsTowShow_WhenFiltersApplied: "Inga transaktioner matchade de applicerade filtren.",
    NotSigned: "Ej signerad",
    NumberOfPayments: "Antal betalningar",
    OCR: "OCR",
    OffboardingBBA_Acknowledgment_LosingBG: "Jag förstår att, genom att fortsätta så förlorar jag mitt allokerade bankgironummer.",
    OffboardingBBA_Acknowledgment_NoExpectedTransactions: "Jag bekräftar att jag inte väntar på några inkommande betalningar till detta konto.",
    OffboardingBBA_CanNotBeUndone_Warning: "Varning: Detta kan inte ångras",
    OffboardingBBA_Footnote_ChargingIncomingPayments: "Bokio har rätt att ta en konsultationsavgift för att ta hand om inkommande transaktioner efter avslutat konto.",
    OffboardingBBA_Footnote_OnlyBBANotice: "Notis om detta är ditt enda Bokio Företagaskonto: Avslutande av detta konto kommer ändra sättet du loggar in, tillbaks till email och lösenord + 2FA (om det var aktiverat).",
    OffboardingBBA_InvoicesModal_FixInvoices: "Detta är listan över kommande eller aktiva fakturor som änvänder Bokio Företagskonto som betalningsmetod. Du behöver uppdatera betalningsmetoden på dessa fakturor till något annat för att kunna avsluta Bokio Företagskonto.",
    OffboardingBBA_NoActiveBusinessAccount: "Du har inget aktivt Bokio Företagskonto.",
    OffboardingBBA_Requirements_NoActiveCard: "Avsluta betalkort",
    OffboardingBBA_Requirements_NoActiveInvoices_Action: "Se fakturor",
    OffboardingBBA_Requirements_NoActiveInvoices_Description: "Inga kommande eller aktiva fakturor med dessa betalningsuppgifter",
    OffboardingBBA_Requirements_NoActivePayments: "Inga pågående eller schemalagda betalningar",
    OffboardingBBA_Requirements_NoPendingTransactions: "Inga reserverade transaktioner",
    OffboardingBBA_Requirements_Title: "Krav för att avsluta Bokio Företagskonto",
    OffboardingBBA_Requirements_UpdatedInvoiceSettings: "Updatera betalsätt för utgående fakturor",
    OffboardingBBA_Requirements_UpdatedInvoiceSettingsCaption: "Notera: Publicerade fakturor kommer behålla det gamla bankgironumret. Vänta på att de blir betalda, eller delge dina kunder de nya betalningsdetaljerna.",
    OffboardingBBA_Requirements_ZeroBalance: "Bankkontosaldot ska vara noll",
    OffboardingBBA_TerminateAccount: "Avsluta Bokio Företagskonto",
    OffboardingBBA_TerminationCompleted_Title: "Företagskonto avslutat",
    OffboardingBBA_TerminationConfirmModal_GiveFeedback: "Lämna gärna feedback om varför du vill avsluta Bokio Företagskonto (frivilligt).",
    OffboardingBBA_TerminationConfirmModal_PlanNotDowngraded: "Du kan fortfarande använda de andra fördelarna som ingår i din nuvarande plan.",
    OffboardingBBA_TerminationConfirmModal_Success: "Ditt Bokio Företagskonto har blivit avslutat. Kom ihåg att du när som helst kan skaffa Bokio Företagskonto igen.",
    OffboardingBBA_TerminationConfirmModal_Title: "Bekräfta avslut",
    OnboardingStepsCompletedModal_Menu_BankAndTaxAccounts_Description: "Här kan du se alla transaktioner i ditt företagskonto.",
    OnboardingStepsCompletedModal_Menu_Payments_Description: "Ett dedikerat ställe för att hantera och signera utgående betalningar, så som leverantörsfakturor, löner och manuella betalningar.",
    OnboardingStepsCompletedModal_YourBokioBusinessAccountIsActive: "Bokio Företagskonto är aktivt och redo att användas. Du har många nya saker i Bokio att upptäcka nu.",
    OnePayment: "1 betalning",
    OnlinePurchases: "Internetköp",
    OpenApplication: "Öppna ansökan",
    OrderCard: "Beställ kort",
    OrderPlacedOn: "Beställningen gjordes",
    OtherSettings: "Andra inställningar",
    OwnNote: "Text på eget kontoutdrag",
    Payment_AntiMoneyLaundering_Message: "Bokio har att uppfylla alla krav i penningtvättslagen där övervakning av transaktioner och förstå dem samt våra kunders beteenden ingår. Vi kan därför behöva ställa frågor om en eller flera transaktioner på ditt Bokio Företagskonto. Därför är det viktigt att underlag för transaktionerna finns. I vissa fall kan vi ej släppa igenom en transaktion innan vi har fått kontakt med er. Var därför noga med att alltid ha uppdaterade kontaktuppgifter.",
    Payment_AntiMoneyLaundering_TitleInfo: "Bokio har att uppfylla alla krav i penningtvättslagen.",
    Payment_AntiMoneyLaundering_TitleWarning: "Se till att ha underlag för dina transaktioner.",
    PaymentAdded: "Betalning tillagd",
    PaymentCancelled: "Betalning avbruten",
    PaymentCenter_MultipleStatuses: "Flera statusar",
    PaymentDetails: "Betalning",
    PaymentId: "Betalnings ID",
    PaymentMatching_BannerCTA: "Matcha nu",
    PaymentMatching_BannerTitle: "Det finns {0} internationella betalning(ar) som behöver matchas",
    PaymentMatching_ConnectConfirmText: "Tyvärr går det inte att ångra detta valet så kontrollera det extra nogrannt.",
    PaymentMatching_ConnectConfirmTitle: "Är du säker på att dessa hör ihop?",
    PaymentMatching_ConnectCTA: "Koppla till transaktion",
    PaymentMatching_Description: "För tillfället får vi inte all information vi behöver för att automatiskt matcha transaktionerna med betalningen som skapade dessa. För att du ska kunna göra internationella betalningar medan vi väntar på att det ska implementeras måste du matcha dessa själv. Detta steg kommer tas bort så snart vi kan.",
    PaymentMatching_IsNotTextMatch: "Meddelande matchar inte",
    PaymentMatching_IsTextMatch: "Meddelande matchar",
    PaymentMatching_PossiblePayment: "Betalningar som kan kopplas till denna transaction",
    PaymentMatching_PossiblePaymentsInfo: "Vi har placerat de betalningar vi tror matchar bäst baserat på text och datum först. Välj den som matchar denna transaktion.",
    PaymentMatching_Title: "Koppla internationella betalningar",
    PaymentMatching_TransactionsToMatch: "Transaktioner som behöver matchas",
    PaymentNotSigned: "Betalning ej signerad",
    PaymentOnTheWay: "Betalning på väg",
    PaymentReason: "Anledning till betalning",
    PaymentRejected: "Betalning avvisad",
    PaymentsCenter_AvailableAfterPayment_Summary: "Tillgängligt efter betalningar",
    PaymentsCenter_Error_Status: "Error",
    PaymentsCenter_HidePayment: "Dölj denna betalning",
    PaymentsCenter_HidePayments: "Dölj dessa betalningar",
    PaymentsCenter_NewPayment_Button: "Ny betalning",
    PaymentsCenter_Paid_Status: "Betald",
    PaymentsCenter_PastDateInformation: "Datumet har passerat, signera och betala för att betala på tidigast möjliga dag.",
    PaymentsCenter_Payments_Title: "Betalningar",
    PaymentsCenter_ReadyToSign_Status: "Redo att signeras",
    PaymentsCenter_Rejected_Reason: "Betalningen avvisades. Det kan bero på att mottagarens Bankgiro, PlusGiro eller kontonummer är felaktigt eller att det inte finns tillräckligt med pengar på kontot.",
    PaymentsCenter_Rejected_Status: "Avvisad",
    PaymentsCenter_Scheduled_Status: "Schemalagd",
    PaymentsCenter_ShowCancelledAndRejectedPayments_CheckBox: "Visa avbrutna och dolda",
    PaymentsCenter_ShowingPayments_Text: "Visar {0} av {1} betalningar",
    PaymentsCenter_ShowPaidPayments_CheckBox: "Visa betalda",
    PaymentsCenter_SignAndPay_Button: "Signera och betala",
    PaymentsCenter_Transfering_Status: "På väg",
    PaymentsCenter_UnhidePayment: "Markera betalning som ej dold",
    PaymentsCenter_UnhidePayments: "Markera betalningar som ej dolda",
    PaymentsCenter_UpcomingPayments_Summary: "Kommande betalningar",
    PaymentScheduled: "Betalning schemalagd",
    PaymentTo: "Betalning till",
    PaymentType_AccountTransfer: "Överföring",
    PaymentType_Bankgiro: "Bankgiro",
    PaymentType_Bic: "BIC-betalning",
    PaymentType_PlusGiro: "PlusGiro",
    PaymentType_Sepa: "SEPA-betalning",
    PlaceOrder: "Beställ kort",
    Plaid_ChangeToProduction_Modal_Description: "We’re moving to a better, more reliable Plaid bank feed connection!",
    Plaid_ChangeToProduction_Modal_Failed_Desc: "We could not connect your bank. Try again later",
    Plaid_ChangeToProduction_Modal_Instruction_1: "Connect your bank again",
    Plaid_ChangeToProduction_Modal_Instruction_2: "Check that the same bookkeeping/payment account as the previous connection is chosen",
    Plaid_ChangeToProduction_Modal_Instruction_3: "Enter your \"Get transactions from\" date as the date of the last transaction fetched, which can be seen from the bank feeds page",
    Plaid_ChangeToProduction_Modal_Instruction_4: "Save your changes",
    Plaid_ChangeToProduction_Modal_Instruction_Title: "You will need to:",
    Plaid_ChangeToProduction_Modal_Title: "Upgrade your bank feed connection",
    PlusgiroNumber: "PlusGironummer",
    PreparingYourAccount: "Behandlar ditt konto",
    Preview: "Förhandsgranskning",
    PrintCompanyNameOnCard: "Tryckt företagsnamn på kortet",
    PurchaseSettings: "Köpinställningar",
    ReadAbout_HowToTerminateBBA: "Läs om hur du avslutar ditt nuvarande Bokio Företagskonto här.",
    Readmore_InternationalPayments: "Läs mer om internationella betalningar",
    ReadMoreAbout_AutomatedBankFeeds: "Läs mer om automatisk bankkoppling här.",
    ReadMoreAboutHowToChooseBookkeepingAccount: "Läs mer om hur man väljer bokföringskonto",
    ReadyToBeActivated: "Redo att aktiveras",
    ReceiveEInvoiceRating_Feedback_Description: "Har du någon återkoppling som kan hjälpa oss förbättra din upplevelse av att ta emot e-fakturor?",
    ReceiveEInvoiceRating_Rating_Title: "Hur var din upplevelse med att ta emot e-fakturor?",
    ReceivePayments: "Ta emot betalningar",
    Receiver: "Mottagare",
    ReceiverInfo: "Information om mottagaren",
    ReceiverPayableStatus_Message_CountryNotSupported: "Vi stödjer för närvarande inte betalningar till detta landet",
    ReceiverPayableStatus_Message_CurrencyNotSupported: "Du behöver ändra valutan på betalningen",
    ReceiverPayableStatus_Message_InternationalPaymentsNotEnabled: "Det verkar som du ännu inte har ansökt om internationella betalningar. För att göra betalningar till internationella konton, vänligen ansök om denna tjänst.",
    ReceiverPayableStatus_Message_InternationalReceiverInformationNotMatching: "Detta IBAN-nummer har granskats men övrig kontaktinformation skiljer. Du behöver antingen skicka denna mottagare på granskning igen eller uppdatera informationen på den sparade mottagaren.",
    ReceiverPayableStatus_Message_InternationalReceiverNotRegistered: "Det fanns ingen registrerad mottagare med exakt detta IBAN-nummer.På grund av penningtvättsregler behöver du skicka denna mottagare för granskning innan du kan göra en betalning.",
    ReceiverPayableStatus_Message_InternationalReceiverRegisterationPending: "Din ansökan för denna mottagare är för närvarande under behandling. Du kommer att kunna skicka betalningar när ansökan är godkänd.",
    ReceiverPayableStatus_Message_SepaNotSupported: "Mottagaren har SEPA-betalning inställd men det stöds ej.",
    ReceiverPayableStatus_Title_CountryNotSupported: "Landet stöds ej",
    ReceiverPayableStatus_Title_CurrencyNotSupported: "Valutan stöds ej",
    ReceiverPayableStatus_Title_InternationalPaymentsNotEnabled: "Ansök om internationella betalningar",
    ReceiverPayableStatus_Title_InternationalReceiverInformationNotMatching: "Mottagarinformation matchar inte",
    ReceiverPayableStatus_Title_InternationalReceiverNotRegistered: "Denna mottagare är ännu inte registrerad",
    ReceiverPayableStatus_Title_InternationalReceiverRegisterationPending: "Mottagaransökan väntar på godkännande",
    ReceiverPayableStatus_Title_SepaNotSupported: "Mottagaren behöver ändras till SWIFT-betalning",
    ReceiversBankDetails: "Mottagare",
    RecipientName: "Mottagarens namn",
    Reference: "Referens",
    RejectedBy: "Avvisad av",
    Replace: "Ersätt",
    ReplaceCard: "Ersätt kort",
    RequiresSigningWithMobileBankId: "Kräver signering med mobilt BankID",
    ResendPin: "Skicka PIN igen",
    ReservedTransaction: "Reserverad transaktion",
    ReservedTransactions: "Reserverade transaktioner",
    ResetAddress: "Återställ adress",
    ResumeApplication: "Återuppta ansökan",
    ResumeOngoingApplicationNotice_Description: "Du kan bara ha en pågående ansökan igång samtidigt. Just nu har du en ansökan om {product} som måste slutföras eller avbrytas för att du ska kunna ansöka om {product2}.",
    ReverseNameOrder: "Byt ordning på namnen",
    SearchRecipientPlaceholder: "Sök mottagare eller skapa en ny",
    SearchSenderPlaceholder: "Välj bankkonto",
    SecuritySettings: "Säkerhetsinställningar",
    SelectCountries: "Välj länder",
    SelectCountry: "Välj land",
    SelectPaymentAccount: "Välj betalkonto",
    Sender: "Avsändare",
    SenderInformation: "Information om avsändaren",
    ShowingOfTotalRecipients: "Visar {0} av {1} mottagare",
    ShowPaymentDetails: "Visa betalningsdetaljer",
    SignAndPayModal_NoRights: "Du har inte rättigheter att signera betalningar för det här företaget.",
    SignAndPayModal_TotalSelectedPayments: "Totalt ({0} {1}){ - flera valutor}",
    SignAndPayModalError: "En eller flera betalningar kunde inte slutföras. Var god försök igen.",
    SignAndPayModalError_RowErrors: "En eller flera betalningar har fel. Vänligen fixa dem eller avmarkera dem och signera de andra betalningarna",
    SignAndPayModalErrorTitle: "Betalningen misslyckades",
    SignAndPayModalSuccess: "Alla dina valda betalningar lyckades och är schemalagda.",
    SignAndPayModalTitle: "Signera och betala",
    SignAndPayRow_AddToPay_Button: "Lägg till",
    SignAndPayRow_MoveForLater_Button: "Betala senare",
    Signed: "Signerat",
    SignWithBankID: "Signera med BankID",
    StartApplication: "Påbörja ansökan",
    Svea_Application_Sent_Title: "Din ansökan är skickad",
    Svea_InternationalPaymentsOnboarding_OrderUnderReview_Description: "Din ansökan kommer nu att kontrolleras av Svea Bank. Väntetiden för att få svar beror på vad som granskas i just din ansökan. När din ansökan är granskad och klar så kommer du att meddelas om detta. ",
    Svea_InternationalPaymentsOnboarding_Success_Description: "Innan du kan göra en betalning till utlandet behöver du förregistrera den mottagaren.",
    Svea_SavingsAccountOnboarding_MainSceneTitle: "Öppna bankkonto",
    Svea_SwishOnboarding_AlreadyHasSwish: "Du har redan Swish på ditt transaktionskonto.",
    Svea_SwishOnboarding_ChooseAccount_Description: "Välj det bankkonto som du vill ta emot dina Swishbetalningar till. Kontot måste vara ett transaktionskonto.",
    Svea_SwishOnboarding_ChooseAccount_SelectPlaceholder: "Select a transaction account",
    Svea_SwishOnboarding_ChooseAccount_Title: "Välj bankkonto",
    Svea_SwishOnboarding_ConnectNumber_Description: "Välj om du vill skapa ett nytt Swish-nummer eller ansluta ett befintligt från en annan bank. Om du ska ansluta ett befintligt Swish-nummer, se till att organisationsnumret matchar det som idag är anslutet till det Swish-numret.",
    Svea_SwishOnboarding_ConnectNumber_ExistingNumberOption: "Anslut befintligt Swish-nummer från annan bank",
    Svea_SwishOnboarding_ConnectNumber_Info: "När anslutningen är klar så kommer alla betalningar som skickas till ditt Swish-nummer att tas emot av transaktionskontot **{0}**, som har bankkontonumret **{1}**.",
    Svea_SwishOnboarding_ConnectNumber_NewNumberOption: "Skapa nytt nummer",
    Svea_SwishOnboarding_ConnectNumber_SwishNumber_Error: "Måste vara ett giltigt Swish-nummer som börjar med 123 följt av sju siffror (123 xxx xx xx)",
    Svea_SwishOnboarding_ConnectNumber_SwishNumber_Label: "Swish-nummer",
    Svea_SwishOnboarding_ConnectNumber_SwishNumber_Placeholder: "Skriv befintligt Swish-nummer",
    Svea_SwishOnboarding_ConnectNumber_Title: "Anslut Swish-nummer",
    Svea_SwishOnboarding_ErrorNotice: "**Det gick inte att ansluta Swish Företag**\n\nKontrollera att all information stämmer och försök igen. Om problemet kvarstår så är du välkommen att [kontakta vår support](bokio-support:Bank).\n\n- **Swish-nummer**: Kontrollera att du har angett rätt Swish-nummer.\n- **Befintligt nummer hos Svea Bank**: Har du ett Swish-nummer anslutet till Svea Bank måste du först avsluta ditt Swish-avtal hos dem. När du gjort det så reserverar Swish ditt nummer i 180 dagar, vilket ger dig tid att ansluta numret till ditt Bokio Företagskonto.\n- **Organisationsnummer**: Kontrollera att ditt organisationsnummer stämmer. Organisationsnumret måste vara detsamma som för närvarande är anslutet till ditt Swish-nummer.",
    Svea_SwishOnboarding_ReviewAndSign_Description: "Granska din information och kontrollera att den är korrekt. När du har signerat med BankID kan du börja ta emot betalningar via Swish.",
    Svea_SwishOnboarding_ReviewAndSign_NoticeTitle_ExistingNumber: "Anslut befintligt Swish-nummer",
    Svea_SwishOnboarding_ReviewAndSign_NoticeTitle_NewNumber: "Anslut nytt Swish-nummer",
    Svea_SwishOnboarding_ReviewAndSign_Summary_BankAccount: "Anslutet bankkonto",
    Svea_SwishOnboarding_ReviewAndSign_Summary_CompanyName: "Företagsnamn",
    Svea_SwishOnboarding_ReviewAndSign_Summary_MonthlyFee: "Månadsavgift",
    Svea_SwishOnboarding_ReviewAndSign_Summary_Orgnumber: "Org. nr",
    Svea_SwishOnboarding_ReviewAndSign_Summary_Price: "Transaktionsavgift",
    Svea_SwishOnboarding_ReviewAndSign_Title: "Granska och signera",
    SveaActivationBlock_Title: "Ansök och kom igång med Bokio Företagskonto",
    SveaAddProductInternationalPayments_NoUserPermission_NoticeDescription: "För att aktivera utlandsbetalningar behöver du ha adminrättigheter i Bokio.",
    SveaBBA_ActivationStatus_NonConsentNotice_Title: "Status för aktivering av Bokio Företagskonto",
    SveaBBA_ApplicationApproved_Header: "Status för din ansökan",
    SveaBba_ApplicationRejected_Notice: "Vill du komma i kontakt med Svea angående din ansökan kan du mejla dem på {hej.bank@svea.com}. Kontakta gärna {vår support} om du behöver mer hjälp.",
    SveaBba_ApplicationRejected_NoticeDetails: "**Premium företagsadmin även utan Bokio Företagskonto**\n\nSvea Bank har gjort bedömningen att de tyvärr inte har möjlighet att erbjuda företagskontot du ansökt om. Det tycker vi såklart är jättetråkigt och vi vill därför hjälpa dig vidare med hur du kan fortsätta använda Bokios övriga tjänster som finns inom din prisplan Premium. \n\nEtt bra alternativ till Bokio Företagskonto är att välja en annan banklösning och sedan koppla den till Bokio via **automatisk bankkoppling**. Transaktionerna hämtas automatiskt dagligen från din bank, du slipper hantera dem manuellt och kan bokföra snabbt och enkelt från sidan Transaktioner att bokföra samt i din Att göra.",
    SveaBBA_ApplicationReviewStatus_Header: "Status för din ansökan",
    SveaBBA_ApplicationStatus_Details: "Din ansökan är under granskning. Under tiden kan du fortsätta att använda ditt nuvarande företagskonto som vanligt. När din ansökan har godkänts kommer du att kunna fortsätta och aktivera ditt nya företagskonto. Om du vill komma i kontakt med Svea angående din ansökan kan du mejla dem på {hej.bank@svea.com}. Kontakta gärna {vår support} om du behöver mer hjälp.",
    SveaBBA_ApplicationUnderReviewStatus_Details: "Du har lämnat uppgifter som Svea Bank måste granska manuellt för att gå vidare med din ansökan. Väntetiden för att få svar beror på vad som granskas i just din ansökan. I dagsläget kan det i vissa fall ta upp till några veckor innan granskningen är klar. Svea arbetar så fort de kan och vi tackar för ditt tålamod. {kontakta gärna vår support} om du behöver mer hjälp.",
    SveaBBA_ApplicationUnderReviewStatus_Title: "Informationen du lämnat granskas av Svea",
    SveaBBA_Migration_SepaWarning: "Om du har några leverantörer/betalmottagare med SEPA-betalning inställd kommer vi konvertera dessa till Swift-betalning när du aktiverar internationella betalningar. Det är för att du ska behöva göra mindre jobb vid framtida betalningar till dessa.",
    SveaBBA_OptionalServices_BankGiro_Info: "Har du ett befintligt bankgironummer som du vill flytta över till ditt Bokio Företagskonto? (Om nej så skapar vi ett nytt åt dig)",
    SveaBBA_OptionalServices_BankGiro_InputLabel: "Ange ditt befintliga BG-nummer",
    SveaBBA_Stepper1: "Granska detaljer",
    SveaBBA_Stepper2: "Bekräfta och signera",
    SveaBBA_Stepper3: "Allmänna frågor",
    SveaBBA_Stepper4: "Valfria tjänster",
    SveaBBA_Stepper4_BbaMigration: "Aktivera konto",
    SveaBBA_Stepper5: "Koppla konto",
    SveaBBA_WhatsNext_Details: "När din ansökan är granskad och klar kan du fortsätta härifrån genom att koppla ditt Bokio Företagskonto till ett bokföringskonto. Vill du förbereda dig för nästa steg redan nu? Läs mer om {hur du väljer bokföringskonto}.",
    SveaBBA_WhatsNext_Header: "Vad händer sen?",
    SveaBba_WithPremium_Point_1: "Göra bokföringen enkelt med färdiga mallar.",
    SveaBba_WithPremium_Point_2: "Fakturera och ta betalt enklare med anpassade funktioner såsom egen design, e-faktura och återkommande fakturor.",
    SveaBba_WithPremium_Point_3: "Spara tid och effektivisera bokföringen genom att koppla direkt till din bank.",
    SveaBba_WithPremium_Point_4: "Integrerad lönehantering med automatisk beräkning av lön, skatter och avgifter inkl. arbetsgivardeklaration",
    SveaBba_WithPremium_Title: "Med Premium kan du exempelvis:",
    SveaBBAMigration_ActivateAccount_FinalizeApplication_ApprovedHeader: "Din ansökan är godkänd",
    SveaBBAMigration_ActivateAccount_FinalizeApplication_ReadyToBeActivated: "Redo att aktiveras",
    SveaBBAMigration_ActivateAccount_FinalizeApplication_WhatsNext: "När din ansökan blivit godkänd kan du gå vidare och aktivera det nya företagskontot. För att göra detta krävs det att ditt nuvarande företagskonto inte har:\n- Schemalagda eller pågående betalningar\n- Reserverade transaktioner\n\nVid aktiveringen kommer saldot överföras till ditt nya bankkonto (inom 1-2 bankdagar) och ditt bankgironummer flyttas med automatiskt. Att flytta bankgirot tar några dagar och du får en notis i din Bankinkorg när det är klart. Eventuella inbetalningar som sker under pågående flytt kommer att synas på ditt konto ett par dagar efter att flytten är klar. Därefter avslutar vi det gamla företagskontot åt dig och så snart aktiveringen är klar kan du beställa ditt företagskort.",
    SveaBBAMigration_ActivateAccount_Prerequisites_Description: "Betalningar och transaktioner måste vara helt klara innan vi kan avsluta ditt klientmedelskonto och flytta över pengarna till ditt nya Bokio Företagskonto.",
    SveaBBAMigration_ActivateAccount_Prerequisites_Title: "Checklista",
    SveaBBAMigration_ActivateAccount_PrerequisitesNotice: "Du har uppgifter att hantera innan du kan aktivera ditt nya konto. {Kontakta vår support} om du har några frågor.",
    SveaBBAMigration_ActivateAccount_SetPassword_Description: "Med ditt nya företagskonto kommer du logga in med vanligt lösenord i Bokio. BankID kommer användas för att identifiera dig själv och signera saker kopplat till ditt företagskonto.",
    SveaBBAMigration_ActivateAccount_SetTwoFactor_Disabled_Description: "Vill du lägga till 2-faktor-autentisering? Om du inte lägger till det nu så kan du alltid lägga till det senare i dina inställningar.",
    SveaBBAMigration_ActivateAccount_SetTwoFactor_Disabled_Notice: "Genom att aktivera 2-faktor-autentisering så blir ditt konto mycket säkrare. När du loggar in så kommer vi kräva en kod genererad av din telefon utöver ditt lösenord. [Läs mer om 2FA](https://www.bokio.se/hjalp/bokio-medlemskap/ditt-bokiokonto/tvafaktor-autentisering-med-bokio/). ",
    SveaBBAMigration_ActivateAccount_SetTwoFactor_Enabled_Description: "Ange en verifieringskod för att verifiera din 2-faktor-autentisering.",
    SveaBBAMigration_ActivateAccount_SetTwoFactor_Enabled_Notice: "Du kan avaktivera 2-faktors autentiseringen om du till exempel har tappat bort din telefon eller av någon annan anledning vill inaktivera den. Du kan alltid aktivera det senare i dina inställningar.",
    SveaBBAMigration_ActivateAccount_SetTwoFactor_Enabled_Verify_Error: "Misslyckades med verifiering av kod",
    SveaBBAMigration_ActivateAccount_SignBankgiro_Description: "För att göra allt så smidigt som möjligt för dig kommer bankgironumret {0} att flyttas och kopplas till ditt nya bankkonto. Var vänlig och bekräfta flytten av ditt bankgironummer med ditt BankID.",
    SveaBBAMigration_ActivateAccount_SignBankgiro_Title: "Bekräfta flytt av bankgironummer",
    SveaBBAMigration_ConfirmationModal_Description: "Bekräfta aktiveringen av ditt konto för att överföra kontosaldot till ditt uppgraderade Bokio Företagskonto.",
    SveaBBAMigration_ConfirmationModal_Explanation: "## Du behåller ditt bankgironummer\nFör att göra allt så smidigt som möjligt för dig kommer bankgironumret på ditt tidigare konto att flyttas och kopplas till ditt nya bankkonto.\n## Beställ ditt Mastercard debetkort\nHar du ett företagskort kopplat till ditt tidigare konto kommer detta att avslutas. Du kommer kunna beställa ett nytt kort. Det kan ta upp till 2 veckor för ditt nya kort att levereras.\n## Ditt tidigare företagskonto kommer avslutas\nVi avslutar ditt tidigare konto åt dig (detta kommer inte påverka din bokföring på något sätt) och du kan koppla ditt nya företagskonto till ett nytt bokföringskonto.",
    SveaBBAMigration_ConfirmationModal_NoticeContent: "Det totala saldot på ditt tidigare företagskonto kommer överföras säkert till ditt nya bankkonto inom 1-2 bankdagar.",
    SveaBBAMigration_ConfirmationModal_NoticeHeading: "Överför till ditt nya bankkonto:",
    SveaBBAMigration_ConfirmationModal_Title: "Bekräfta aktivering",
    SveaBbaMigration_OrderMastercard_Details: "# Beställ Mastercard debetkort \nDitt företagskort på ditt tidigare konto har avslutats och du kommer att behöva beställa ett nytt kort. Det kan ta upp till 2 veckor innan ditt nya kort levereras.",
    SveaBBAMigration_SuccessModal_Explanation: "# Bank och skattekonto\nVisa alla dina transaktioner på Bokio Företagskonto på ett ställe.\n# Betalningar\nHantera och signera alla dina utgående betalningar, som leverantörsfakturor, löner och manuella betalningar, från denna sida.\n# Transaktioner att bokföra\nHär kan du direkt bokföra både inkommande och utgående transaktioner.\n# Bokiokort\nBeställ och hantera ditt företagskort här. Du kan också spärra och få ett nytt om det behövs.\n# Bankinkorg\nHåll dig uppdaterad med information om ditt konto ifrån Svea Bank.",
    SveaBBAMigration_SuccessModal_ExplanationHeading: "Utforska Bokio Företagskonto",
    SveaBbaMigration_SuccessModal_SeClearingAccountDescription: "Använd detta bankkontonummer för att överföra pengar till ditt företagskonto.",
    SveaBeneficiaryOwnership_ApplicationStatusNotValid_NoticeDescription: "Din ansökan är tillfälligt blockad. Det här är troligen ett tekniskt problem som vår support behöver hjälpa dig med. Vänligen {kontakta vår support} så snart som möjligt.",
    SveaCardOrder_UnderReview_Description: "Du har lämnat information som Svea Bank måste granska manuellt. Väntetiden för att få svar beror på vad som granskas i just din ansökan. Om du har några frågor angående din kortbeställning, vänligen {kontakta vår support}.",
    SveaCardOrder_UnderReview_Header: "Kortbeställning under granskning",
    SveaConsent: "Uppdrag att dela kontoinformation",
    SveaInternationalPayment_AllowedCountries: "Utlandsbetalningar är möjligt att göra till följande länder:",
    SveaInternationalPayment_NotAllowedNotice_Description: "Du har inte rätt behörighet för att ansöka om internationella betalningar",
    SveaInternationalPayment_ReadyToOnboard_Description: "Med Bokio Företagskonto kan du göra utgående internationella betalningar. För att börja behöver du först skicka in en ansökan, vilket är ett nödvändigt steg för att säkerställa transaktionssäkerhet. Det kostar att göra internationella betalningar men själva ansökan och att ha tjänsten aktiverad är kostnadsfri. Väntetiden för att få svar beror på vad som granskas i just din ansökan.",
    SveaInternationalPayment_ReadyToOnboard_Title: "Ansök om internationella betalningar",
    SveaKyc_BlockServices_Notice: "För att kunna använda banktjänsten Bokio Företagskonto måste du {uppdatera din företagsinformation.}",
    SveaKyc_ReminderModal_Button: "Uppdatera företagsinformation",
    SveaKyc_ReminderModal_MainContent: "Vi ber dig om detta eftersom leverantörer av banktjänster enligt lag är skyldiga att regelbundet följa upp den information de har om sina kunder. Det skyddar den finansiella marknaden från penningtvätt och annan kriminell verksamhet. Direkt när du är klar så kan du fortsätta att använda ditt Bokio Företagskonto som vanligt.\n\nHär kan du läsa mer om [kravet på kundkännedom](https://www.bokio.se/hjalp/bokio-foretagskonto-bankkoppling/bokio-foretagskonto/vanliga-fragor-om-bokio-foretagskonto/#kundkannedom).",
    SveaKyc_ReminderModal_NoticeContent: "För att kunna använda banktjänsten Bokio Företagskonto måste du nu uppdatera din företagsinformation. ",
    SveaKyc_ReminderModal_NoticeTitle: "Du måste uppdatera dina företagsuppgifter",
    SveaKyc_ReminderModal_Title: "Viktigt för dig med Bokio Företagskonto",
    SveaKycApplication_BlockBanner_Description: "För att kunna använda banktjänsten Bokio Företagskonto måste du nu uppdatera din företagsinformation. Direkt när du är klar så kan du fortsätta att använda ditt Bokio Företagskonto som vanligt. Vi ber dig om detta eftersom leverantörer av banktjänster enligt lag är skyldiga att regelbundet följa upp den information de har om sina kunder. Här kan du läsa mer om {kravet på kundkännedom.}",
    SveaKycApplication_BlockBanner_Title: "Du måste uppdatera din företagsinformation",
    SveaKycApplication_InfoBanner_Description: "Du som har Bokio Företagskonto måste se över och uppdatera din företagsinformation senast **{0}**. Direkt när du är klar så kan du fortsätta att använda ditt Bokio Företagskonto som vanligt. Vi ber dig om detta eftersom leverantörer av banktjänster enligt lag är skyldiga att regelbundet följa upp den information de har om sina kunder. Här kan du läsa mer om [kravet på kundkännedom.](https://www.bokio.se/hjalp/bokio-foretagskonto-bankkoppling/bokio-foretagskonto/vanliga-fragor-om-bokio-foretagskonto/#kundkannedom)",
    SveaKycApplication_InfoBanner_Title: "Uppdatera din företagsinformation",
    SveaKycApplication_MainScene_BlockNotice: "För att kunna använda banktjänsten Bokio Företagskonto måste du nu uppdatera din företagsinformation.",
    SveaKycApplication_MainScene_Description: "Leverantörer av banktjänster är enligt lag skyldiga att regelbundet följa upp den information de har om sina kunder. Det skyddar den finansiella marknaden från penningtvätt och annan kriminell verksamhet. Så fort du är klar så kan du fortsätta att använda ditt Bokio Företagskonto som vanligt. ",
    SveaKycApplication_MainScene_DueDateNotice: "Du som har Bokio Företagskonto måste se över och uppdatera din företagsinformation senast **{0}**. ",
    SveaKycApplication_MainScene_NoActionRequiredNotice: "Din företagsinformation är uppdaterad.",
    SveaKycApplication_MainScene_ReadMoreText: "Här kan du läsa mer om {kravet på kundkännedom}.",
    SveaKycApplication_MainScene_TopHeading: "Uppdatera din företagsinformation",
    SveaKycApplication_Settings_Subtitle: "Här kan du uppdatera din företagsinformation när vi och Svea Bank behöver säkerställa att den fortfarande stämmer.",
    SveaKycApplication_Settings_Title: "Företagsinformation för kundkännedom",
    SveaKycApplication_StartKycButton: "Uppdatera företagsinformation",
    SveaKycApplication_SuccessModal_Content: "Vi har tagit emot din uppdaterade företagsinformation. Du kan nu fortsätta att använda Bokio Företagskonto som vanligt.",
    SveaKycApplication_SuccessModal_Title: "Tack",
    SveaNonConsent_BankCardNotice_Description: "Om du inte samtycker kommer du inte att kunna använda tjänsten fullt ut och dess funktioner kommer att vara begränsade. Det innebär att Bokio inte kan komma åt ditt kort för att visa dig mer information.",
    SveaNonConsent_BankCardNotice_Title: "Ditt medgivande behövs för att få tillgång till ditt kort",
    SveaNonConsent_BankInboxNotice_Description: "Om du inte samtycker kommer du inte att kunna använda tjänsten fullt ut och dess funktioner kommer att vara begränsade. Det innebär att Bokio inte kan komma åt din inkorg för att visa dig mer information.",
    SveaNonConsent_BankInboxNotice_Title: "Ditt medgivande behövs för att få tillgång till din inkorg",
    SveaNonConsent_BankInternationalPaymentsNotice_Description: "Om du inte samtycker kommer du inte att kunna använda tjänsten fullt ut och dess funktioner kommer att vara begränsade. Det innebär att Bokio inte kan komma åt internationella betalningar för att visa dig mer information.",
    SveaNonConsent_BankInternationalPaymentsNotice_Title: "Ditt medgivande behövs för att få tillgång till internationella betalningar",
    SveaNonConsent_BankPaymentNotice_Description: "Utan ditt medgivande kan vi inte erbjuda dig alla våra smarta funktioner. I det här fallet innebär det att vi inte kan hämta dina betalningar för att ge dig en överblick.",
    SveaNonConsent_BankPaymentNotice_Title: "Vi behöver ditt medgivande för att se dina betalningar",
    SveaNonConsent_BankTransactionNotice_Description: "Utan ditt medgivande kan vi inte erbjuda dig alla våra smarta funktioner. I det här fallet innebär det att vi inte kan hämta dina transaktioner för att ge dig en överblick.",
    SveaNonConsent_BankTransactionNotice_Title: "Vi behöver ditt medgivande för att hämta dina transaktioner",
    SveaNonConsent_ExistingTransactionsWhenGivingConsent_Notice: "Om du vill ge Svea Bank AB i uppdrag att dela kontoinformation så måste du tyvärr {avsluta ditt Bokio Företagskonto} och ansöka om ett nytt, där du väljer att ge Svea Bank AB uppdraget att dela kontoinformation med oss.",
    SveaNonConsentNotice_Explanation: "För att kunna använda ditt Bokio Företagskonto till fullo måste du antingen uppdatera dina inställningar för medgivande eller gå till Svea Bank.",
    SveaNonConsentNotice_Modal_Title: "Vi behöver ditt medgivande",
    SveaNonConsentNotice_ModalContent_Description: "Du har inte gett oss ditt medgivande för att hämta din kontoinformation från Svea.",
    SveaNonConsentNotice_ModalContent_Title: "För att du ska kunna använda funktionen behöver vi ditt medgivande",
    SveaOffboarding_Description: "För att avsluta ditt Bokio Företagskonto behöver du ta kontakt med Svea Bank. Detta beror på att Bokio Företagskonto tillhandahålls av Svea Bank AB. Du når dem via mejl på {hej.bank@svea.com} eller via deras telefonnummer {08-616 99 00}.",
    SveaOnboarding_CompanyDisclosure_Detail: "Ifrågavarande företag som jag företräder (\"Företaget\") uppdrar härigenom Svea Bank AB (\"Svea\") att fortlöpande dela information om Företagets bankkonton hos Svea (inklusive betalnings- och transaktionsrelaterad data) med Bokio AB (\"Bokio\") (\"Uppdraget\"). Detta Uppdrag är giltigt från och med dagens datum, till dess att Uppdraget återkallas genom att Svea tagit emot skriftligt meddelande därom från en behörig företrädare för Företaget.",
    SveaOnboarding_ConfirmBeneficialOwners_Description: "Vänligen tänk på att du inte kan ändra dessa uppgifter vid ett senare tillfälle.",
    SveaOnboarding_ConfirmBeneficialOwners_Heading: "Är följande information om verklig huvudman korrekt?",
    SveaOnboarding_ConfirmBeneficialOwnersStep_NoBeneficialOwnersExist: "Ditt företag har ingen verklig huvudman.",
    SveaOnboarding_CountryAndTinQuestionType_AddNewEntryButtonText: "Lägg till nytt skatteregisteringsnummer (TIN)",
    SveaOnboarding_CountryAndTinQuestionType_CountryListPlaceholder: "Välj land",
    SveaOnboarding_CountryAndTinQuestionType_TinPlaceholder: "Skatteregisteringsnummer (TIN)",
    SveaOnboarding_Finalizing_ThankYou_Heading: "Tack för din ansökan",
    SveaOnboarding_Finalizing_ThankYouForSubmittingYourKyc_Heading: "Tack för att du skickade in din ansökan",
    SveaOnboarding_IncorrectResponseBeneficialOwner_NoticeDescription: "För att kunna aktivera Bokio Företagskonto måste verklig huvudman vara korrekt. På Bolagsverkets hemsida kan du logga in och göra en ny anmälan av verklig huvudman för ditt företag. Efter 1-2 dagar kan du sedan fortsätta aktiveringen. Vänligen {kontakta vår support} om du har några frågor.",
    SveaOnboarding_IncorrectResponseBeneficialOwner_NoticeHeader: "Informationen om verklig huvudman måste vara korrekt för att fortsätta",
    SveaOnboarding_KycAmlHeading: "Kundkännedom för {0}",
    SveaOnboarding_KycApplicationHeading: "Allmän kundkännedom",
    SveaOnboarding_KycBeneficialOwner_Description: "Frågor om {0}",
    SveaOnboarding_KycBeneficialOwner_Heading: "Frågor om verklig huvudman",
    SveaOnboarding_KycQuestionsDescription: "Vi behöver lära känna ditt företag lite bättre innan du börjar använda våra tjänster. Vänligen besvara frågorna nedan ärligt.",
    SveaOnboarding_NoUserPermission_NoticeDescription: "För att aktivera Bokio Företagskonto krävs adminbehörigheter i Bokio.",
    SveaOnboarding_NoUserPermission_NoticeHeader: "Din användare saknar behörighet",
    SveaOnboarding_ReviewDetails_AccountOwnerWarning: "Just nu har tjänsten endast stöd för en användare, därför är det viktigt att den personen som ska använda företagskontot också aktiverar det. Det är endast du som kontoägare som har möjlighet att signera betalningar och beställa kort till ditt företagskonto.",
    SveaOnboarding_ReviewDetails_OptionalFeatures_Bankgiro: "Vill du ha ett bankgiro kopplat till ditt företagskonto?",
    SveaOnboarding_ReviewDetails_OptionalFeatures_Bankgiro_Description: "Det här är en tjänst som du utan extra kostnad kan välja för ditt Bokio Företagskonto. Om du har ett befintligt bankgiro kommer du kunna välja att flytta över det till ditt företagskonto om du vill.",
    SveaOnboarding_ReviewDetails_OptionalFeatures_Title: "Valfria tjänster",
    SveaOnboarding_SniCodesStep_Description: "Vi saknar information om ditt företags SNI-koder. Var vänlig och fyll i en eller flera SNI-koder.",
    SveaOnboarding_SniCodesStep_ReadMoreLink: "Läs mer om SNI-koder",
    SveaOnboarding_SniCodesStep_SearchPlaceholder: "Sök efter SNI-koder",
    SveaOnboarding_SniCodesStep_SelectPlaceholder: "Välj SNI-koder",
    SveaOnboarding_SniCodesStep_Title: "SNI-koder",
    SveaOnboarding_SveaProductDefinitionId_Name_ForeignPayment: "internationella betalningar",
    SveaOnboarding_SveaProductDefinitionId_Name_SavingAccount: "sparkonto",
    SveaOnboarding_SveaProductDefinitionId_Name_TransactionAccount: "transaktionskonto",
    SveaOnboarding_UnspecifiedBeneficialOwner_NoticeDescription: "Företag som inte är registrerade som enskild firma måste ha minst en verklig huvudman för att kunna aktivera Bokio Företagskonto. På Bolagsverkets hemsida kan du logga in och registrera en verklig huvudman för ditt företag. Efter 1-2 dagar kan du sedan fortsätta aktiveringen. Vänligen {kontakta vår support} om du har några frågor.",
    SveaOnboarding_UnspecifiedBeneficialOwner_NoticeHeader: "Det verkar inte finnas en verklig huvudman för det här företaget",
    SveaRemoveSwish_ModalContent: "Är du säker att du vill ta bort Swish-numret **{0}** som är kopplat till **{1}**? Du kan inte ångra detta i efterhand.",
    SveaRemoveSwish_ModalTitle: "Ta bort Swish-nummer",
    SveaReplaceCard_Modal_Description1: "Är du säker på att du vill ersätta ditt valda kort?",
    SveaReplaceCard_Modal_Description2: "Detta kommer att permanent avsluta ditt valda kort och du kan inte ångra den här ändringen.",
    SveaReplaceCard_Modal_TitleText: "Bekräfta ersätt kort",
    SveaReplaceCard_Reason_Option1: "Borttappat",
    SveaReplaceCard_Reason_Option2: "Stulet",
    SveaReplaceCard_Reason_Title: "Anledning till att ersätta kortet",
    SveaReplaceCard_SelectReason: "Välj anledning",
    SveaSavingsAccount_Onboarding_EntrypointButton: "Öppna nytt bankkonto",
    SveaSigPackageAgreement_ZeroSignatories_Notice_Description: "Tyvärr kan vi inte hitta någon firmatecknare på ditt företag. Åtminstone en firmatecknare krävs för att aktivera Bokio Företagskonto.",
    SveaSigPackageAgreement_ZeroSignatoriesNotice_Header: "Vi kunde inte hitta någon firmatecknare för företaget. {Vänligen kontakta vår support.}",
    SveaSwishRemove_SuccessToastText: "Ditt Swish-nummer har tagits bort",
    Swish: "Swish",
    SwishBusiness: "Swish Företag",
    SwishBusiness_ReadyToBeUsed_Description: "Swish Företag är redo att användas. Börja ta del av smidiga inkommande betalningar genom att dela ditt Swish-nummer med dina kunder.",
    SwishCampaignBanner_DoesntHaveBba_Description: "Vårt företagskonto blev precis ännu bättre! Låt dina kunder betala med Swish och få transaktionerna direkt till din bokföring. Under 2024 bjuder vi på månadsavgiften ({0}/mån) för **Swish Företag** – ansök om Bokio Företagskonto och kom igång idag.",
    SwishCampaignBanner_HasBba_Description: "Ta betalt med Swish och få transaktionerna direkt till din bokföring. Snabbt, enkelt och säkert för både dig och dina kunder. Anslut **Swish Företag** till ditt Bokio Företagskonto – under 2024 bjuder vi på månadsavgiften ({0}/mån).",
    SwishCampaignBanner_Title: "Nu lanserar vi Swish - fri månadsavgift året ut",
    SwishConnected: "Swish anslutet!",
    SwishNumber: "Swish-nummer",
    SwishOverview_BillingOfService_Description: "Läs mer om betalning av Swish Företag under {Betalning av Bokios tjänster}.",
    SwishOverview_BillingOfService_SectionTitle: "Betalning av tjänst",
    SwishOverview_ConnectedAccount_SectionTitle: "Anslutet bankkonto",
    SwishOverview_CopySwishNumberButton_Text: "Kopiera Swish-nummer",
    SwishOverview_RemoveSwishNumberButton_Text: "Ta bort nummer",
    SwishOverview_SwishFaqLinkText: "Vanliga frågor om Swish",
    SwishOverview_TopBanner_Description: "Alla betalningar som skickas till ditt Swish-nummer tas emot av det anslutna transaktionskontot. Du kan endast ansluta 1 Swish-nummer till det transaktionskonto du har inom Bokio Företagskonto.",
    SwishOverviewPage_Feedback_Text: "Ge gärna feedback på hur du upplever att Swish Företag fungerar för dig eller om du har förslag på förbättringar.",
    SwishOverviewPage_Feedback_Title: "Hur upplever du Swish Företag?",
    SwishOverviewPage_OnboardingBanner_Description: "**Swish Företag** är en tjänst för dig som vill komma igång och ta betalt direkt, utan att behöva sätta upp komplicerade kassasystem. Du slipper hantera kontanter och kundernas betalningar går igenom direkt.",
    SwishOverviewPage_OnboardingBanner_MonthlyCostBadge: "Fri månadsavgift {0} under 2024",
    SwishOverviewPage_OnboardingBanner_OnboardCTA: "Anslut Swish Företag",
    SwishOverviewPage_OnboardingBanner_StepList_ConnectNumber: "Anslut ett Swish-nummer till det transaktionskonto du har inom Bokio Företagskonto. Du har möjlighet att ansluta ett befintligt Swish-nummer eller skaffa ett nytt nummer.",
    SwishOverviewPage_OnboardingBanner_StepList_Sign: "Granska uppgifterna och signera med ditt BankID.",
    SwishOverviewPage_OnboardingBanner_Title: "Anslut Swish till ditt Bokio Företagskonto",
    SwishPricing_ReadMore_LinkLabel: "Läs mer om Swish och prissättning på vår webbsida",
    SwishProcessingMessage: "Vi förbereder din Swish-information. Det kan ta några minuter.",
    SyncNow: "Synka nu",
    SyncTransactions_Failed: "Misslyckades att synka med banken på grund av ett temporärt tekniskt problem. Vänligen försök igen imorgon.",
    SyncTransactions_Success: "Lyckades synka banktransaktioner. Nya transaktioner är redo att bokföras.",
    Terminate: "Avsluta",
    TerminateAccount: "Avsluta företagskonto",
    TerminateBba: "Avsluta företagskonto",
    TerminateCard: "Avsluta kort",
    TerminateCardDescription_1: "Är du säker på att du vill avsluta ditt valda kort?",
    TerminateCardDescription_2: "Detta kommer att permanent inaktivera kortet och du kan inte ångra den här ändringen.",
    TerminateCards: "Avsluta kort",
    Todo_ContinueNormalSveaOnboarding_Title: "Du har påbörjat en ansökan till **Bokio Företagskonto**. Öppna för att fortsätta eller för att se den senaste statusen.",
    Todo_ContinueSwishOnboarding_Title: "Du har påbörjat anslutningen av Swish Företag men har inte slutfört alla steg. Öppna för att fortsätta.",
    TodoCard_Activate_Description: "Aktivera ditt företagskort idag och få alla dina korttransaktioner direkt till Bokio, redo att bokföras.",
    TodoCard_Activate_Title: "Har du fått ditt kort?",
    TodoCard_Dismiss_FeedbackInput_Title: "Ange gärna varför du vill ta bort uppgiften Beställ kort.",
    TodoCard_Dismiss_Title: "Bekräfta borttagning av uppgift",
    TodoCard_Order_Description: "Beställ ditt företagskort idag och få alla dina korttransaktioner direkt till Bokio, redo att bokföras.",
    TodoCheckMissingTransactionsWhenMigratingFromKlarnaRow_Description: "Kontrollera att transaktionerna för {0} stämmer för datumen {1}. Jämför de bankrader du har på ditt bankkonto med de som importerats till Bokio. Om transaktioner saknas, lägg till dem manuellt {här}. Detta beror på en tidslucka i den automatiska importen vid övergången till vår nya autentiseringstjänst Enable Banking. Efter att du har gjort detta kommer bankkopplingen att fungera precis som vanligt.",
    TodoCheckMissingTransactionsWhenMigratingFromKlarnaRow_Title: "Kontrollera importerade transaktioner",
    TodoDepositMoney_Description: "Du behöver ha pengar på ditt konto för att börja göra betalningar.",
    TodoDepositMoney_Title: "Sätt in pengar på Bokio Företagskonto",
    TodoPayments_ReadyToSign: "Du har {0} med en totalsumma på {1} som är redo att signeras.",
    TodoPayments_ReadyToSign_MultipleCurrencies: "Du har {0} i flera olika valutor som är redo att signeras.",
    TotalAmount: "Total summa",
    TransactionAccount: "Transaktionskonto",
    TransactionDate: "Transaktionsdatum",
    Transfer: "Överföring",
    UnblockCard: "Aktivera kortet",
    UpdateCardLimit: "Uppdatera kortets köpgräns",
    UpdateCardLimitModal_CardLimitInputDescription: "Totala tillåtna beloppet på alla köp under en 7-dagarsperiod. Du behöver signera med mobilt BankID för att bekräfta denna ändring.",
    UpdateLinkedPhoneNumber: "Uppdatera kopplat telefonnummer",
    UpdateLinkedPhoneNumberModal_LinkedPhoneNumberInputDescription: "Din verifieringskod till Mastercard 3D secure och framtida PIN-koder kommer skickas till detta telefonnummer.",
    UpdateSettings: "Updatera inställningar",
    Upgrade: "Uppgradera",
    UseDifferentDeliveryAddress: "Använd en annan leveransadress",
    ValidationError_PaymentDateNonBankingDay: "Betaldatum måste vara på en bankdag",
    ViewAllAccounts: "Visa alla konton",
    ViewAndSign: "Se och signera",
    ViewEditDetails: "Visa/Ändra detaljer",
    ViewEditRecipient: "Visa/Ändra mottagare",
    WithdrawMoney: "Ta ut pengar",
    XPayments: "{0} betalningar",
    YourCardIsReadyToOrder: "Ditt kort är redo att beställas",
    YourPersonalNumber: "Ditt personnummer",
  };
}