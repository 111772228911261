/* eslint-disable */

import type { GeneralLang } from "../types/GeneralLang";
import { translateTempImpl } from "../TranslatableLanguage";

export function GeneralLangDef(): GeneralLang {
  return {
    translateTemp: (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB),
    
    _OR: "eller",
    Accept: "Acceptera",
    Account: "Konto",
    AccountedPage_ShowVerifications_label: "Visar {0} av {1} verifikat",
    AccountedPage_Sum_Tooltip: "Summan är totalen av kredit/debet",
    AccountingApplication: "Bokföring",
    AccountingCheck_CheckVerifications_Intro: "Denna rapporten hjälper dig att snabbt hitta konstigt bokförda verifikat genom att kolla vilka mönster verifikaten följer.",
    AccountingCheck_CheckVerifications_Title: "Kontrollera att verifikat ser korrekta ut",
    AccountingCheck_LedgerReconcile_Intro: "Denna rapporten hjälper dig att snabbt hitta om och när ett bokföringskonto inte stämmer med en extern lista, t.ex ett bankutdrag eller ett skattekonto",
    AccountingCheck_LedgerReconcile_SelectionTitle: "Stäm av konto {0} mellan {1} och {2}",
    AccountingCheck_LedgerReconcile_Title: "Hitta bokföringsfel",
    AccountingCheck_Title: "Kontrollera redovisning",
    Accounts: "Konton",
    AccountSelector_Placeholder: "Nummer eller namn",
    AccountSync_ManualBookkeepingWarning: "För att säkerställa korrekt bokföring, rekommenderar vi att du endast bokför ifrån {Transaktioner att bokföra} för bokföringskonton som är synkade med din bank eller skattekonto.",
    AccountSync_ManualBookkeepingWarning_Title: "Bokföring på ett synkat konto",
    Action_Heading: "Åtgärd",
    Actions: "Åtgärder",
    Activate: "Aktivera",
    ActivationFailed: "Aktiveringen misslyckades",
    Active: "Aktiverad",
    Add: "Lägg till",
    Add_Account: "Nytt konto",
    AddMaterial: "Lägg till underlag",
    AddNew: "Lägg till ny",
    AddNewRow: "Lägg till ny rad",
    Address_City: "Ort",
    Address_Co: "C/o",
    Address_Country: "Land",
    Address_PostalCode: "Postnr",
    Address_Street: "Postadress",
    Address_Street2: "Postadress 2",
    AddrPosSetField: "Placering av adressen",
    AdvancedSettings: "Avancerade inställningar",
    All: "alla",
    AllItems: "Alla föremål",
    AllowBypassLabel: "Jag förstår riskerna och vill fortsätta",
    AllRemoved: "Alla borttagna",
    AllTypes: "Alla typer",
    Amount: "Belopp",
    AmountExclVAT: "Summa (exkl. moms)",
    AmountIn_Currency: "Belopp i {currency}",
    AmountInclVAT: "Summa (inkl. moms)",
    AmountWithLocalCurrency: "{0} kr",
    And: "och",
    ApartmentNumber: "Lägenhetsnummer",
    Apply: "Applicera",
    ApplyFilters: "Applicera filter",
    April: "April",
    AreYouSureDelete: "Vill du verkligen radera detta?",
    AreYouSureYouWantToContinue: "Är du säker på att du vill fortsätta?",
    Ascending_label: "Stigande",
    Assets: "Tillgångar",
    Assigned_accountants: "Kundansvariga",
    August: "Augusti",
    AuthenticateCompanyError: "Kunde inte verifiera ditt företag. Försök igen senare.",
    AwaitingApproval: "Inväntar godkännande",
    Back: "Tillbaka",
    BankAccount: "Bankkonto",
    BankDemoData_Completed_Title: "Testtransaktioner genererade",
    BankDemoData_CompletedCta: "Gå till Saker att göra",
    BankDemoData_CompletedText: "17 rader har blivit tillagda på ditt konto. Så här enkelt kommer det att vara om du kopplar din bank till Bokio. Du kan nu testa att bokföra dina testtransaktioner genom att gå till sidan för Saker att göra.",
    BankDemoData_CTA: "Generera testtransaktioner",
    BankDemoData_Text: "Testa hur bokföring fungerar i Bokio på ett snabbt sätt genom att generera testtransaktioner som du sedan kan bokföra. Transaktionerna kopplas till konto 1930. Om du på ett liknande sätt vill slippa att importera dina transaktioner manuellt i framtiden så kan du koppla din bank till Bokio. Med bankkoppling hämtas transaktionerna automatiskt dagligen från din bank och du slipper det manuella flödet med att klippa och klistra.",
    BankDemoData_Title: "Utforska bokföring genom att generera några testtransaktioner",
    BankDetails: "Bankuppgifter",
    BankID: "BankID",
    BankID_Footer: "{Läs mer om BankID} {eller} {avbryt}",
    BankId_NotOpening: "Öppnas inte BankID?",
    BankId_Open: "Öppna BankID",
    BankID_OpenOnOtherDevice: "Öppna BankID på annan enhet",
    BankID_OpenOnThisDevice: "Öppna BankID på denna enhet",
    BankID_Start: "Starta Mobilt BankID",
    BankID_Trouble: "Har du problem med Mobilt BankID?",
    BankId_UserMessage_NotSupportedByUserApp: "Uppdatera din BankID-klient",
    BankId_UserMessage_RFA1: "Starta BankID-appen.",
    BankId_UserMessage_RFA1_QR: "1. Öppna BankID-appen på en annan enhet\n1. Tryck på knappen **Skanna QR-kod**\n1. Rikta kameran mot QR-koden här nedanför",
    BankId_UserMessage_RFA13: "Försöker starta BankID-appen.",
    BankId_UserMessage_RFA15A: "Söker efter BankID, det kan ta en liten stund …\nOm det har gått några sekunder och inget BankID har hittats har du sannolikt inget BankID som går att använda för den aktuella identifieringen/underskriften i den här datorn. Om du har ett BankID-kort, sätt in det i kortläsaren. Om du inte har något BankID kan du skaffa ett hos din bank.",
    BankId_UserMessage_RFA15B: "Söker efter BankID, det kan ta en liten stund …\nOm det har gått några sekunder och inget BankID har hittats har du sannolikt inget BankID som går att använda för den aktuella identifieringen/underskriften i den här enheten. Om du inte har något BankID kan du skaffa ett hos din bank.",
    BankId_UserMessage_RFA16: "Det BankID du försöker använda är för gammalt eller spärrat. Använd ett annat BankID eller skaffa ett nytt hos din bank.",
    BankId_UserMessage_RFA17A: "BankID-appen verkar inte finnas i din dator eller mobil. Installera den och skaffa ett BankID hos din bank. Installera appen från din appbutik eller [https://install.bankid.com](https://install.bankid.com).",
    BankId_UserMessage_RFA17B: "Misslyckades att läsa av QR-koden. Starta BankID-appen och läs av QR-koden. Kontrollera att BankID-appen är uppdaterad. Om du inte har BankID-appen måste du installera den och skaffa ett BankID hos din bank. Installera appen från din appbutik eller [https://install.bankid.com](https://install.bankid.com).",
    BankId_UserMessage_RFA21: "Identifiering eller underskrift pågår.",
    BankId_UserMessage_RFA22: "Okänt fel. Försök igen.",
    BankId_UserMessage_RFA3: "Åtgärden avbruten. **Försök igen**.",
    BankId_UserMessage_RFA6: "Åtgärden avbruten.",
    BankId_UserMessage_RFA8: "BankID-appen svarar inte. Kontrollera att den är startad och att du har internetanslutning. Om du inte har något giltigt BankID kan du skaffa ett hos din bank. **Försök sedan igen.**",
    BankId_UserMessage_RFA9: "Skriv in din säkerhetskod i BankID-appen och välj Identifiera eller Skriv under.",
    BankRulesRating_Feedback_Description: "Har du några tankar som kan hjälpa oss att förbättra din upplevelse kring bankregler?",
    BankRulesRating_Rate_Title: "Hur var din upplevelse med att använda bankregler?",
    BankTransaction: "Bank transaktioner",
    BBA_AddPaymentsOrTransfers: "Betalningar och överföringar som behöver signeras kommer dyka upp här och du kan signera alla betalningar härifrån",
    BBA_AvailableBalance: "Tillgängligt saldo",
    BBA_CreatePaymentOrTransfer: "Skapa en ny betalning/överföring",
    BBA_NoPaymentsOrTransfers: "Inga betalningar eller överföringar att signera",
    BBA_PaymentsToSign: "Betalningar att signera",
    BBA_TopBarButton_NoticePopoverContent_QuickAccess: "Här kommer du nu enkelt åt dina transaktioner, betalningar och signering av betalningar för ditt Bokio Företagskonto.",
    BBA_ViewPayments: "Visa betalningar",
    BBA_ViewTransactions: "Visa transaktioner",
    Beta: "Beta",
    BetaFeedback: "Beta feedback",
    Billing: "Fakturering",
    Billing_CurrentPeriod_Description: "Baserad på din nuvarande användning. Alla kostnader kommer faktureras {0}. Den totala summan kan komma att ändras. Kostnader uppdateras varje natt",
    Billing_CurrentPeriod_Heading: "Nuvarande period",
    Billing_FullPriceList: "Se fullständig prislista här",
    Billing_FullPriceList_Link: "https://www.bokio.se/prisplaner/detaljerad-prislista",
    Billing_InvoiceDetails_Heading: "Faktureringsdetaljer",
    Billing_Invoices_EmptyState: "När du använt och betalat för betaltjänster i Bokio kommer dina kvitton att visas här.",
    Billing_Item: "Artikel",
    Billing_OverdueError_Description: "Vi har inte mottagit någon betalning för fakturan och den är nu försenad. Vänligen betala fakturan nu för att inte riskera att servicen blir avbruten.",
    Billing_OverdueError_Heading: "Faktura för {0} (#{1}) är försenad",
    Billing_PreviousInvoices_Heading: "Tidigare fakturor",
    Billing_Receipt_PdfName: "Bokio_Kvitto_{0}.pdf",
    Billing_Receipts: "Kvitton",
    Billing_ReceiptsHistory_Heading: "Kvittohistorik",
    Billing_Reports_FromAndIncluding: "Från och med",
    Billing_Reports_Heading: "Preleminär funktionelitet - Faktureringsrapporter",
    Billing_Reports_Subtitle: "Hämta rapporter för fakturerade kostnader.",
    Billing_Reports_Tab: "Rapporter",
    Billing_Settings: "Betalningsinställningar",
    Billing_Settings_AddPaymentMethod: "Lägg till betalningsmetod",
    Billing_Settings_NotACustomer: "Här kommer ni kunna ändra företagets betaluppgifter efter att företaget anslutit sig till betaltjänster i Bokio.",
    Billing_Settings_UpdatePaymentMethod: "Uppdatera betalningsmetod",
    Billing_Settings_UpdatePaymentMethodNotice: "Vid uppdatering av kortuppgifter kommer vi göra nya försök att betala dina obetalda fakturor",
    Billing_Status_Credited: "Krediterad",
    Billing_Status_CurrentPeriod: "Pågående",
    Billing_Status_NoInvoice: "Ingen faktura",
    Billing_Status_Overdue: "Försenad",
    Billing_Status_Paid: "Betald",
    Billing_Status_Pending: "Behandlas",
    Billing_Status_ReadyToPay: "Redo att betalas",
    Billing_TypeName_Default: "Bokio kvitto",
    Billing_TypeName_EarlyBird: "Prisplan köp",
    Billing_TypeName_MonthlyInvoice: "Bokio kvitto",
    Billing_TypeName_PlanPurchase: "Prisplan köp",
    Billing_TypeName_PlanUpgrade: "Prisplan uppgradering",
    Billing_TypeName_Refund: "Återbetalning",
    Billing_Voucher_Success_CTA: "Välj en betalplan",
    Billing_Voucher_Success_Heading: "Rabatten {0} har aktiverats!",
    Billing_Voucher_Success_Text: "Slutför köpet av en prisplan innan {0} för att utnyttja rabatten.",
    Billing_Voucher_Title: "Aktivera rabatt",
    Billing_YourInvoiceFor: "Din faktura för {0}",
    Blocked: "Spärrat",
    BokioEntrepreneurs_description: "Bokio entreprenörer supportar varandra",
    BokioEntrepreneurs_label: "Facebook-grupp",
    BokioFacebookCommunityUrl: "https://www.facebook.com/groups/240735629672293/",
    BokioHelpBaseUrl: "https://www.bokio.se/hjalp",
    BokioPartner_description: "Vi sköter bokföringen åt dig",
    BokioPartner_label: "Bokio partner",
    BokioPartnerProgrammeUrl: "https://www.bokio.se/for-redovisningsbyraer/byrapartner/",
    BokioPartnerUrl: "https://www.bokio.se/tillaggstjanster/partner/",
    BokioPlan_Name_Balance: "Balans",
    BokioPlan_Name_Business: "Business",
    BokioPlan_Name_Premium: "Premium",
    BokioPlan_Name_PremiumPlus: "Premium Plus",
    BokioPlan_Offer_Status: "Erbjudande",
    BokioPlan_SelectPlan: "Välj prisplan",
    BokioPlanLockBadge_PlaidBankFeed_Tooltip: "You can only connect one account on our {Free} plan. You need the {Balance} or {Business} plan to connect multiple accounts.",
    BokioPlanLockBadge_PlaidBankFeed_Tooltip_Trial: "After the trial period expires, you can only connect one account on our {Free} plan. You need the {Balance} or {Business} plan to connect multiple accounts.",
    BokioPlanLockBadge_Text_RequiresPlanX: "Kräver prisplan: {0}",
    BokioPlanLockBadge_Text_Trial: "Gratis under provperioden",
    BokioPlanLockBadge_ToolTip_NoPermission: "Din administratör behöver uppgradera er prisplan.",
    BokioPlanLockBadge_ToolTip_RequiresPlanX_Locked: "Denna funktion kräver prisplan {0}.",
    BokioPlanLockBadge_ToolTip_RequiresPlanX_Trial: "Denna funktion kräver prisplan {0} efter att din provperiod slutar.",
    BokioPlanLockBadge_ToolTip_RequiresPlanX_WithFeatureName_Trial: "{0} kräver prisplan {0} efter att din provperiod slutar.",
    BokioPlanLockPromotionBanner_InvoiceReportText: "Uppgradera din plan till {0} för se fakturarapporter.",
    BokioPlanLockPromotionBanner_InvoiceReportText_Trial: "Uppgradera din plan till {0} för se fakturarapporter efter att din provperiod slutar.",
    BokioPlanLockPromotionBanner_QuoteText: "Uppgradera din plan till {0} för publicera nya offerter.",
    BokioPlanLockPromotionBanner_QuoteText_Trial: "Uppgradera din plan till {0} för publicera nya offerter efter att din provperiod slutar.",
    BokioPlanLockPromotionBanner_RecurringInvoice: "Uppgradera din plan till {0} för att använda återkommande fakturor.",
    BokioPlanLockPromotionBanner_RecurringInvoice_Trial: "Uppgradera din plan till {0} för att använda återkommande fakturor efter att din provperiod slutar.",
    BokioPlanLockPromotionBanner_RotRutText: "Uppgradera din plan till {0} för publicera fakturor och offerter med Rot/Rut.",
    BokioPlanLockPromotionBanner_RotRutText_Trial: "Uppgradera din plan till {0} för publicera fakturor och offerter med Rot/Rut efter att din provperiod slutar.",
    BokioPlanLockPromotionBanner_StripeText: "Upgrade your plan to {0} to get card payments by stripe.",
    BokioPlanLockPromotionBanner_StripeText_Trial: "Upgrade your plan to {0} to get card payments by stripe after the trial periods ends.",
    BokioPlanLockPromotionBanner_ZettleIntegrationText: "Uppgradera din plan till {0} för att skapa automatisk bokföring från Zettle.",
    BokioPlanLockPromotionBanner_ZettleIntegrationText_Trial: "Uppgradera din plan till {0} för att skapa automatisk bokföring från Zettle efter att din provperiod slutar.",
    BokioSupportMail: "support@bokio.se",
    Bookkeep_BookkeepTaxes: "Bokför skatter",
    Bookkeep_NoTaxes: "Det fanns inga skatter denna perioden så vi kommer inte generera några verifikat. Klicka på bokför transaktioner för att färdigställa perioden.",
    Bookkeeping: "Bokföring",
    BookkeepingRating_Feedback_Description: "Har du några tankar som kan hjälpa oss att förbättra din bokföringsupplevelse?",
    BookkeepingRating_Feedback_Placeholder: "Skriv din feedback här",
    BookkeepingRating_Rate_Title: "Hur var din bokföringsupplevelse?",
    BrowserNotSupported: "Din webbläsare eller version av webbläsare stöds inte av Bokio. Vänligen uppgradera till en nyare version eller använd en annan [webbläsare som stöds](bokio-help:/BrowserSupport).",
    Campaign: "Kampanj",
    Cancel: "Avbryt",
    CancelPlanConfirmationModal_ActiveBbaAccount: "Du kan inte säga upp ditt abonnemang medan du har ett aktivt Bokio Företagskonto. ",
    CancelPlanConfirmationModal_ActiveBbaOnboarding_link: "För att kunna avsluta din prenumeration behöver du [avbryta din pågående ansökan om Bokio Företagskonto]({0}).",
    CancelPlanConfirmationModal_CancelCancellation: "Nej, behåll planen",
    CancelPlanConfirmationModal_CancelPlan: "Avsluta plan",
    CancelPlanConfirmationModal_ConfirmCancellation: "Ja, avsluta plan",
    CancelPlanConfirmationModal_DeleteYourBBA: "Avsluta ditt Bokio Företagskonto här.",
    CancelPlanConfirmationModal_DownloadData: "Ladda ner företagets data här.",
    CancelPlanConfirmationModal_ModalContent: "Är du säker på att du vill säga upp din plan nu? Din plan kommer att vara aktiv fram till och med {0} och du kommer därefter inte längre ha tillgång till Bokios tjänster eller ditt företags data. ",
    Card: "Kort",
    CashbackOverview_Heading: "Status för återbäring",
    CashbackOverview_HideBanner: "Dölj återbäring",
    CashbackOverview_PremiumText: "Du skulle ha tjänat {0} under de senaste 30 dagarna om du hade aktiverat återbäring genom att uppgradera till Premium Plus.",
    CashbackOverview_ShowBanner: "Visa återbäring",
    Category: "Kategori",
    CESRating_Complete_Description: "Har du ytterligare feedback eller frågor kan du alltid kontakta [support@bokio.se](mailto:support@bokio.se).",
    CESRating_Complete_Title: "Tack för din feedback!",
    CESRating_Feedback_Title: "Ge oss din feedback",
    Change: "Ändra",
    Changed: "Ändrad",
    ChangeLanguage_action: "Change language",
    ChangeRegion: "Välj ett annat land",
    ChartOfAccounts_Custom: "Eget",
    ChartOfAccounts_CustomName: "Eget namn",
    Checkout_BokioStartFreeMonths: "({0} fria månader)",
    Checkout_Discount: "Rabatt {0}",
    ChooseAnotherCompany: "Välj ett annat företag",
    ChooseMatch: "Välj matchning",
    ChooseNewPassword: "Välj nytt lösenord",
    ChooseOne: "Välj en",
    ChoosePassword: "Välj lösenord",
    Claims_AccountingSettings: "Bokföringsinställningar",
    Claims_AdminUsers: "Användare",
    Claims_Attest: "Attestera",
    Claims_Bookkeep: "Bokföra",
    Claims_Closures: "Bokslut",
    Claims_CompanySettings: "Företagsinställningar",
    Claims_Description_AccountingSettings: "Användaren kan ändra bokföringsinställningarna",
    Claims_Description_AdminUsers: "Tillåt användaren hantera rättigheter för användare",
    Claims_Description_Attest: "Tillåter användaren att attestera kvitton",
    Claims_Description_Bookkeep: "Tillåtera användaren att bokföra",
    Claims_Description_Closures: "Tillåter användaren att se och göra bokslut",
    Claims_Description_CompanySettings: "Användaren kan ändra företagsinställningarna",
    Claims_Description_Disbursements: "Användaren kan schemalägga utbetalningar för utlägg",
    Claims_Description_ImportantDates: "Tillåt användare att hantera viktiga datum",
    Claims_Description_Inbox: "Användaren kan ändra inställningarna för vem som får skicka in kvitton",
    Claims_Description_Invoices: "Tillåter användaren att se, skapa samt skicka fakturor och offerter",
    Claims_Description_InvoiceSettings: "Användaren kan ändra inställningar för faktura och offert",
    Claims_Description_Reports: "Tillåter användaren att se rapporter",
    Claims_Description_Salaries: "Tillåter användaren att se och skapa löner samt lönerapporter",
    Claims_Description_SalarySettings: "Användaren kan ändra inställningarna för lön",
    Claims_Description_SupplierInvoices: "Användaren kan administrera leverantörer och leverantörsfakturor",
    Claims_Description_UploadBank: "Tillåter användaren att importera från bank",
    Claims_Description_UploadExpense: "Tillåter användaren att ladda upp sina utlägg",
    Claims_Description_UploadReceipt: "Tillåter användaren att ladda upp kvitton",
    Claims_Description_ViewBookkeeping: "Användaren får detaljerad inblick i bokföringen",
    Claims_Disbursements: "Utbetalningar",
    Claims_HasUser: "Endast insyn",
    Claims_ImportantDates: "Viktiga datum",
    Claims_Inbox: "Kvittoinkorg",
    Claims_Invoices: "Fakturor och offerter",
    Claims_InvoiceSettings: "Inställningar för faktura och offert",
    Claims_Reports: "Rapporter",
    Claims_Salaries: "Löner",
    Claims_SalarySettings: "Löneinställningar",
    Claims_SupplierInvoices: "Leverantörsfakturor",
    Claims_UploadBank: "Ladda upp från bank",
    Claims_UploadExpense: "Ladda upp",
    Claims_UploadReceipt: "Ladda upp kvitton",
    Claims_ViewBookkeeping: "Läsa bokföring",
    Clear: "Rensa",
    ClearFilters: "Rensa filter",
    ClearingNumber: "Clearingnummer",
    Close: "Stäng",
    Closed: "Stängt",
    Code: "Kod",
    ComingSoon: "Kommer snart",
    CommandPalette_SwitchCompany: "Byta företag",
    CommandSearch_GoTo_Prefix: "Gå till",
    CommandSearch_NoSearchResultFound: "Inget hittades...",
    CommandSearch_SearchInvoices: "Sök bland fakturor",
    CommandSearch_SearchSupplierInvoices: "Sök bland leverantörsfakturor",
    Comments_1Comment: "1 Kommentar",
    Comments_AddNewComment: "Lägg till ny kommentar",
    Comments_AddYourCommentWithMention: "Skriv din kommentar här, använd @ för att nämna",
    Comments_ManyComments: "{0} Kommentarer",
    Comments_ShowNewestFirst: "Visa nyaste först",
    Comments_ShowOldestFirst: "Visa äldst först",
    Company: "Företag",
    CompanyAccessDeniedPage_Content: "Du saknar behörighet att visa den här sidan.\nDet kan bero på att det inte finns något företag på denna adress eller att du inte är medlem i företaget.",
    CompanyChoose_Heading: "Välj företag",
    CompanyCommandPalette_BookkeepWithTemplate: "Mall för bokföring",
    CompanyCommandPalette_CaptionInstruction: "'Enter' genomför markerat val ('Ctrl'/'Cmd' + 'Enter' för att öppna i ny flik). Tryck 'Esc' för att avbryta.",
    CompanyCommandPalette_ManualBookkeeping: "Manuell bokföring",
    CompanyCommandPalette_SearchActions: "Sök kommandon",
    CompanyCommandPalette_SearchInvoices: "Fakturasökning",
    CompanyCommandPalette_SearchSupplierInvoices: "Leverantörsfakturasökning",
    CompanyDataStorageSettingsNotice_Cta: "Hantera lagring av data",
    CompanyDataStorageSettingsNotice_Message: "Med vår lagringstjänst har du möjlighet att få tillgång till din data om du tecknar en prenumeration i ett senare skede. Vill du lagra din data hos Bokio, måste du senast den 1 oktober 2024 acceptera villkoren för lagringstjänsten. För det fall du inte tecknar en prenumeration eller inte väljer att lagra din data kommer den att permanent raderas efter den 1 oktober.",
    CompanyDataStorageSettingsNotice_Title: "Vill du lagra din data i Bokio?",
    CompanyDetails: "Företagsdetaljer",
    CompanyInformation: "Företagsinformation",
    CompanyList_CreateATestCompany: "Skapa ett testföretag",
    CompanyList_SearchField_NoResults: "Inget resultat för \"{0}\"",
    CompanyList_SearchField_Placeholder: "Sök företag",
    CompanyName: "Företagsnamn",
    CompanySearchFetch: "Hämta från UC",
    CompanyStartedAt: "Företagets startdatum",
    CompanyType: "Företagstyp",
    CompanyUserRole_AccountOwner_Label: "Företagsägare",
    CompanyUserRole_ChangeOwner_ButtonText: "Byt ägare",
    CompanyUserRole_ChangeOwner_ModalHeading: "Byt företagsägare",
    CompanyUserRole_ChangeWarning_Heading: "Varning!",
    CompanyUserRole_ChangeWarning_Text: "Endast företagsägaren kan byta ägare. När du har bytt kan endast den nya ägaren byta ägandeskap av företaget.",
    CompanyUserRole_CurrentOwnerIs_Label: "Företaget ägs av",
    CompanyUserRole_NoCurrentOwner_Label: "Ingen användare är registrerad som ägare",
    CompanyVertical: "Verksamhet",
    Completed_Heading: "Klar",
    Confirm: "Bekräfta",
    ConfirmAndComplete: "Bekräfta och slutför",
    ConfirmAndDelete: "Bekräfta och ta bort",
    ConfirmChanges: "Bekräfta ändringar",
    ConfirmDeleteCommentMessage: "Vill du verkligen radera denna kommentar?",
    ConfirmDeleteTaskMessage: "Vill du verkligen radera denna uppgift?",
    ConfirmDeleteX: "Ta bort {0}?",
    ConfirmDeleteX_Title: "Bekräfta borttagning av {0}",
    ConfirmOrder: "Bekräfta beställning",
    ConfirmPassword: "Bekräfta lösenord",
    ConfirmRemoval: "Bekräfta borttagning",
    ConfirmSubscription: "Bekräfta prenumeration",
    ConfirmYourDetails: "Bekräfta dina uppgifter",
    Congratulations: "Grattis!",
    Connect_another: "Anslut en till",
    ContactAdministratorToFixProblem: "Du har inte behörigheter att ändra detta. Kontakta företagets administratör.",
    ContactInformation: "Kontaktinformation",
    ContactPerson: "Kontaktperson",
    ContactSupport_action: "Kontakta support",
    ContactSupportAgency: "Bokio Byrå kontakta support",
    Continue: "Fortsätt",
    CookieWarning: "Vi lagrar information på din enhet som krävs för att Bokio ska fungera. Läs mer i vår [cookiepolicy](https://www.bokio.se/gdpr/cookiepolicy/). Vi skulle också vilja lagra cookies för marknadsföring och analys som hjälper oss att göra Bokio bättre. Får vi göra det?",
    CookieWarning_Accept: "Ja, tillåt alla cookies",
    Copied_label: "Kopierad",
    Copy: "Copy",
    CopyAndPasteExactFormat: "Kopiera och klistra in (exakt format)",
    CorrectPersonalNumber: "och vara ett korrekt/giltigt personnummer",
    CountrySubdivision: "Riksdel",
    Create_Custom_Todo: "Skapa en ny uppgift",
    create_test_company: "Skapa testbolag",
    CreateCompany: "Skapa nytt företag",
    Created: "Skapad",
    CreatedBy: "Skapad av",
    CreatedOn: "Skapad den",
    CreatedOnX: "Skapad den {0}",
    CreateInvoice: "Skapa faktura",
    CreateNew: "Skapa ny",
    Credit: "Kredit",
    Csv_ExportAll_Button: "Exportera alla",
    Currency: "Valuta",
    CurrentChoicePostFix: " (aktuell)",
    CurrentFiscalYear: "Nuvarande räkenskapsår",
    CurrentPassword: "Nuvarande lösenord",
    Custom_Selection: "Anpassat urval",
    Custom_Todo_Description_Label: "Uppgiftsbeskrivning",
    Custom_Todo_DueDate: "Förfallodatum (valfritt)",
    Custom_Todo_Info_Message: "Uppgifter som du skapar är synliga for alla användare som kommer åt Saker att göra-sidan",
    CustomerNumber: "Kundnummer",
    CustomPeriod: "Egna datum",
    CustomTodo_Completed_EmptyState: "Det finns inga slutförda uppgifter att visa.",
    CustomTodo_Edit: "Redigera uppgift",
    CustomTodo_EmptyState_Text: "Skapa egna påminnelser eller andra att-göra-uppgifter.",
    CustomTodo_EmptyState_Title: "Inga uppgifter att visa",
    CustomTodos_CompletedTasks: "Slutförda uppgifter",
    DataSecurity: "Datasäkerhet och legalt",
    DataSecurityPageUrl: "https://www.bokio.se/gdpr",
    Date: "Datum",
    DateFilter_AllDates: "Alla datum",
    DateFilter_From: "Från {0}",
    DateFilter_Range: "Från {0} till {1}",
    DateFilter_Reset: "Rensa datum",
    DateFilter_To: "Till {0}",
    DateIntervalBetweenTwoDates: "{startdatum} till {slutdatum}",
    DateRange_Custom_label: "Annan period",
    DateRange_Last12Months_label: "Senaste 12 månaderna",
    DateRange_Last30Days_label: "Senaste 30 dagarna",
    DateRange_Last4Months_label: "Senaste 4 månaderna",
    DateRange_Last7Days_label: "Senaste 7 dagarna",
    DateRange_LastMonth_label: "Senaste månaden",
    DateRange_LastWeek_label: "Senaste veckan",
    DateRange_LastXDays_label: "Senaste {0} dagarna",
    day: "dag",
    DayPicker_ShortcutDown_label: "Kortkommando: pil ner",
    DayPicker_ShortcutUp_label: "Kortkommando: pil upp",
    days: "dagar",
    DDR_Accumulated: "Totalvärden",
    DDR_AddMetric: "Lägg till värde",
    DDR_Children: "Visa mer detaljer",
    DDR_Comparison: "Jämför med",
    DDR_ComparisonLabel: "Tidigare",
    DDR_ExploreNextLevel: "Utforska i mer detalj",
    DDR_FailedToGetComparisonOptions: "Kunde inte ladda perioder",
    DDR_Insights: "Fortsätt analysera",
    DDR_Level_AdministrationCosts: "Administrationskostnader",
    DDR_Level_CostOfGoods: "Varukostnader",
    DDR_Level_Costs: "Kostnader",
    DDR_Level_DepreciationCosts: "Avskrivningar",
    DDR_Level_Income: "Inkomster",
    DDR_Level_NetRevenue: "Nettoomsättning",
    DDR_Level_OfficeCosts: "Kontorskostnader",
    DDR_Level_OtherCosts: "Övriga kostnader",
    DDR_Level_OtherIncome: "Övriga intäkter",
    DDR_Level_Result: "Resultat",
    DDR_Level_SalaryCosts: "Lönekostnader",
    DDR_Level_SalesCosts: "Försäljningskostnader",
    DDR_LineChart: "Linjediagram",
    DDR_NoEarlierPeriods: "Det finns inga tidigare perioder att jämföra med",
    DDR_NotAccumulated: "Periodvärden",
    DDR_NotYearMonthOrQuarter: "Du kan bara jämföra om du har en månad, ett kvartal eller ett år valt",
    DDR_OtherLabel: "Inkludera Övrigt",
    DDR_PageTitle: "Resultanalys",
    DDR_Period: "Period att analysera",
    DDR_RemoveMetric: "Ta bort värde",
    DDR_SelectTags: "Välj taggar att jämföra",
    DDR_Table: "Tabell",
    Debit: "Debet",
    December: "December",
    Decline: "Neka",
    Declined_Nekad: "Nekad",
    DecreaseTrust: "Osäkra e-mail är inte tillåtna",
    Delete: "Ta bort",
    DeleteAccount: "Radera kontot",
    DeleteSelected: "Ta bort valda",
    Deleting: "Tar bort",
    Descending_label: "Fallande",
    Description: "Beskrivning",
    DeselectAll: "Avmarkera alla",
    Destroyed: "Förstörd",
    Details: "Detaljer",
    DirectorSetup_AccountsOfficeReferenceValidationError: "The Accounts Office Reference number isn't valid, it should be in the format 123AB12345678, please double check and enter the correct Accounts Office Reference",
    DirectorSetup_EmployerPayeValidationError: "The Employer PAYE Reference number isn't valid, it should be in the format 123/A45678, please double check and enter the correct Employer PAYE Reference",
    DirectorSetup_NINOValidationError: "The National Insurance number isn't valid, it should be in the format AB123456C, please double check and enter the correct National Insurance number",
    DirectorSetup_TaxCodeNotSupportedError: "We currently do not support this Tax code, so you will need to use a different software",
    DirectorSetup_TaxCodeValidationError: "The tax code isn't valid, please double check and enter the correct tax code",
    DirectorsPayrollRating_Feedback_Description: "Any thoughts that could help us improve your director's payroll experience?",
    DirectorsPayrollRating_Rate_Title: "How was your director's payroll experience?",
    Dismiss: "Avfärda",
    DismissTodoItemFeedback_TextInput_Title: "Varför var det inte intressant att göra detta? (valfritt)",
    DoesNotMatch: "Matchar ej",
    Done: "Klar",
    Download: "Ladda ned",
    Download_Excel: "Exportera till Excel",
    DownloadBankgiroFile: "Hämta Bankgirofil",
    DownloadData: "Ladda ner data",
    DownloadPdf: "Hämta pdf",
    DownloadSelected: "Ladda ner valda",
    Draft: "Utkast",
    DraftVerification_Todo_SectionTitle: "Schemalagd bokföring",
    DraftVerification_TodoPreview_Action: "Förhandsgranska",
    DueDate: "Förfallodatum",
    ECommerce: "E-handel",
    Edit: "Redigera",
    EditDescription_Placeholder: "Skriv in beskrivning här",
    EditInCompanySettings: "Redigera i företagsinställningar",
    EditMore: "Redigera mer",
    EditUploadModal_SaveAndCreateSupplierInvoice_Action: "Spara och skapa leverantörsfaktura",
    EditUploadModal_SaveAndRecord_Action: "Spara och bokför",
    EditUploadModal_SaveAndShowVerification_Action: "Spara och visa verifikat",
    EInvoice_VerifyCompany: "Verifiera ditt företag",
    EInvoice_VerifyCompanyMessage: "Du behöver verifiera ditt företag med BankID innan du kan aktivera e-faktura",
    Email: "E-post",
    Email_Bounced: "Kunde ej levereras",
    Email_Clicked: "Klickat",
    Email_Delivered: "Levererat",
    Email_Opened: "E-post öppnat",
    Email_Sent: "Skickat",
    Enddate: "Slutdatum",
    EnterEmail: "Ange email",
    EntityType: "Typ av entitet",
    EntityType_Organization: "Organisation",
    EntityType_PrivatePerson: "Privatperson",
    EquityAndLiabilities: "Eget kapital och skulder",
    Error_General: "Ett fel har uppstått, v.v. kontakta &lt;a href=\"mailto:support@bokio.se\"&gt;support@bokio.se&lt;/a&gt;",
    Error_Notification_Reference: "Referens: ",
    ErrorCode_InMessageToSupport: "Var god låt denna felkod vara med i meddelandet:",
    ErrorMessage_GenericWithErrorCode: "Något gick fel. Var god försök igen. Om problemet kvarstår kan du {0} och inkludera denna felkod: {1} i ditt meddelande.",
    ErrorMessage_GenericWithErrorCode_ContactSupportPart: "kontakta vår support",
    ErrorPage_CTA_Reload: "Börja om från början",
    ErrorPage_Footer: "Hjälpte det inte? {Kontakta support}",
    ErrorPage_Message: "Det blev något fel på sidan.\n\nVi har loggat felet och våra utvecklare jobbar på en lösning.",
    ErrorPage_Title: "Attans! Vi är hemskt ledsna!",
    Etc_abbreviation: "osv",
    ExcelFileAndFileEnding: "Excel-fil (.xlsx)",
    ExchangeRate: "Växlingskurs",
    ExpenseClaims: "Utlägg",
    Expired: "Utgånget",
    Export: "Exportera",
    ExportAll_Articles_Button: "Exportera alla artiklar",
    ExportAll_Customers_Button: "Exportera alla kunder",
    ExportAll_Employees_Button: "Exportera alla anställda",
    ExportAll_Invoices_Button: "Exportera alla fakturor",
    Failed: "Misslyckades",
    FailedPayment_Bar_Text: "Företaget har misslyckade betalningar, [se mer information]({0}). Detta bör åtgärdas för att företaget inte ska blockeras.",
    FBE_AccumulatedDifference: "Ackumulerad skillnad",
    FBE_DayDifference: "Dagens skillnad",
    FBE_Excel_Info: ".xlsx filen behöver ha minst en kolumn för datumet, en kolumn för summan och en kolumn för radtexten för att det ska fungera.",
    FBE_HideGoodDays: "Dölj dagar utan skillnad",
    FBE_InfoText: "För instruktioner se {0}. Detta är en tidig version och vi uppskattar både positiv och negativ {1} så vi kan hjälpa er hitta fel snabbare.",
    FBE_Period_BookkkeepingAccount_Label: "Bokföringskonto att leta fel på",
    FBE_Period_ToReview_Info: "Perioden måste matcha perioden för jämförelsedata som du väljer i nästa steg. Till exempel, om du exporterar en excel-fil från din bank för perioden 15 mars till 31 mars skulle det vara den period du väljer även här.",
    FBE_Period_ToReview_Title: "Period att granska",
    FBE_Promotion: "Vill du hitta bokföringsfel snabbare? Vi testar en ny funktion för det. Se \"Hitta bokföringsfel\" i \"...\" menyn för att testa.",
    FBE_Status_LooksGood: "Ser bra ut",
    FBE_Status_NeedsReview: "Behöver granskning",
    FBE_Summary_Days: "{0} dagar",
    FBE_Summary_Error_Description: "Du bör titta igenom alla dagar markerade som 'Behöver granskning' för att se om några verifikat behöver läggas till, tas bort eller ändras. Ett vanligt fall är att en verifikat hamnat på någon av granndagarna, du kan kolla på Ackumulerad skillnad för att se ",
    FBE_Summary_Error_Title: "Vi hittade dagar där din bokföring inte matchade jämförelsedatan",
    FBE_Summary_Success_Description: "Detta är en bra indikator på att du lyckades bokföra alla händelser för detta kontot.",
    FBE_Summary_Success_Title: "Alla dagar matchade jämförelsedatan! Bra jobbat!",
    FBE_TransactionFromAccount: "Transaktion från bokföringen",
    FBE_TransactionFromInput: "Transaktion från jämförelsedatan",
    FBE_ViewExpense: "Visa utlägg",
    FBE_ViewExpensePayment: "Visa betalning",
    FBE_ViewSupplierInvoice: "Visa leverantörsfaktura",
    FeatureFeedbackText: "För att kunna göra den här funktionen ännu bättre vill vi gärna ha din feedback",
    FeatureInterestInterestedFeedbackModal_HelpUsGetBetterAboutX_Description: "We always love to hear your feedback! If you want to share your thoughts about {0}, you are welcome to let us know so we can create a software that meets your needs.",
    FeatureInterestInterestedFeedbackModal_ThankYouForShowingInterextInX_Description_1: "These features aren’t available yet. We’re working on being able to offer {0} later this year.",
    FeatureInterestInterestedFeedbackModal_ThankYouForShowingInterextInX_Description_2: "Thanks to your participation we have more information about how we should design Bokio in the future. As a thank you, we promise to contact you when {0} is released so you will be first to get the new features.",
    FeatureInterestInterestedFeedbackModal_ThankYouForShowingInterextInX_Header: "Tack för att du visar intresse för {0}",
    FeatureToggle_ActivateNow_Action: "Aktivera nu",
    FeatureToggle_VacationTracking_Name: "Automatisk semesterbalans",
    FeatureToggles_AskingFeedback: "Berätta gärna varför du vill avaktivera {0}?",
    FeatureToggles_CantDisable: "Denna funktion kan inte avaktiveras utan att kontakta support",
    FeatureToggles_Disable: "Avaktivera",
    FeatureToggles_DisableFeature: "Avaktivera {0}?",
    FeatureToggles_Enable: "Aktivera",
    FeatureToggles_Enabled: "Aktiverad",
    FeatureToggles_FeatureIsDependantOfOtherFeatures: "Andra beta-funktioner måste aktiveras innan denna kan aktiveras",
    FeatureToggles_Title: "Beta-funktioner",
    FeatureToggles_TryOutOtherNewFeatures_button: "Prova våra andra {beta-funktioner}",
    February: "Februari",
    Feedback: "Feedback",
    FieldCanNotEmpty: "{name} måste anges",
    FieldMustBeInteger: "Måste vara ett heltal",
    FieldMustBeNumber: "Måste vara ett giltigt tal",
    FieldMustBePositiveInteger: "Måste vara ett positivt heltal",
    FieldMustContainNoExternalLink: "Externa länkar är inte tillåtna i fältet för {0}",
    FieldNotCorrectFormat: "{name} har inte rätt format",
    FieldNumberFromZeroToHundred: "{0} får endast vara mellan 0 och 100",
    FileName: "Filnamn",
    Files_Choose: "Välj filer",
    FilesTooLarge: "Dessa filer är för stora.",
    FileType: "Filtyp",
    Filter_ShowDecimals: "Visa decimaler",
    Filters_Filters_heading: "Filter",
    Filters_GroupVerifications_action: "Gruppera kopplade",
    Filters_ShowInactiveVerifications_action: "Visa inaktiva",
    FindAgencyPage_ManualLink: "Skriv in redovisningsbyråuppgifter manuellt",
    FindAgencyPage_PageDescription: "För att importera företagsuppgifterna direkt från UC, vänligen ange **organisationsnummer eller namn på din byrå**.",
    FindAgencyPage_PageTitle: "Hitta din redovisningsbyrå",
    FindAgencyPage_SearchForOrgNumberOrName_Label: "Namn eller org-nummer på byrå",
    FindAgencyPage_SkipText: "Hittar du inte din redovisningsbyrå? {0}",
    FindBookkeepingErrors_ComparisonText_Description: "Jämförelsedata behöver vara i detta format",
    FindBookkeepingErrors_CopyPaste_FormatExample: "2019-01-01 2000.00 ExempelText\n2019-02-01 -100.00",
    FindBookkeepingErrors_Dates_Description: "Måste vara i ISO format (yyyy-MM-dd)",
    FindBookkeepingErrors_Dates_Key: "Datum",
    FindBookkeepingErrors_FailedToParse_ErrorText: "Kunde inte läsa din data",
    FindBookkeepingErrors_Numbers_Description: "Måste vara utan mellanslag och kommatecken. Använd \".\" som decimalavskiljare. Utan decimaler fungerar också",
    FindBookkeepingErrors_Numbers_Key: "Nummer",
    FindBookkeepingErrors_TextField_Label: "Klistra in din jämförelsedata",
    FindOutMore_action: "Utforska nu",
    FirstName: "Förnamn",
    FirstNames: "Förnamn",
    FiscalYear: "Räkenskapsår",
    FixedAssets_Menu: "Tillgångar",
    FixNow: "Fixa nu",
    Forbidden: "Åtkomst nekad",
    ForgotPassword: "Glömt lösenord?",
    Free: "Gratis",
    Friday: "fredag",
    From: "Från",
    Fullname: "Fullständiga namn",
    FullYear: "Hela året",
    GeneralAppError_Content: "Försök igen om en stund eller {kontakta support}.",
    GeneralAppError_Title: "Något gick fel",
    GeneralFeedback_WriteYourMessageHere_text: "Skriv dit meddelande här",
    GetStarted: "Kom igång",
    GiveFeedback: "Ge feedback",
    GoBack: "Gå tillbaka",
    GoToSettings: "Gå till inställningar",
    GroupBy: "Gruppera",
    Help_Action: "Hjälp",
    Help_ChooseACategory: "Välj en kategori",
    Help_ChooseAnArea: "Välj ett område",
    Help_ChooseASubCategory: "Välj en underkategori",
    Help_DescribeWhat: "Beskriv vad du vill ha hjälp med",
    Help_HowCanWeHelpYou: "Hur kan vi hjälpa dig?",
    Help_Search: "Sök",
    Help_SendingMessage: "Skickar meddelande...",
    Help_Type: "Jag vill",
    Help_Type_Feedback: "Ge feedback",
    Help_Type_Question: "Ställ en fråga",
    Help_Type_Technical: "Rapportera ett fel",
    Help_UploadAttachments: "Bifoga filer i ditt meddelande till oss",
    help_us_to_improve: "Hjälp oss att bli bättre",
    Help_WhatIsYourQuestion: "Vad vill du kontakta oss om?",
    HelpCenter_ArticleError: "Vi lyckades inte ladda denna hjälpsidan",
    HelpCenter_label: "Hjälpcenter",
    HelpConfirmationHeading: "Meddelandet skickat!",
    HelpConfirmationText: "Tack för att du kontaktar oss! Vi svarar till din registrerade e-postadress så snart vi kan. ",
    HelpEmptyResult1: "Tyvärr, vi kunde inte hitta någon hjälpartikel för din sökning.",
    HelpEmptyResult2: "Du kan prova att fråga på vår {Facebook community hjälpsida} , eller kontakta vår support.",
    HelpSendMessage: "Skicka meddelande",
    HelpTitle: "Vad kan vi hjälpa dig med?",
    Here: "här",
    Hi: "Hej",
    HiName: "Hej {0}",
    hour: "timme",
    hours: "timmar",
    Ignore: "Ignorera",
    IllegalExpression: "Otillåtet uttryck",
    Import: "Importera",
    ImportantDate: "Viktiga datum",
    ImportantDate_Action_HideCategory: "Dölj alla liknande uppgifter",
    ImportantDate_Action_HideImportantDate: "Dölj uppgiften",
    ImportantDate_Action_MarkAsDone: "Markera som klar",
    ImportantDate_Action_MarkAsNotDone: "Markera som inte klar",
    ImportantDate_Action_SetInProgress: "Pågående",
    ImportantDate_Action_UndoInProgress: "Ej pågående",
    ImportantDate_Action_Unhide: "Visa uppgiften",
    ImportantDate_Action_UnhideCategory: "Visa alla liknande uppgifter",
    ImportantDate_Label_Completed: "Klar",
    ImportantDate_ReadMoreAtSkatteverket: "Läs mer",
    ImportantDate_RefersToPeriod: "Avser perioden {0} till {1}",
    ImportantDates_Feedback_Title: "Har du någon feedback på viktiga datum?",
    ImportantDates_Onboarding_CheckCompanyDetails_message: "Kan du ta en titt på följande information om {0} åt oss.",
    ImportantDates_Onboarding_heading: "Du är ett steg närmare viktiga datum!",
    ImportantDates_Onboarding_PleasecContactSupport_message: "Om du anser att ovanstående inställningar redan är korrekta så kontakta support.",
    ImportantDates_Onboarding_UpdateCompanySettings: "Uppdatera företagsinställningar",
    ImportArticles: "Importera artiklar",
    ImportCustomers: "Importera kunder",
    ImportEmployees: "Importera anställda",
    Inactivate: "Inaktivera",
    Inactive: "Inaktiv",
    Inbox: "Kvittoinkorg",
    Inbox_Limitation_BannerText: "Du behöver Premium för att kunna läsa in nya meddelanden.",
    Included: "Inkluderad",
    IncreaseTrust: "Osäkra e-mail är tillåtna",
    informationNeededForCompany: "Innan du fortsätter behöver vi följande information om företaget",
    informationNeededForEmployee: "Innan du fortsätter behöver vi följande information om den anställda",
    InProgress: "Pågående",
    InputField_FillInCharacters_placeholder: "Fyll i {0} tecken",
    InputField_FillInDigits_placeholder: "Fyll i {0} siffror",
    Integration_Singular: "Integration",
    Integrations_iZettle_Title: "Bokför försäljning automatiskt - {Zettle}",
    Integrations_Stripe_Description: "",
    Integrations_Stripe_Title: "",
    InvalidCode: "Ogiltig kod",
    InvalidPassword: "Felaktigt lösenord",
    InviteAndComplete: "Bjud in och slutför",
    Invoice: "Faktura",
    InvoiceApplication: "Fakturering",
    Invoices: "Fakturor",
    InvoicingRating_Feedback_Confirmation: "Vill du berätta mer om din upplevelse av att skapa fakturor i Bokio? Boka en tid för en interjvu [här](https://calendly.com/bokio-sales/intervju-med-bokio-faktura).",
    InvoicingRating_Feedback_Description: "Har du några tankar som kan hjälpa oss att förbättra din faktureringsupplevelse?",
    InvoicingRating_Rate_Title: "Hur var din faktureringsupplevelse?",
    IsRequiredError: "är obligatoriskt",
    Item: "Föremål",
    ItemNotFound: "Kunde inte hittas",
    January: "Januari",
    JournalPage_Sum_Heading: "Debet/Kredit",
    July: "Juli",
    JumpTo: "Gå till",
    June: "Juni",
    KeepMeLoggedIn: "Håll mig inloggad",
    LanguageEnglish: "Engelska",
    LanguageSwedish: "Svenska",
    LastName: "Efternamn",
    LastUpdated: "Senast uppdaterad",
    LearnMore: "Mer information",
    LeavePagePrompt: "Vill du verkligen lämna sidan? Dina ändringar kommer gå förlorade",
    Left: "Vänster",
    LinkToMobileApp: "https://www.bokio.se/hjalp/komma-igang/skapa-ett-konto/har-bokio-en-mobilapp/",
    Loading: "Laddar",
    LoadingImage: "Laddar bild",
    LoadMore: "Ladda fler",
    LogIn: "Logga in",
    Logo_heading: "Logotyp",
    LogoAction: "Ladda upp logotyp",
    LogoChange: "Byt logotyp",
    LogotypeAltText: "Bokio logotyp",
    LogOut: "Logga ut",
    LoyaltyProgram_BronzeTier: "Brons nivå",
    LoyaltyProgram_GoldTier: "Guld nivå",
    LoyaltyProgram_LoyaltyProgram: "Byråpartner",
    LoyaltyProgram_RegisterInterest: "Anmäl intresse",
    LoyaltyProgram_RegisterInterest_SuccessHeading: "Tack för din intresseanmälan!",
    LoyaltyProgram_RegisterInterest_SuccessMessage: "Vi på Bokio kommer att kontakta dig så fort som möjligt",
    LoyaltyProgram_RegisterInterestEnquiry: "Anmäl Byråpartner intresse",
    LoyaltyProgram_SilverTier: "Silver nivå",
    LoyaltyProgram_StatusInactive: "Inaktivt",
    LoyaltyProgram_StatusPending: "Behandlas",
    LoyaltyProgram_ViewTiers: "Visa nivåer",
    LoyaltyProgram_ViewTiersLink: "https://www.bokio.se/for-redovisningsbyraer/",
    MailLinker_Campaign_Preamble: "Följande företag har tillgång till erbjudandet och är kopplade till användaren {0}.",
    MailLinker_NoCompanies: "Det finns ingen aktiv kampanj på något av företagen kopplade till {0}. Det kan också vara så att erbjudandet har gått ut.",
    Manage: "Hantera",
    ManuallyRecorded: "Manuellt bokförd",
    March: "Mars",
    MarkAsManuallyRecorded: "Markera som manuellt bokförd",
    Matches: "Matchar",
    MatchManually: "Matcha manuellt",
    MatchP: "Bästa matchning",
    Materials: "Underlag",
    MaximumFileSize: "Den maximala filstorleken är {0} MB per fil.",
    May: "Maj",
    MaybeLater: "Kanske senare",
    MentionsTextArea_caption: "{0} kommer att få en notis",
    MentionsTextArea_Placeholder: "Använd @ för att mention",
    Menu_Accounted: "Bokfört",
    Menu_AccountingServices: "Bokföring och bokslut",
    Menu_AccountsReceivable: "Kundreskontra",
    Menu_AdditionalServices: "Tilläggstjänster",
    Menu_AgedCreditors: "Leverantörsreskontra",
    Menu_Articles: "Artiklar",
    Menu_BalanceReport: "Balansrapport",
    Menu_Bank: "Bank",
    Menu_BankAndTaxAccounts: "Bank och skattekonton",
    Menu_Closures: "Bokslut",
    Menu_CurrentCompany: "Aktivt bolag",
    Menu_Customers: "Kunder",
    Menu_DailyTakings: "Dagskassa",
    Menu_Directors_Payroll: "Directors payroll",
    Menu_Employees: "Anställda",
    Menu_ETR_MenuTitle: "Händelser att bokföra",
    Menu_Expenses_All: "Alla utlägg",
    Menu_Expenses_MyExpenses: "Mina utlägg",
    Menu_Expenses_Payments: "Utbetalningar",
    Menu_HireAccountant: "Anlita en konsult",
    Menu_Invoices: "Fakturor",
    Menu_InvoicesReport: "Fakturarapporter",
    Menu_Ledger: "Huvudbok",
    Menu_MyPayslips: "Mina löner",
    Menu_Offers: "Erbjudanden",
    Menu_Payments: "Betalningar",
    Menu_Profile: "Profil",
    Menu_Quotes: "Offerter",
    Menu_ResultReport: "Resultatrapport",
    Menu_RotRutErrands: "Rot/Rut-ärenden",
    Menu_Salaries: "Löner",
    Menu_SalaryOverview: "Löner",
    Menu_SalaryReports: "Lönerapporter",
    Menu_Sales: "Försäljning",
    Menu_SupplierInvoices_Invoices: "Leverantörsfakturor",
    Menu_Suppliers: "Leverantörer",
    Menu_TagReport: "Taggrapport",
    Menu_Transactions: "Transaktioner att bokföra",
    Menu_Vacation: "Semester",
    Menu_VatReport: "Momsrapport",
    MenuButton_Upload_CTA: "Skapa ny",
    MenuCurrentPlan_1DayLeftOfAccess: " dag kvar innan din åtkomst förloras",
    MenuCurrentPlan_1DayLeftOfTrial: " dag kvar av din provperiod",
    MenuCurrentPlan_PricePlan_Title: "Plan",
    MenuCurrentPlan_XDaysLeftOfAccess: " dagar kvar innan din plan löper ut",
    MenuCurrentPlan_XDaysLeftOfTrial: " dagar kvar av din provperiod",
    MenuItem_Accounts: "Konton",
    MenuWhatsNew_label: "Nyheter",
    MenuWhatsNew_ReleaseNotesLink: "https://www.bokio.se/release-notes/",
    MenuWhatsNew_ReleaseNotesLink_Agency: "https://www.bokio.se/release-notes/?id=agency/",
    MenuWhatsNew_ReleaseNotesLink_Company: "https://www.bokio.se/release-notes/?id=company/",
    Message: "Meddelande",
    MessageOptional: "Meddelande (valfritt)",
    Monday: "måndag",
    Month: "Månad",
    MoreInformation: "Mer information",
    MoreThan: "Fler än {0}",
    Multiple: "Flertalet",
    MyAccount: "Mitt konto",
    Name: "Namn",
    Nbr: "Nr.",
    NeedsReconnection: "Behöver återanslutas",
    New: "Nytt",
    NewUpdate_Text: "Det finns en nyare version av Bokio",
    Next: "Nästa",
    NextStep: "Nästa steg",
    NFilesSelected: "{0} filer valda",
    NFilesWillBeDeleted: "{0} filer kommer tas bort",
    No: "Nej",
    No_Continue_CTA: "Nej, fortsätt",
    NoAccount: "Inget konto",
    NoCancel: "Nej, avbryt",
    None: "Ingen",
    NoPermissions_Message: "Kontakta administratören för ditt företag eller annars Bokios support.",
    NoSearchResults: "Inga sökresultat",
    NoSearchResults_filter_message: "Hmm, vi hittade ingenting. Prova att söka på något annat",
    NotActivated: "Ej aktiverat",
    NotDone: "Ej klar",
    NoThanks: "Nej tack",
    NothingToShow: "Just nu finns det inget att visa här",
    Notification_TargetEmail: "E-Post",
    Notification_TargetMobile: "Push",
    NotificationFlyout_Earlier: "Äldre",
    NotificationFlyout_MarkAllAsRead: "Markera alla som lästa",
    NotificationFlyout_Notifications: "Notiser",
    NotificationFlyout_Recent: "Senaste",
    NotificationFlyout_UnreadNotifications_P: "olästa notiser",
    NotificationFlyout_UnreadNotifications_S: "oläst notis",
    NotificationsEmptyState_Content: "Dina nya notiser kommer att visas här.",
    NotificationsEmptyState_Title: "Du har inga notiser än",
    NotSet: "Ej vald",
    NotSpecified: "Inte specificerad",
    November: "November",
    NPS_Heading: "Hur sannolikt är det att du rekommenderar {0} till dina vänner?",
    NPS_Label_Good: "Vad gillar du med Bokio?",
    NPS_Label_Improve: "Hur kan vi bli bättre?",
    NPS_Message_1To4: "Tack för din feedback! Hur tycker du att vi kan vi ge dig en bättre upplevelse?",
    NPS_Message_5To8: "Tack för din feedback! Då behöver vi bli bättre. Kan du hjälpa oss att förstå hur?",
    NPS_Message_9To10: "Tack för din feedback! Hur tycker du vi kan bli bättre?",
    NPS_NotAtAll: "Inte alls troligt",
    NPS_NotThisTime: "Inte denna gång",
    NPS_Review_Description: "Vi uppskattar verkligen att du gillar vad vi gör och skulle bli så glada om du vill dela ditt omdöme på Trustpilot. Det tar bara två minuter.",
    NPS_Review_Title: "Tack för din feedback!",
    NPS_ReviewOnTrustpilot: "Ge ett omdöme på Trustpilot",
    NPS_ReviewOnTrustpilot_Link: "https://se.trustpilot.com/evaluate/bokio.se",
    NPS_SendFeedback: "Skicka feedback",
    NPS_VeryLikley: "Extremt troligt",
    October: "Oktober",
    OK: "OK",
    OkGotIt_action: "Okej, jag fattar",
    OnboardingChecklist_Active_BBA_Button: "Kom igång",
    OnboardingChecklist_Active_BBA_heading: "Öppna företagskonto",
    OnboardingChecklist_Active_BBA_text: "Banktjänst fullt integrerad i Bokio för enklare bokföring, mer kontroll och bekvämlighet.",
    OnboardingChecklist_App_Heading: "Ladda ner mobilappen",
    OnboardingChecklist_App_Text: "Undvik kvitton som försvinner genom att fota dem direkt med vår mobilapp",
    OnboardingChecklist_CC_Text: "Sätt upp ditt företag och starta din provperiod",
    OnboardingChecklist_Checklist_intro: "Här kan du hitta tips om saker som är relevanta för att komma igång med ditt företag.",
    OnboardingChecklist_Connect_to_bank_button: "Koppla till bank",
    OnboardingChecklist_Connect_To_Bank_Heading: "Synka dina transaktioner",
    OnboardingChecklist_Connect_To_Bank_Text: "Spara tid och automatisera din bokföring genom att koppla Bokio till din bank.",
    OnboardingChecklist_Continue: "Fortsätt",
    OnboardingChecklist_Create_Invoice_Button: "Skapa en faktura",
    OnboardingChecklist_Create_Invoice_Heading: "Pröva på fakturering",
    OnboardingChecklist_Create_Invoice_Text: "Utforska fakturering i Bokio - lägg till detaljer i fakturan, publicera, registrera betalning och bokför",
    OnboardingChecklist_Create_Payroll_Button: "Utforska nu",
    OnboardingChecklist_Create_Payroll_Heading: "Utforska Bokios löne-hantering",
    OnboardingChecklist_Create_Payroll_Text: "Testa lösningen i Bokio för att hantera dina anställdas löner.",
    OnboardingChecklist_Create_your_company_button: "Sätt igång",
    OnboardingChecklist_Create_your_company_Heading: "Nytt företag i Bokio",
    OnboardingChecklist_Download_the_mobile_app_button: "Ladda ner mobilappen",
    OnboardingChecklist_Import_Heading: "Importera tidigare bokföring",
    OnboardingChecklist_Import_previous_bookkeeping_button: "Starta importguiden",
    OnboardingChecklist_Import_Text: "Följ Bokios guide för att importera din tidigare bokföring",
    OnboardingChecklist_Important_Dates_Button: "Anpassa datum",
    OnboardingChecklist_Important_Dates_heading: "Dina viktiga datum",
    OnboardingChecklist_Important_Dates_text: "Göm en uppgift bland dina viktiga datum som är irrelevant för dig. Använd ikonen med tre punkter.",
    OnboardingChecklist_Mobile_App_Heading: "Bokio on-the-go",
    OnboardingChecklist_MobileText: "Gör bankärenden och scanna in kvitton och fakturor när du är på språng med vår mobilapp. \n- Scanna snabbt in kvitton och fakturor för dina inköp\n- Signera och skapa betalningar Bokio Företagskonto\n- Se banktransaktion för Bokio Företagskonto och andra banker du har kopplat\n- Få notifikation för köp med ditt Bokiokort\n- Scana in och hantera din utlägg",
    OnboardingChecklist_Pending_Status: "Bearbetar",
    OnboardingChecklist_Record_Receipt_Or_Expense_Button: "Bokför nu",
    OnboardingChecklist_Record_Receipt_Or_Expense_Heading: "Bokför ett kvitto eller en utgift",
    OnboardingChecklist_Record_Receipt_Or_Expense_Text: "Testa Bokios bokföringsmallar genom att bokföra kostnader och utgifter.",
    OnboardingChecklist_Remove_item_heading: "Är du säker?",
    OnboardingChecklist_Remove_item_text: "Du håller på att ta bort en uppgift från listan. Du kan enkelt hitta uppgiften igen om du väljer 'visa dolda uppgifter' under ikonen med tre punkter.",
    Open: "Öppet",
    Open_CTA: "Öppna",
    OpenInNewTab: "Öppna i ny flik",
    OptionalDescription: "Valfri beskrivning",
    Or: "Eller",
    Order_label: "Ordning",
    OrderFailed: "Beställning misslyckades",
    OrderOngoing: "Pågående beställning",
    OrgNumber: "Organisationsnummer",
    Other: "Annan",
    Overview: "Översikt",
    PageNotFoundHeading: "Sidan kunde inte hittas",
    PageNotFoundText: "ToCompleteYourRegistration",
    PageOf: "av",
    Pages: "sidor",
    Pagination_ItemsPerPage: "Artiklar per sida",
    Paid: "Betald",
    Password: "Lösenord",
    PasswordChanged: "Lösenordet har ändrats",
    PasswordConfirmMismatch: "Lösenorden matchade inte",
    Payment: "Betalning",
    PaymentDate: "Betaldatum",
    PaymentFailedTitle: "Senaste felmeddelande från Stripe:",
    PaymentFile_BankAccount_FailedToParse: "Kunde inte läsa",
    PaymentFile_BgFileInfo: "Bankgiro-filer kan användas för att snabbt ladda upp flera betalningar till din bank. Men du måste köpa den extratjänsten från din bank innan det fungerar.",
    PaymentFile_GenerateFile: "Skapa fil",
    PaymentFile_InvalidAccountNumber: "Ogiltigt kontonummer: Du måste ha ett korrekt kontonummer för att skapa filen",
    PaymentFile_InvalidBankgiro: "Ogiltigt Bankgiro: Du måste ha ett korrekt Bankgironummer för att skapa filen",
    PaymentFile_ItemsSelected: "Inkludera {0} betalningar på totalt {1}",
    PaymentMethod: "Betalningsmetod",
    Payments: "Betalningar",
    Payroll: "Lön",
    Period: "Period",
    PeriodEnd: "Periodens slut",
    PeriodStart: "Periodens start",
    Permissions: "Rättigheter",
    Personal_Number: "Personnummer",
    PersonalSettings: "Personliga inställningar",
    PhoneNumber: "Telefon",
    PhoneNumber_CountryCode: "Landsnummer",
    PickFromUploads: "Välj från uppladdat",
    Plans_NoPermission: "Du har inte behörighet att ändra betalplan på detta företaget.",
    Plans_PayingAgencyInfo: "Du har automatiskt {0} eftersom att du är kund hos {1}. Du behöver inte välja en plan.",
    please_wait: "Var vänlig vänta",
    PleaseContact_OurSupport: "Vänligen kontakta vår support",
    PleaseContactSupport_action: "Kontakta gärna support",
    PleaseHelpUsWithSomeSettings: "Hjälp oss med lite inställningar ",
    PleaseTryAgainLaterOrContactSupport: "Vänligen försök igen senare eller {kontakta supporten}.",
    PMatch: "{0} matchning",
    PMatch_NoData: "Ingen data",
    PMatch_NoData_Explanation: "Det fanns inget datum eller summa utläst eller manuellt tillagt för detta underlaget så det kunde inte matchas",
    PMatchText: "Datum matchar {0}, Summa matchar {1}",
    PoweredByInEnglish: "Powered by",
    PP_DowngradeBlock_ContactSupport: "Var vänlig kontakta support om du vill byta betalperiod",
    Previous: "Föregående",
    PriceExclVAT: "Pris (exkl. moms)",
    PricePlan: "Plan",
    PricePlan_CurrentPlan: "Nuvarande plan",
    PricePlan_EndsOn: "Slutar {0}",
    PricePlan_MenuText: "Du har tillgång till alla bokföringsfunktioner gratis under provperioden",
    PricePlan_ReactivatePlan: "Återaktivera din plan",
    PricePlan_ReadMoreAboutBusinessAccount: "Läs mer om Bokio Företagskonto",
    PricePlan_ReadMoreAboutBusinessAccount_Link: "https://www.bokio.se/foretagskonto/",
    PricePlan_SelectPlan: "Välj plan",
    PricePlan_TrialUntil: "till {0}",
    PricePlan_Upgrade: "Uppgradera",
    PricePlan_ViewPlans: "Visa planer",
    PricePlan_WithBusinessAccount: "Med företagskonto",
    PricePlan_WithoutBusinessAccount: "Utan företagskonto",
    PriceplanCheckoutModal_SubscriptionPeriod: "Prenumerationsperiod: {0} till {1}",
    PricePlanTrial: "Gratis provperiod",
    Print: "Skriv ut",
    Priority: "Prioritet",
    PrivacyPolicyLink_Text: "Integritetspolicy",
    Processing: "Bearbetar",
    Quantity: "Antal",
    Quarter: "Kvartal",
    QuarterPrefix: "Q",
    QuotingRating_Feedback_Description: "Har du några tankar som kan hjälpa oss att förbättra din upplevelse kring offerter?",
    QuotingRating_Rate_Title: "Hur var din upplevelse med att använda offerter?",
    Rating_Bad: "Dålig",
    Rating_Excellent: "Utmärkt",
    Rating_Good: "Bra",
    Rating_Okay: "Okej",
    Rating_VeryBad: "Usel",
    ReadMore: "Läs mer",
    ReadMoreHere: "Läs mer {här}.",
    ReadyToOrder: "Redo att beställas",
    Receipt: "Kvitto",
    ReceiptUpload_FileTypeNotSupported: "Filformatet stöds ej. Välj en .png, .pdf eller .jpeg fil",
    ReceiptUpload_PDFTooBig: "Filen är för stor. Maximal PDF filstorlek är 4 MB",
    ReceiptZoomInShortcut: "Ctrl + Scrollhjul",
    RecentLogin_Action: "Bekräfta",
    RecentLogin_Description: "Eftersom du håller på att utföra en känslig ändring måste du verifiera ditt konto innan du kan fortsätta.",
    RecentLogin_Header: "Verifiera din identitet",
    Recommended: "Rekommenderad",
    Reconcile_CompareTo_CopyAndPaste_Text: "Jämför detta kontot med data som du copy och pastar. Detta är flexibelt men svårare att använda",
    Reconcile_CompareTo_Expenes_Text: "Jämför detta kontot med anställdas utlägg i Bokio. Detta hjälper dig att hitta skillnader mellan kontot och anställdas utlägg",
    Reconcile_CompareTo_FileOption_Text: "Du kan ladda upp en Excel-fil (.xslx) som innehåller data. Uppgifterna måste finnas i det första arket och måste innehålla ett datum, en text och en summa.",
    Reconcile_CompareTo_SupplierInvoices_Text: "Jämför detta kontot med leverantörsfakturorna i Bokio. Detta hjälper dig att hitta skillnader mellan kontot och leverantörsfakturorna",
    Reconcile_CompareTo_TaxAccount_Text: "Jämför med datan vi har hämtat från din integration med Skatteverket. Vi hämtade data längre tillbaka i tiden än vad vi importerade som rader att bokföra så att du kan stämma av längre bak i tiden.",
    Reconnect: "Återanslut",
    ReconnectWith: "Återanslut med",
    Record: "Bokför",
    Recorded: "Bokförd",
    RecurringInvoices: "Återkommande fakturor",
    Refresh: "Uppdatera",
    Refund: "Återbetalning",
    Refunds: "Återbetalningar",
    RegisterInterest_action: "Anmäl intresse",
    Rejected: "Nekad",
    Remove: "Ta bort",
    Remove_Account: "Ta bort konto",
    RemoveAll: "Ta bort alla",
    Removed: "Borttagen",
    RemoveReminder_action: "Ta bort påminnelse",
    Reports: "Rapporter",
    RequiredInformation: "* Obligatorisk information",
    Reset_Action: "Återställ",
    ResetFilters_Action: "Återställ filter",
    ResetToDefault: "Återställ till standardinställningar",
    Resubmit_action: "Uppdatera och skicka in igen",
    Right: "Höger",
    Role: "Roll",
    Rotating: "Roterar",
    SalaryApplication: "Lön",
    Saturday: "lördag",
    Save: "Spara",
    SaveAnyway: "Spara ändå",
    SaveAsDraft: "Spara utkast",
    SaveChanges: "Spara ändringar",
    Saved: "Sparad",
    Savings: "Besparingar",
    Scheduled: "Schemalagd",
    Search: "Sök",
    Searching: "Söker...",
    SearchTags: "Sök efter taggar",
    Select: "Välj",
    SelectAccount: "Välj konto",
    SelectAll: "Markera alla",
    SelectColumns: "Välj kolumner",
    SelectPeriodOrRange: "Välj period eller intervall",
    SelectTags: "Välj taggar",
    SelectType: "Välj typ",
    Send: "Skicka",
    SendAgain: "Skicka igen",
    SendFeedback: "Skicka feedback",
    September: "September",
    Settings: "Inställningar",
    Share: "Dela",
    ShouldBeOfTheFormat: "Ska ha formatet",
    Show_N_Hits: "Visa träffar ({0})",
    ShowAll: "Visa alla",
    ShowMoreContent: "Visa mer",
    ShowXResults: "Visa {0} resultat",
    ShowXVerifications_action: "Visa {0} verifikationer",
    Sie: "SIE",
    Skatteverket: "Skatteverket",
    Skip: "Hoppa över",
    SkipForLater: "Hoppa över",
    SomethingWentWrong_TryAgainOrContactSupport: "Förlåt, något gick fel. Vänligen försök igen om en stund, eller {kontakta support}",
    SomethingWentWrongWeAreSorry: "Något gick fel. Vi beklagar!",
    Sort: "Sortera",
    SortBy: "Sortera efter",
    StaffAndSalaries_label: "Personal och löner",
    Startdate: "Startdatum",
    Status: "Status",
    StepXofY: "Steg {0} av {1}",
    Subject: "Ämnesrad",
    Submit: "Skicka",
    SuccessModal_Understand: "Jag förstår",
    Sum: "Summa",
    Summary: "Sammanfattning",
    SummerCampaign_GetStarted: "Kom igång med Bokio Business",
    Sunday: "söndag",
    Supplier: "Leverantör",
    SupplierInvoice: "Leverantörsfaktura",
    SuppliersPurchases_label: "Leverantörer och inköp",
    Support_AwaitingSupport_status: "Väntar på svar",
    Support_InformationNeeded_status: "Behöver mer information",
    Support_SupportReceived_status: "Har fått svar",
    SupportMessage_Active: "Pågående",
    SupportMessage_EmptyActive: "Det finns för närvarande inga aktiva supportmeddelanden för dig. Nya meddelanden kommer synas i \"Saker att göra\".",
    SupportMessage_EmptyResolved: "Det finns för närvarande inga klara supportmeddelanden.",
    SupportMessage_Reactivate: "Återaktivera",
    SupportMessage_Resolve: "Klarmarkera",
    SupportMessage_Resolved: "Avslutade",
    SupportMessages_Title: "Supportmeddelanden",
    SupportMessages_Todo_GoTo: "Öppna meddelande",
    Tags_AddLink: "Lägg till nya taggar",
    Tags_AddTag: "Lägg till tagg",
    Tags_AddTagsTitle: "Lägg till tagg(ar)",
    Tags_CompareGroup: "Jämför taggar i gruppen",
    Tags_DisableBulkTagging: "Deaktivera multitaggning",
    Tags_EmptyState: "Taggar hjälper dig att få bättre kontroll på din ekonomi. För att komma igång, Lägg till en ny Taggrupp, t.ex projekt, kostnadsställen eller kunder, och lägg till de taggar du vill ha i gruppen. Du kan sedan tagga händelser och se rapporter för dessa taggar",
    Tags_EmptyState_Title: "Inga aktiva taggar",
    Tags_EnableBulkTagging: "Aktivera multitaggning",
    Tags_FailedTag_DuplicateTag: "Denna taggen fanns redan",
    Tags_FailedTag_Error: "Okänt fel när taggen skulle sparas",
    Tags_FailedTag_WeightError: "Totalen av alla taggar i gruppen var över 100%. Gränsen finns för att garantera meningsfulla rapporter.",
    Tags_FailedToTagVerification: "Kunde inte tagga {0}",
    Tags_Feedback: "Taggar är en ny funktion som vi aktivt jobbar på att förbättra. Har du några önskemål eller annan feedback så tar vi gärna emot denna.",
    Tags_FilterBy: "Filtrera på",
    Tags_Heading: "Taggar",
    Tags_LearnMore: "Läs mer om taggar",
    Tags_PercentageLabel: "Procent av totalen",
    Tags_Report: "Taggrapport",
    Tags_RowModeMessage: "* Kan bara tagga rader på grund av tidigare taggar",
    Tags_Save_FailMessage: "Vi kunde inte spara {1} tagg(ar)",
    Tags_Save_PartialFailMessage: "Du har sparat {0} tagg(ar). Vi kunde inte spara {1} tagg(ar)",
    Tags_Save_SuccessMessage: "Du har sparat {0} tagg(ar)",
    Tags_Selector_Placeholder: "Sök på prefix och namn",
    Tags_SelectTagLabel: "Välj en tagg",
    Tags_SetupLink: "Aktivera taggar för att få mer avancerade rapporter",
    Tags_SplitBy: "Gruppera på",
    Tags_StopTagging: "Sluta tagga",
    Tags_TagSettings: "Inställningar för taggar",
    Tags_Untagged: "Otaggade",
    Tags_VerificationModeMessage: "* Kan bara tagga hela verifikatet på grund av tidigare taggar",
    Task: "Uppgift",
    Tasks: "Uppgifter",
    TaxAccount: "Skattekonto",
    TaxAccountBumper: "Om du aktiverar Skattekontosynken så behöver du inte längre bokföra skatterna manuellt.",
    TaxAccountBumper_CTA: "Aktivera Skattekontosynk",
    TaxAccountSync_Connect: "Lägg till Skattekontosynk",
    TaxAccountSync_ConnectError_AlreadyConnectedBookkeepingAccount: "Du har ett annat bankkonto kopplat till bokföringskonto 1630 så vi kan inte koppla skattekonto. Vänligen försök igen efter du har åtgärdat det.",
    TaxAccountSync_ConnectError_InvalidOrgNumber: "Organisationsnumret verkar inte vara giltigt",
    TaxAccountSync_ConnectSuccess_Text: "Du har nu aktiverat skattekonto-synken. När du ska bokföra hämtar du sedan transaktionerna snabbt och smidigt. Det kan du göra genom att klicka in på Skattekonto från banklistan eller i Transaktioner att bokföra.",
    TaxAccountSync_ConnectSuccess_Title: "Skattekontosynk är nu aktiverad",
    TaxAccountSync_DateToSyncFrom: "Datum att synka från",
    TaxAccountSync_DateToSyncFrom_ExtraInfo: "Datumet bör sättas så att det inte överlappar med manuella importer för Skattekontot. Just nu räknar vi ut detta och tillåter inte att du ändrar det. Om du idag manuellt importerar rader för skattekontot så bör du importera upp till detta datum innan du slår på synken.",
    TaxAccountSync_FetchTransactions: "Hämta transaktioner",
    TaxAccountSync_FetchTransactions_ApprovalInfo: "När du synkar kommer du bli skickad till Skatteverket för att godkänna att vi läser av Skattekontot. Vi kommer endast hämta transaktioner för detta företaget.",
    TaxAccountSync_FetchTransactions_Success: "Hämtade {0} nya transaktion(er)",
    TaxAccountSync_FetchTransactions_SuccessNoTransactions: "Skattekontot hade redan alla händelser",
    TaxAccountSync_ReasonToConnect: "Koppla Bokio till ditt Skattekonto för att snabbt hämta alla skattetransaktioner så att du får rätt siffror och sparar tid när du bokför.",
    TaxAccountSync_StatusMessage: "Synkade senast skattekontot {0}",
    TaxAccountSync_StatusMessage_Empty: "Synka ditt Skattekonto för första gången",
    TaxesSalary: "Skatter: Lön",
    TaxesVat: "Skatter: Moms",
    TaxPeriod_NoEuTradeDeadlineInfo: "Om du inte har sålt till EU länder under perioden är deadline {0}",
    TaxPeriod_NoTaxes: "Det fanns inga skatter denna perioden",
    TaxPeriod_RecordedTaxes: "Bokförda skatter",
    TaxPeriod_TaxDeductionDate: "Datum skatterna drogs från Skattekontot",
    TaxPeriod_TaxPaymentInfoText: "Du behöver betala {0} till Bankgiro {1} (Skatteverket) med OCR {2} före {3}. Med Bokio Företagskonto kan du betala direkt från den här sidan och transaktionen bokförs automatiskt. I annat fall betalar du från din bank och bokföringen hanteras enklast från transaktioner att bokföra med mallen insättning till skattekontot.",
    TaxPeriod_WaitingForTaxAccountText: "Bra jobbat och fortsätt så! Eftersom du har Skattekontosynk hanteras bokföringen av skatterna från {0}.",
    Terminated: "Avslutat",
    Terms_Accept_Action: "Acceptera villkor",
    Terms_AllowMarketing_Label: "Jag samtycker till att Bokio får använda mina personuppgifter för målgruppsanpassad marknadsföring genom annonsplattformarna  Google (Google Ads och Display och Video 360), Linkedin och Meta (Facebook och Instagram). Ditt samtycke hjälper oss att kunna erbjuda mera relevanta annonser och att vi kan exkludera befintliga användare från annonsering. Information om användningsområden finns att läsa i vår integritetspolicy",
    Terms_CurrentTerms_Text: "Dessa är de för närvarande gällande avtalen för Bokio.",
    Terms_CurrentTerms_Title: "Nuvarande villkor, version {0}",
    Terms_EULA: "Allmänna villkor",
    Terms_ProcessorAgreement: "Personuppgiftsbiträdesavtal ",
    Terms_ReadAndAccept_EulaOnly_Label: "Jag har läst och accepterar {0} ",
    Terms_ReadAndAccept_Label: "Jag har läst och accepterar både {0} och {1}",
    Terms_UpdatedTerms: "Uppdaterade villkor",
    TestCompany_Label: "Testbolag",
    TestCompany_ThisActionIsNotAvailable: "Tyvärr går inte detta att testa i ett testföretag. Du behöver skapa ett riktigt företag för att fortsätta med detta.",
    TestCompanyTopBar_Description: "Välkommen att utforska Bokio. Bokför, skapa fakturor eller administrera löner!",
    TestCompanyTopBar_Header: "Detta är ett testbolag",
    TestCompanyTopBar_SetUp_Action: "Skapa ditt riktiga företag",
    ThankForYourFeedback: "Tack för din kommentar",
    ThankYou: "Tack",
    ThingsToDo_label: "Saker att göra",
    Thursday: "torsdag",
    Time_DaysAgo_P: "{0} dag sedan",
    Time_DaysAgo_S: "{0} dagar sedan",
    Time_EditedXTimeAgo: " Redigerad för {0}",
    TIMEAGO_AGO: "sedan",
    TIMEAGO_DAY: "en dag",
    TIMEAGO_DAYS: "%d dagar",
    TIMEAGO_HOUR: "en timma",
    TIMEAGO_HOURS: "%d timmar",
    TIMEAGO_IN: "i",
    TIMEAGO_MINUTE: "en minut",
    TIMEAGO_MINUTES: "%d minuter",
    TIMEAGO_MONTH: "en månad",
    TIMEAGO_MONTHS: "%d månader",
    TIMEAGO_SECONDS: "mindre än en minut",
    TIMEAGO_YEAR: "ett år",
    TIMEAGO_YEARS: "%d år",
    TimeGranularity: "Precision",
    Tip: "Tips",
    Title: "Titel",
    To: "Till",
    Today: "Idag",
    Todo: "Att göra",
    Todo_Action_Approve: "Godkänn",
    Todo_Action_Attest: "Attestera",
    Todo_Action_Bookkeep: "Bokför",
    Todo_Action_BookkeepPayment: "Bokför betalning",
    Todo_Action_Complete: "Jag gjorde klart detta",
    Todo_Action_GoToFiscalYear: "Gå till räkenskapsår",
    Todo_Action_MatchPayment: "Koppla betalning",
    Todo_Action_NotInterested: "Jag är inte intresserad",
    Todo_Action_Postpone: "Påminn mig senare",
    Todo_Action_RegisterPayment: "Registrera betalning",
    Todo_Action_Reject: "Neka",
    Todo_Action_SchedulePayment: "Schemalägg betalning",
    Todo_Action_Upload: "Bokför via uppladdat",
    Todo_Action_YesApprove: "Ja, godkänn",
    Todo_Action_YesConnect: "Ja, koppla",
    Todo_Action_YesReject: "Ja, neka",
    Todo_ApproveConfirmation_Title: "Är du säker på att du vill godkänna detta underlag?",
    Todo_Closure_GoTo: "Gå till bokslut",
    Todo_ClosureUpcomingDescription: "Upprätta och lämna in din årsredovisning och inkomstdeklaration 2",
    Todo_ConfirmConnectVerification_Text: "Vill du koppla verifikatet {0} till den importerade bankraden? Vi kopplar ihop dessa så att du senare på ett enkelt sätt kan verifiera att alla dina bankhändelser har ett verifikat.",
    Todo_ConfirmConnectVerification_Title: "Koppla till verifikat",
    Todo_ConfirmDelete_Title: "Vill du verkligen radera?",
    Todo_ConfirmDeleteAutoVerificationRows_Text_P: "{0} bankrader kommer tas bort.",
    Todo_ConfirmDeleteAutoVerificationRows_Text_S: "En bankrad kommer tas bort.",
    Todo_ConfirmDeleteExpenses_Text_S: "Ett utlägg kommer tas bort.",
    Todo_ConfirmDeleteReceipts_Text_P: "{0} kvitton kommer att tas bort.",
    Todo_ConfirmDeleteReceipts_Text_S: "Ett kvitto kommer tas bort.",
    Todo_ConfirmDeleteSupplierInvoices_Text_S: "En leverantörsfaktura kommer tas bort.",
    ToDo_DailyTakingsRow_Title: "Du har händelser från Zettle som behöver hanteras",
    Todo_Date_DueInDays_P: "Förfaller om {0} dagar",
    Todo_Date_DueInDays_S: "Förfaller imorgon",
    Todo_Date_DueToday: "Förfaller idag",
    Todo_Date_OverdueDays_P: "{0} dagar försenad",
    Todo_Date_OverdueDays_S: "1 dag försenad",
    Todo_DisableMatchPayment: "Inaktivera matching",
    Todo_DisableMatchPayment_Text: "Inaktivera matchning av denna importerade bankrad. Bankraden kommer att flyttas som omatchad till Bokför material.",
    Todo_DoNow_EmptyState_Message: "Påbörjat något nytt? Skapa en uppgift eller påminnelse här.",
    Todo_DoNow_EmptyState_Title: "Bra jobbat, du är i fas!",
    Todo_Feedback_Title: "Har du någon feedback på Saker att göra?",
    Todo_GoToExpenses: "Gå till utlägg",
    Todo_GoToInboxMessage: "Öppna meddelande",
    Todo_GoToInvoice: "Gå till faktura",
    Todo_GoToInvoiceDraft: "Gå till fakturautkast",
    ToDo_GoToIZettleIntegration: "Gå till Zettle-integration",
    Todo_GoToPayroll: "Gå till lönekörning",
    Todo_GoToRecurringInvoice: "Gå till återkommande faktura",
    Todo_GoToSupplierInvoice: "Gå till leverantörsfakturan",
    Todo_GotoVatReport: "Gå till momsrapporten",
    ToDo_ImportantDates_heading: "Viktiga datum",
    ToDo_ImportantDates_ShowCompleted: "Visa färdiga datum",
    ToDo_ImportantDates_ShowHidden: "Visa dolda datum",
    Todo_Mark_as_done: "Markera som klar",
    Todo_Match_MultipleMatchesFound: "{0} {1} matchningar hittades",
    Todo_Match_SingleBankRowMatch: "Matchning hittad med bank rad {0}",
    Todo_Match_SingleInvoiceMatch: "Matchning hittad med {0}",
    Todo_Match_SingleSalaryIncomeTaxMatch: "Matchar inkomstskatt för perioden {0}",
    Todo_Match_SingleSalarySocialFeesMatch: "Matchar arbetsgivaravgifter för perioden {0}",
    Todo_Match_SingleSupplierInvoiceMatch: "Matchning hittad med leverantörsfaktura {0}",
    Todo_Match_SingleVatTaxesMatch: "Matchar momsdragning för perioden {0}",
    Todo_Match_SingleVerificaitonMatch: "Matchning hittad med verifikat {0}",
    Todo_MatchModal_LoadEarlier: "Ladda tidigare matchningar",
    Todo_MatchModal_LoadLater: "Ladda senare matchningar",
    Todo_MatchModal_ManualMatchDateRange: "Visar {0} resultat mellan {1} och {2}",
    Todo_MatchModal_ManualMatchDateRangeSingular: "Visar {0} resultat mellan {1} och {2}",
    Todo_MatchModal_ManualMatchingPrompt: "Kan du inte hitta det du letar efter?",
    Todo_MatchModal_ManualMatchingSubHeading: "Om ingen av de ovanstående matchar kan du ladda fler relaterade matchningar",
    Todo_MatchModal_MatchHeading: "{0} föreslag på matchningar hittades för ovanstående {1}",
    Todo_MatchModal_NoManualMatchesFound: "Inga relaterade matchningar hittades mellan {0} och {1}",
    Todo_MatchModal_SingularMatchHeading: "{0} föreslagen matchning hittades för ovanstående {1}",
    Todo_Now: "Att göra nu",
    Todo_Now_Page_Title: "Nu",
    Todo_PrelTax_AdjustSums: "Justera summor",
    Todo_PrelTax_ManualPayment: "Manuell betalning",
    Todo_PrelTax_NoSetupForYear: "Du har inte skapat betalningar för preliminärskatt för {0} ännu. Klicka här för att skapa betalningar.",
    Todo_PrelTax_Paid: "Betalat",
    Todo_PrelTax_PaymentDate: "Betalningsdatum",
    Todo_PrelTax_PaymentDetails: "Betalningdetaljer",
    Todo_PrelTax_RegisterPayment: "Registrera betalning",
    Todo_PrelTax_RemovePayment: "Radera betalning",
    Todo_PrelTax_RemovePaymentConfirmation: "Är du säker på att du vill radera betalningen?",
    Todo_PrelTax_SetupTaxesModal_MonthlyAmount: "Summa att betala per månad",
    Todo_PrelTax_SetupTaxesModal_Paragraph1: "Ange det du har fått från ditt beslut så vi kan beräkna hur mycket du ska betala tillsammans med andra skatter och avgifter under året.",
    Todo_PrelTax_SetupTaxesModal_Paragraph2: "Du hittar dina beslut på {0} under ",
    Todo_PrelTax_SetupTaxesModal_Paragraph2Italic: "Visa uppgifter om deklarationsobjektet.",
    Todo_PrelTax_SetupTaxesModal_SkatteverketLink: "https://sso.skatteverket.se/so/epd_web/visa_deklarationsobjekt",
    Todo_PrelTax_SetupTaxesModal_Title: "Justera debiterad preliminärskatt",
    Todo_PrelTax_Title: "Preliminärskatt",
    Todo_PrelTax_TotalSum: "Summa totalt",
    Todo_PrelTaxStatus_NothingToPay: "Inget att betala",
    Todo_PrelTaxStatus_Overdue: "Försenad",
    Todo_PrelTaxStatus_Overpaid: "Överbetald",
    Todo_PrelTaxStatus_Paid: "Betald",
    Todo_PrelTaxStatus_Scheduled: "Schemalagd",
    Todo_PrelTaxStatus_Underpaid: "Underbetald",
    Todo_PrelTaxStatus_Unknown: "Okänd",
    Todo_PrelTaxStatus_Unpaid: "Obetald",
    Todo_RejectConfirmation_Title: "Är du säker på att du vill neka detta underlag?",
    Todo_ReorderSections: "Ändra sortering",
    Todo_Section_Approve: "Godkänn",
    Todo_Section_AssignedTasks: "Tilldelade uppgifter",
    Todo_Section_Bank: "Bank",
    Todo_Section_Bookkeep: "Bokför",
    Todo_Section_BookkeepPayment: "Bokför betalningar",
    Todo_Section_CompanyOnboarding: "Kom igång med Bokio",
    Todo_Section_CompleteClosure: "Färdigställ bokslut",
    Todo_Section_GetPayment: "Att få betalt för",
    Todo_Section_MyTasks: "Mina uppgifter",
    Todo_Section_NewPaymentMethods: "Nya betalmetoder",
    Todo_Section_Pay: "Saker att betala",
    Todo_Section_Promotions: "Rekommendationer för ditt bolag",
    Todo_Section_Review: "Granska",
    Todo_Section_SchedulePayment: "Planera betalning",
    Todo_Section_SupportMessages: "Supportmeddelanden",
    Todo_SectionGroup_ActivateCard: "Aktivera ditt kort",
    Todo_SectionGroup_Bank: "Bank",
    Todo_SectionGroup_BankPricePlan: "Uppgradera ditt plan",
    Todo_SectionGroup_Closures: "Bokslut",
    Todo_SectionGroup_DailyPayments: "Hantera dina dagliga betalningar",
    Todo_SectionGroup_DepositMoney: "Sätt in pengar",
    Todo_SectionGroup_EasyAccounting: "Bokföring som är lätt att förstå",
    Todo_SectionGroup_Expenses: "Utlägg",
    Todo_SectionGroup_GetAccountant: "Få hjälp av en expert",
    Todo_SectionGroup_Invoices: "Fakturor",
    Todo_SectionGroup_ManageBankConnection: "Hantera bankkoppling",
    Todo_SectionGroup_ManageExpenses: "Hantera utlägg",
    Todo_SectionGroup_ManageSalaries: "Hantera löner",
    Todo_SectionGroup_ManageSwish: "Hantera Swish",
    Todo_SectionGroup_Material: "Uppladdningar",
    Todo_SectionGroup_OrderCard: "Beställ ditt kort",
    Todo_SectionGroup_Salary: "Löner",
    Todo_SectionGroup_Savings: "Spara",
    Todo_SectionGroup_SendProfessionalInvoices: "Skicka professionella fakturor",
    Todo_SectionGroup_SupplierInvoices: "Leverantörsfakturor",
    Todo_SectionGroup_TrackBills: "Håll koll på dina räkningar",
    Todo_SectionGroup_Vacation: "Semester",
    Todo_SectionGroup_Vat: "Moms",
    Todo_Settings_Page_Title: "Att göra inställningar",
    Todo_Summary_BankTransactions_P: "Du har {0} transaktioner att bokföra",
    Todo_Summary_BankTransactions_S: "Du har en transaktion att bokföra",
    Todo_Upcoming: "Att göra kommande",
    Todo_Upcoming_EmptyState_Message: "Vill du planera någonting? Skapa en faktura eller ladda upp ett underlag för en kommande period.",
    Todo_Upcoming_EmptyState_Title: "Du har inga kommande saker att göra.",
    Todo_Upcoming_Page_Title: "Kommande",
    Todo_WaitingForSomeoneElse: "Väntar på någon annan",
    TOM_CTA: "Uppgradera nu",
    TOM_Heading: "Tack för att du testat Bokio, din provperiod är nu slut",
    TOM_Heading_OnPlan: "Din prisplan är nu aktiv!",
    TOM_Text: "Välj en plan för att fortsätta med Bokios tjänster",
    TOM_Text_OnPlan: "Din provperiod är slut och planen {0} har aktiverats på ditt företag.",
    Total: "Totalt",
    TotalExclVAT: "Totalt (exkl. moms)",
    TotalInclVAT: "Totalt (inkl. moms)",
    TOTP_VerifyCode_Action: "Verifiera kod",
    TrialBalanceExcel: "Trial Balance (Excel)",
    TrialExtendedModal_CTA: "Fortsätt med provperiod",
    TrialExtendedModal_Heading: "Vi har nu förlängt din provperiod med en extra månad!",
    TrialExtendedModal_Message: "Fortsätt att utforska våra funktioner själv från Översiktssidan eller [boka en gratis konsultation](https://calendly.com/bokiokomigang/kom-igang-med-bokio?month=2023-05&amp;date=2023-06-08) med oss och få extra hjälp med att komma igång.",
    TryAgain: "Försök igen",
    TTR_RuleSuggestion_Link: "Visa detaljer eller ändra regel",
    TTR_RuleSuggestion_Multiple: "Rekommendera alltid mallarna [{0}] när meddelandet {1} '{2}'",
    TTR_RuleSuggestion_Single: "Rekommendera alltid {0} när meddelandet {1} '{2}'",
    Tuesday: "tisdag",
    Type: "Typ",
    Uncategorised: "Okategoriserad",
    Undo: "Ångra",
    UndoBookkeepingForTaxes: "Ångra bokföring av skatter",
    UndoManuallyRecorded: "Ångra manuellt bokförd",
    Unknown: "Okänt",
    Upcoming: "Kommande",
    Update: "Uppdatera",
    Updated: "Updaterad",
    UpgradePlan_CTA: "Uppgradera plan",
    UpgradeToX: "Uppgradera till {0}",
    Upload: "Ladda upp",
    Upload_DontHaveAReceiptYet: "Saknar du ditt kvitto i Bokio? Inga problem!",
    UploadedBy: "Uppladdat av",
    UploadedByX: "Uppladdat av: {0}",
    UploadedOn: "Uppladdningsdatum",
    UploadImageTypesRestriction: "Endast jpg- eller png-filer",
    Uploads: "Uppladdat",
    User_Action_Connect: "Koppla",
    User_Action_Connect_To: "Koppla användare till {0}",
    User_Action_Invite: "Bjud in",
    User_Label_SelectUserToConnect: "Användare att koppla",
    User_Message_NotConnectedMessage: "{0} är inte kopplad till en användare. Koppla en användare om du vill att {0} ska kunna logga in. Det behövs för att använda Utlägg.",
    User_Message_TheEmployeeIsConnectedTo: "{0} är kopplad till användaren {1}",
    User_Option_InviteNewUser: "Bjud in användare",
    UserPermission_BokioSupportUser: "Bokio Support",
    Users: "Användare",
    Validation_Action_DoLater_Required: "Kan fyllas i senare",
    Validation_Action_DoNow_Required: "Fyll i nu",
    Validation_Action_FixErrors: "Fixa problem",
    Validation_AllowedCharacters: "Tillåtna tecken i {0} är a-zA-Z0-9åäöÅÄÖ,.-",
    Validation_AtLeastOnInList: "Det behöver finnas minst ett element i listan",
    Validation_ContainsWhitespace: "Får ej innehålla blanksteg",
    Validation_DateGreaterThanOrEqual_Must: "Välj ett datum som är {0} eller senare",
    Validation_DateIsNotOneOf_Must: "Datum stöds inte",
    Validation_DateLessThan_Must: "Välj ett datum innan {0}",
    Validation_DateLessThanOrEqual: "Välj ett datum som inte är senare än {0}",
    Validation_Equal: "Får endast vara {0}",
    Validation_GreaterThan_Must: "Ange ett tal som är större än {0}",
    Validation_GreaterThanOrEqual_Must: "Ange ett tal som är minst {0}",
    Validation_IncorrectEUVATFormat: "Momsnumret matchar inte formatet för {0}: [{1}]",
    Validation_Invoices_PleaseSelectACustomer_text: "Var god välj en kund för fakturan",
    Validation_Invoices_SelectOneItemRow_text: "Var god lägg till åtminstone en rad till fakturan",
    Validation_IsInvalid: "{0} är ogiltigt",
    Validation_IsNotAlphanumeric: "Endast siffror och bokstäver är tillåtet.",
    Validation_IsNotSupportedCurrency: "{fieldName} måste vara en tillåten valuta",
    Validation_ItemIsMissing_S_text: "{0}  saknas",
    Validation_ItemsAreMissing_P_text: "{0} saknas",
    Validation_MaxLength: "Får vara maximalt {0} tecken",
    Validation_MinLength: "Måste minst vara {0} tecken",
    Validation_MustBeInCurrency: "Måste vara i {0}",
    Validation_NeedToBeReviewed_P_text: "{0} behöver granskas",
    Validation_NonExistingDate: "Byt till ett existerande datum",
    Validation_NonZero_Must: "Värdet får inte vara 0",
    Validation_NotContainOnlySpaces: "{fieldName} får inte innehålla enbart mellanslag",
    Validation_NotEqualTo_Must: "Värdet får inte vara {0}",
    Validation_NotInList: "Värdet {0} används redan",
    Validation_OrgNumber_NotValid: "Organisationsnumret är inte giltigt",
    Validation_RangeLength: "Måste vara mellan {0} och {1} tecken",
    Validation_SmallerThanOrEqual_Must: "Ange ett tal som är mindre eller lika med {0}",
    Validation_Summary_DoLater_Title_P: "Du kan fixa till dessa {0} problem senare.",
    Validation_Summary_DoLater_Title_S: "Du kan fixa detta senare.",
    Validation_Summary_DoNow_Title_P: "Efter du har löst dessa {0} problem kan du spara.",
    Validation_Summary_DoNow_Title_S: "Nästan klar! Fixa bara till ett problem.",
    Validation_ValidEmail: "Byt till en giltig email",
    Validation_Zero_Must: "Värdet måste vara noll",
    VATCheck_MappingId_label: "Mappnings-ID",
    VatNumber: "Momsnummer",
    VATPeriodClosed_Warning: "Överväg att öppna momsperioden eller bokför detta verifikat i början av nästa öppna momsperiod.",
    VATPeriodClosed_Warning_Title: "Bokföring i en stängd momsperiod",
    VATPeriodStarted_Warning_Title: "Bokföring i en förlikad momsperiod",
    VatSetting_ExternalVatProvider: "Registered for VAT",
    VatSetting_FixedQuarterlyFeb: "*FEL*",
    VatSetting_FixedQuarterlyJan: "*FEL*",
    VatSetting_FixedQuarterlyMar: "En gång per kvartal",
    VatSetting_FixedYearlyApr: "*FEL*",
    VatSetting_FixedYearlyAug: "*FEL*",
    VatSetting_FixedYearlyDec: "En gång om året",
    VatSetting_FixedYearlyFeb: "*FEL*",
    VatSetting_FixedYearlyJan: "*FEL*",
    VatSetting_FixedYearlyJul: "*FEL*",
    VatSetting_FixedYearlyJun: "*FEL*",
    VatSetting_FixedYearlyMar: "*FEL*",
    VatSetting_FixedYearlyMay: "*FEL*",
    VatSetting_FixedYearlyNov: "*FEL*",
    VatSetting_FixedYearlyOct: "*FEL*",
    VatSetting_FixedYearlySep: "*FEL*",
    VatSetting_Monthly: "Varje månad",
    VatSetting_NotRegisteredForVat: "Ej momsregistrerad",
    VatSetting_NotSet: "Inte satt",
    VatSetting_Quarterly: "En gång per kvartal",
    VatSetting_Yearly: "En gång om året",
    VerificationCode_TOTP: "Verifieringskod (från 2FA-app)",
    VerifyYourCompanyModal_Message: "Verifiera ditt företag med BankID",
    VerifyYourCompanyModal_Title: "Verifiera ditt företag",
    View: "Visa",
    ViewAll: "Visa alla",
    ViewDetails: "Se detaljer",
    ViewInTodo_ButtonText: "Visa i att göra-listan",
    ViewSettings: "Visa inställningar",
    Visit_Backoffice: "Besök Bokio Byrå",
    Voucher_Flow_ActivateOnCompanyCTA: "Aktivera rabatten på ditt företag",
    Voucher_Flow_CreateAccountCTA: "Skapa ett nytt konto med rabatten",
    Voucher_Flow_CreateCompanyCTA: "Skapa ett nytt företag med rabatten",
    Voucher_Flow_CreateDiscountInformation: "Rabatten appliceras när du köper en prisplan efter att du har skapat företaget. Köpet behöver göras under tiden rabatten är aktiv.",
    Voucher_Flow_Description: "För att aktivera rabatten på rätt konto behöver vi lite information. Du kan aktivera rabatten på flera företag genom att gå tillbaka hit igen.",
    Voucher_Flow_DoesTheCompanyExists: "Finns företaget redan i Bokio?",
    Voucher_Flow_DoYouHaveAnAccount: "Har du redan en inloggning hos Bokio?",
    Voucher_Flow_Title: "Aktivera rabatt",
    WantFeatureFeedback: "Du använder en ny funktion och för att kunna göra den ännu bättre vill vi gärna ha din feedback",
    Wednesday: "onsdag",
    Week: "Vecka",
    WeekPrefix: "V",
    WelcomeMessage_Viktor_CEO: "VD",
    WelcomeMessage_Viktor_Name: "Viktor Stensson",
    WelcomeToBokio: "Välkommen till Bokio",
    WhatsNext: "Vad kommer härnäst?",
    Yes: "Ja",
    Yes_Cancel_CTA: "Ja, avbryt",
    YesDelete: "Ja, ta bort",
    YesGoBack: "Ja, gå tillbaka",
    YesNoFeedback_IsBankRulesHelpful: "Är bankregler till hjälp för dig?",
    YesNoFeedback_WasThisInformationHelpful: "Var informationen till hjälp?",
    YesRemove: "Ja, ta bort",
    YouAreUsingBokioForCountryX: "Du använder Bokio för {0}",
    Your: "Ditt",
    YourAccount: "Ditt konto",
    YourChangesHaveBeenSaved: "Dina ändringar har sparats!",
    YourContactEmail: "Företagets e-post för kundkontakt",
    YourName: "Ditt namn",
  };
}