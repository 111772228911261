/* eslint-disable */

import type { AccountingLang } from "../types/AccountingLang";
import { translateTempImpl } from "../TranslatableLanguage";

export function AccountingLangDef(): AccountingLang {
  return {
    translateTemp: (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB),
    
    AccountBalance: "Kontosaldo {0}: {1}",
    Accounted: "Bokfört",
    Accounted_NoBookkeepingEmptyState_heading: "Du har ännu inte bokfört något för den här perioden.",
    Accounted_NoBookkeepingEmptyState_message: "All bokföring för {0} kommer att synas här.",
    Accounted_NoPeriodsFound_Error: "Kunde inte hitta den valda perioden. Om problemet kvarstår, kontakta support.",
    Accounted_NoValidPeriod_Error: "Den valda perioden tillhör inte någon av räkenskapsåren. Vänligen kontakta support.",
    Accounted_yourCompany_inTextmessage: "Ditt företag",
    AccountingType: "Bokföringsmetod",
    AccountingType_Cash: "Kontantmetoden",
    AccountingType_Invoice: "Fakturametoden",
    AccountingType_InvoiceWithCashVAT: "* UK Specific accounting method (Cash VAT)",
    AccountingType_SoleTraderNotVatRegistered_Cash: "* UK Specific",
    AccountingType_SoleTraderNotVatRegistered_Invoice: "* UK Specific",
    AccountingType_SoleTraderVatRegistered_Cash: "* UK Specific",
    AccountingType_SoleTraderVatRegistered_Invoice: "* UK Specific",
    AccountingType_SoleTraderVatRegistered_InvoiceWithCashVAT: "* UK Specific",
    AccrualFundsNotice_Heading: "Byt kontonamn",
    AccrualFundsNotice_Text: "Vet du att du kan ändra namn på periodiseringskonton i kontoplanen för att vara i bättre synk mellan kontonummer och kalenderår? {Läs mer om hur man ändrar namn på periodiseringskonton.}",
    AdditionalFiles: "Övriga filer",
    AdditionalFilesEmptyState_Content: "Här kan du ladda upp filer som inte ska bli underlag för verifikat men som ändå är relevanta. T.ex bankkontoutdrag eller skattebilagor.",
    AdditionalFilesEmptyState_Title: "Inga övriga filer att visa",
    AdditionalFileToBigNotice: "En eller flera filer var för stora och kommer inte att laddas upp. Den maximalt tillåtna filstorleken är {0} MB.",
    AdditionalFileUpload_FileTypeNotSupported: "En eller fler valda filer stödjs ej. Stödda filformat är .pdf, .png, .jpeg, .txt, .csv och .xls.",
    AddPage: "Lägg till sida",
    AddReceiptsModal_AddReceipt: "Koppla kvitto till transaktion",
    AddReceiptsModal_DetachReceipt: "Koppla ifrån kvitto från transaktion",
    AddSupplier: "Lägg till leverantör",
    AdvancedCustomTemplate_ExpressionInfo_SubHeading: "Du kan använda formler för att specificera dina debet- och kreditbelopp. Se exempel nedan.",
    AdvancedCustomTemplate_ExpressionInfo_Title: "Tillgängliga variabler och matematiska funktioner",
    AllAccounts: "Alla konton",
    AllDirections: "Alla betalningar",
    AreYouAnAccountant: "Är du redovisningskonsult?",
    AreYouAnAccountant_No: "Nej, jag är inte redovisningskonsult",
    AreYouAnAccountant_ThankYou: "Tack för din feedback!",
    AreYouAnAccountant_TogetherWeMakeBokio: "Tillsammans gör vi Bokio ännu bättre!",
    AreYouAnAccountant_WeAppreciate: "Tack för att du tog dig tid att svara på vår fråga.",
    AreYouAnAccountant_WeAreCurrentlyLooking: "Vi försöker just nu identifiera redovisningskonsulter i Bokio så att vi kan göra appen ännu bättre för professionella användare.",
    AreYouAnAccountant_Yes: "Ja, jag är redovisningskonsult",
    Asset_AccumulatedAmount: "Summa avskrivet",
    Asset_AlsoDeleteVerification: "Vill du också ta bort verifikatet för denna tillgång?",
    Asset_Archive_Button: "Arkivera",
    Asset_ArchiveModal_Text: "Om du inte längre äger tillgången (t.ex. om den är såld, kasserad eller har gått sönder) ska du ta bort den från ditt anläggningsregister. Händelsen behöver då bokföras manuellt. {Läs om hur du bokför det här.} \n \nÄr du säker på att du vill ta bort tillgången från registret?",
    Asset_ArchiveModal_Title: "Arkivera",
    Asset_AssetDetailsModalTitle: "Tillgångsdetaljer",
    Asset_AssetDetailsSaved: "Tillgångsdetaljerna har sparats.",
    Asset_AssetJournalEntry: "Tillgångsverifikat",
    Asset_AssetLifespan: "Livslängd",
    Asset_AssetLifetime_Years: "{0} år",
    Asset_Category: "Kategori",
    Asset_ConnectedJournalEntries: "Kopplade verifikat",
    Asset_CreateAssetForJournalEntry: "Skapa anläggningstillgång",
    Asset_Delete: "Ta bort anläggningstillgång",
    Asset_DeleteConfirmation: "Är du säker på att du vill ta bort denna anläggningstillgång?",
    Asset_Depreciate_Action: "Registrera avskrivning",
    Asset_DepreciationJournalEntry: "Avskrivningsverifikat",
    Asset_DepreciationMethod_InputLabel: "Avskrivningsmetod",
    Asset_DepreciationMethod_Linear: "Linjär",
    Asset_DepreciationPeriod_InputLabel: "Avskrivningsperiod",
    Asset_DepreciationPeriod_Yearly: "Årligen",
    Asset_Description: "Beskrivning",
    Asset_EmptyState_Description: "Du har inte lagt till några tillgångar än. När du har köpt och bokfört tillgångar kommer du att se dem här.",
    Asset_EmptyState_Title: "Inga tillgångar att visa",
    Asset_ItemDetails: "Detaljer",
    Asset_LearnMoreAboutAssets: "Lär dig mer om tillgångar",
    Asset_LearnMoreAboutAssets_Link: "https://www.bokio.se/hjalp/bokforing/bokforingshjalp/sa-bokfor-du-anlaggningstillgangar/",
    Asset_LeftToDepreciate: "Kvar att avskriva",
    Asset_Lifetime_Tooltip: "Livslängden för en tillgång är den tid den anses vara användbar i ditt företag. Rekommenderad livslängd beror på tillgångstyp. [Du kan läsa mer om riktlinjerna här](https://www.bokio.se/hjalp/bokforing/bokforingshjalp/sa-bokfor-du-anlaggningstillgangar/)",
    Asset_LifetimeCustom_InputLabel: "Annan livstid",
    Asset_LifetimeCustom_Label: "Annan",
    Asset_Method_Tooltip: "Med den linjära avskrivningsmetoden skrivs din tillgång av med samma belopp varje år, förutom att den är propertionell till hur länge du har haft tillgången under räkenskapsåret. Om du har köpt den när hälften av ditt räkenskapsår är kvar, så kommer du avskriva hälften av den årliga avskrivningen. Ex:\n\nTillgångens värde: 100 000 Kr\nTid för avskrivning: 6 månader innan räkenskapsårets slut\nLivstid: 5 år (60 månader)\nÅrlig avskrivning: 6/60 * 100 000 = 10 000 kr, 12/60 * 100 000 = 20 000 kr, 20 000 kr, 20 000 kr, 20 000 kr, 10 000 kr",
    Asset_MoreDetailsNeeded_FormDescription: "För att kunna registrera avskrivningar på tillgången behövs lite ytterligare information.",
    Asset_Name_Tooltip: "Ge din tillgång ett namn som beskriver den i detalj och gör det enkelt för dig att identifiera senare. Ex. Dator-Modell, Biltyp-ABC 123",
    Asset_Period_Tooltip: "Avskrivningen för din tillgång kommer att ske en gång om året, sista dagen på ditt räkenskapsår.",
    Asset_PurchaseAmount: "Inköpsvärde",
    Asset_PurchaseDate: "Inköpsdatum",
    Asset_RecordAsAsset_Label: "Bokför som tillgång",
    Asset_RemoveDepreciation: "Ta bort avskrivning",
    Asset_RemoveDepreciation_ModalText: "Det här kommer ta bort avskrivningen och annullera verifikatet. Är du säker på att du vill ta bort detta?",
    Asset_SaveDetails_Action: "Spara tillgång",
    Asset_SearchField_Placeholder: "Sök tillgångar",
    Asset_Status_Active: "Aktiva",
    Asset_Status_All: "Alla",
    Asset_Status_Archived: "Arkiverade",
    Asset_Unarchive_Button: "Återaktivera",
    Asset_UpToDateWithDepreciations: "Det finns inga avskrivningar att göra just nu. Vid slutet av nuvarande räkenskapsår kommer tillgången kunna avskrivas.",
    Asset_YouCanNowRegisterDepreciations: "Du kan nu registrera avskrivningar för denna tillgång. Se till att göra det innan du gör klart ditt bokslut.",
    Assets_AddFixedAsset: "Importera tillgångar",
    Assets_AddFixedAssetManually: "Importera tillgångar",
    Assets_ControlVerificationBalance_Error: "Vi kunde inte skapa tillgången eftersom beloppen som finns i bokföringen inte stämmer överens med det angivna inköpsvärde och summa avskrivet (konto {0} på {1} och konto {2} på {3})",
    Assets_SearchForJournalEntries_Action: "Sök verifikat",
    Assets_SearchJournalEntries_Description: "Sök verifikat som matchar ovan angivna information. Att koppla ett verifikat till detta inköp gör det enklare att följa upp dina tillgångar.",
    AssetTemplate_ThresholdInfo: "**Är det här en tillgång?**\nNär inköpsvärdet är över {0} (exklusive moms) och den förväntade livslängden är mer än tre år kan du i de flesta fall bokföra inköpet som en tillgång. [Lär dig mer om tillgångar.](https://www.bokio.se/hjalp/bokforing/bokforingshjalp/sa-bokfor-du-anlaggningstillgangar/)",
    Attest: "Attestera",
    Attest_Approve_Action: "Godkänn",
    Attest_DeleteReceipt_Action: "Ta bort kvitto",
    Attest_DeleteReceipt_Text: "Detta kan inte ångras.",
    Attest_DeleteReceipt_Title: "Vill du verkligen radera kvittot?",
    Attest_Deny_Action: "Neka",
    Attest_Deny_Text: "Om du nekar kvittot för att det var för dålig kvalitet kan du sedan be den anställda att ladda upp ett nytt kvitto.",
    Attest_Deny_Title: "Vill du verkligen neka kvittot?",
    AutmationRulePreview_MatchInfo_DidNotMatch: "Matchar ej",
    AutmationRulePreview_MatchInfo_MatchedRule: "Matchar",
    AutmationRulePreview_MatchInfo_MatchedRuleButOtherHadPriority: "Annan regel matchade först",
    AutomationRule_CreateBankRule: "Skapa bankregel",
    AutomationRule_Preview_Debug: "Välj en rad för att se varför regeln matchade eller inte",
    AutomationRule_RuleNameTooltipText: "Välj ett namn som hjälper dig komma ihåg vad regeln handlar om",
    AutomationRuleError_FeatureNotAvailable: "Bank regler är inte tillgängligt på din nuvarande plan",
    AutomationRuleError_InvalidConstraint: "Ogiltigt villkor definierat, kontrollera villkorens fält och typ",
    AutomationRuleError_InvalidEditOfEditedRule: "Regeln har ändrats sedan den visades här, ladda om listan av regler och försök igen",
    AutomationRuleError_InvalidRecipeAction: "Ogiltig mall vald för regeln. Var god välj en annan mall",
    AutomationRuleError_InvalidStatusChange: "Ogiltig status ändring för regeln. Var god ladda om listan av regler och försök igen",
    AutomationRuleError_MaxConstraintCountExceeded: "Regeln har fler villkor än maximalt tillåtet. Ta bort några villkor och försök igen",
    AutomationRuleError_MaxRuleCountExceeded: "Max antalet regler har redan definierats. För att lägga in en regel måste en annan regel först tas bort",
    AutomationRulePreview_ContainsType: "innehåller",
    AutomationRulePreview_EndsWithType: "slutar med",
    AutomationRulePreview_EqualToType: "är lika med",
    AutomationRulePreview_StartsWithType: "börjar med",
    AutomationRules: "Bankregler",
    AutomationRules_ActionHeading: "2. Åtgärd",
    AutomationRules_AddConstraint: "Lägg till ytterligare villkor",
    AutomationRules_AddConstraintGroup: "Lägg till villkorsgrupp",
    AutomationRules_AddConstraintToGroup: "Lägg till villkor till gruppen",
    AutomationRules_BankTransactionsTemplateSelectionBasedOnRule: "Föreslår mall(ar) baserat på regel:",
    AutomationRules_ChangePriority: "Ändra prioritet",
    AutomationRules_ConstraintConnector_AND: "Och",
    AutomationRules_ConstraintConnector_AND_Label: "Alla villkor matchar",
    AutomationRules_ConstraintConnector_OR: "Eller",
    AutomationRules_ConstraintConnector_OR_Label: "Något villkor matchar",
    AutomationRules_ConstraintField_AccountNumber: "Betalkonto",
    AutomationRules_ConstraintField_Amount: "Belopp",
    AutomationRules_ConstraintField_Message: "Meddelande",
    AutomationRules_ConstraintHeading: "1. Villkor",
    AutomationRules_ConstraintType_Contains: "innehåller",
    AutomationRules_ConstraintType_EndWith: "slutar med",
    AutomationRules_ConstraintType_EqualTo: "lika med",
    AutomationRules_ConstraintType_GreaterThan: "mer än",
    AutomationRules_ConstraintType_LessThan: "mindre än",
    AutomationRules_ConstraintType_NoConstraint: "Välj typ av villkor",
    AutomationRules_ConstraintType_StartsWith: "börjar med",
    AutomationRules_CreateNewRule: "Skapa ny regel",
    AutomationRules_DeleteConfirmation: "Bankregeln kommer tas bort.",
    AutomationRules_DoesNotMatch: "Matchar inte",
    AutomationRules_EditModalTitle: "Bankregler",
    AutomationRules_Explanation: "Om du har många liknande eller återkommande transaktioner så kan du skapa en regel för vilken bokföringsmall som ska användas. På så sätt så slipper du söka efter rätt mall varje gång och kan bokföra många transaktioner på samma gång.",
    AutomationRules_FromTaxAccount: "Denna regeln har skapats från Skattekontosynken och kan inte ändras",
    AutomationRules_Matches: "Matchar",
    AutomationRules_MissingPermissionToSave: "Du saknar rät behörighet för att spara bankregler",
    AutomationRules_NoPreviewMessage: "Fyll i alla obligatoriska fält för att förhandsgranska matchningar",
    AutomationRules_OperatorLabelGroup: "Gruppen matchar om",
    AutomationRules_PreviewLabel: "Förhandsgranskning",
    AutomationRules_RootOperatorLabel: "Regeln matchar om",
    AutomationRules_RuleDirection_Cost: "Kostnad",
    AutomationRules_RuleDirection_Income: "Inkomst",
    AutomationRules_RuleDirectionTitle: "Visa mallar för",
    AutomationRules_RuleMatch_DoesntMatch: "Regeln matchade inte raden",
    AutomationRules_RuleMatch_Matches: "Regeln matchade raden",
    AutomationRules_RuleMatch_WrongDirection: "Den valda mallen har fel riktning för denna raden",
    AutomationRules_RuleNameLabel: "Regelns namn",
    AutomationRulesList_EmptyState_LearnMore: "Läs mer om hur du skapar bankregler",
    AutomationRulesList_EmptyState_Message: "Spara tid när du bokför genom att skapa regler för dina återkommande banktransaktioner.",
    AutomationRulesList_EmptyState_Title: "Inga bankregler att visa",
    AutoVerification_InputAmounts_FormLabel: "Hur ska summan fördelas?",
    AutoVerificationRow_InputRowCount_FormLabel: "Hur många rader ska orginalet delas upp i? (Max: {0})",
    AutoVerificationRow_Split: "Dela upp bankrad",
    AutoVerificationRow_UndoSplit: "Ångra dela upp transaktion",
    AutoVerificationRow_UndoSplit_Description_Invalid: "Transaktionen är bokförd, Bokföringen måste tas bort eller annulleras innan du kan fortsätta.",
    AutoVerificationRow_UndoSplit_Description_Valid: "Transaktionerna ovan kommer att tas bort och ersättas med följande transaktion:",
    AutoVerificationRow_UndoSplit_DetachReceipt_Warning: "När du ångrar din uppdelning av transaktionen kommer {0} kvitton kopplas från transaktionen. Du hittar dessa kvitton under Uppladdat.",
    Balance: "Saldo",
    BalanceReport: "Balansrapport",
    BalanceReport_NoRecordedTransactions_message: "Konto {0} saknar transaktioner i den valda perioden",
    Bank_ConfirmDeleteImport_CTA: "Ta bort import",
    Bank_ConfirmDeleteImport_Prompt: "Vill du verkligen ta bort importen?",
    Bank_ConfirmDeleteImport_Title: "Ta bort import",
    Bank_CountTransactions: "{x} transaktioner",
    Bank_Date: "Datum",
    Bank_DisconnectModal_Action: "Koppla bort",
    Bank_EditPreviousImports_CTA: "Hantera importer",
    Bank_ImportedOn: "Importerade {date}",
    Bank_ImportFromBank: "Importera från bank",
    Bank_ImportManually_CTA: "Importera från bank",
    Bank_ImportsEmpty: "När du gjort din första bankimport kommer den att dyka upp här.",
    Bank_LastImportedRow: "Senaste radens datum",
    Bank_Sum: "Summa",
    Bank_Title: "Meddelande",
    BankAccount_Action_Sync: "Synka",
    BankAccount_FetchingTransactions: "Hämtar dina transaktioner",
    BankAccount_WhenProblemsThenImportManually: "Om du upplever problem med den automatiska synkningen kan du {importera transaktioner manuellt}",
    BankDemo_Header: "Exempel",
    BankDemo_Heading: "Demobanken AB",
    BankDemo_Menu_AccountsAndCards: "Konton och kort",
    BankDemo_Menu_Funds: "Fonder",
    BankDemo_Menu_Loans: "Lån",
    BankDemo_Menu_Start: "Start",
    BankDemo_Step1_Instruction: "Börja med att klicka någonstans i tabellen",
    BankDemo_Step2_Instruction: "Tryck nu",
    BankDemo_Step3_Instruction: "När all text är markerad, tryck",
    BankDemo_Step4_Instructions_Text: "När du har gjort detta i din bank går du tillbaka till Bokio och klistrar in texten med",
    BankFeed_ConnectBank_CTA: "Koppla till bank",
    BankFeed_ConnectionsNotConnected: "Du har en bankkoppling eller ett företagskonto som inte har anslutits till ett bokförings-/betalkonto än. {Välj bokföringskonto nu}",
    BankFeed_ConnectToBank_CTA: "Koppla till bank",
    BankFeed_ConnectToBank_PoweredBy: "Powered by",
    BankFeed_ConnectToBank_Preamble: "Med automatisk bankkoppling synkas dina transaktioner från din externa bank direkt till Bokio. Hantera dina bankrader enkelt från Att göra eller Transaktioner att bokföra. Spara tid och minska risken för fel genom att slippa manuell inmatning.",
    BankFeed_ConnectToBank_Recommended: "Rekommenderat",
    BankFeed_ConnectToBank_Steps_1: "Börja med att välja bank och bekräfta genom autentisering.",
    BankFeed_ConnectToBank_Steps_2: "Välj de bankkonton du vill koppla till Bokio.",
    BankFeed_ConnectToBank_Steps_3: "Ställ in det bokförings-/betalkonto varje bankkonto ska kopplas till och från vilket datum du vill hämta dina transaktioner.",
    BankFeed_ConnectToBank_Title: "Koppla din bank till Bokio",
    BankFeed_DisconnectBankModal_Description: "Vad vill du göra med transaktionerna från den här banken?",
    BankFeed_DisconnectBankModal_KeepUnrecordedTransactions: "Behåll transaktioner",
    BankFeed_DisconnectBankModal_RemoveUnrecordedTransactions: "Ta bort transaktioner",
    BankFeed_DisconnectBankModal_Title: "Koppla bort {0}",
    BankFeed_FetchingTransactions_Information: "Vi hämtar dina transaktioner i bakgrunden och kommer notifiera dig när det är klart.",
    BankFeed_Manage_AccountLabel: "Bokförings-/betalkonto",
    BankFeed_Manage_AccountPlaceholder: "Välj bokförings-/betalkonto",
    BankFeed_Manage_AddAccountButton: "Lägg till betalkonto",
    BankFeed_Manage_AddBank: "Lägg till bank",
    BankFeed_Manage_BankAccountNumber: "Bankkontonummer",
    BankFeed_Manage_ConnectToPaymentAccount: "Välj bokföringskonto för dina företagskonton",
    BankFeed_Manage_ConnectToPaymentAccountDescription: "Alla dina framtida transaktioner kommer automatiskt att kopplas till det anslutna bokförings-/betalkontot.",
    BankFeed_Manage_ConnectToPaymentAccountHelpText: "Så här väljer du bokföringskonto",
    BankFeed_Manage_DisconnectBank: "Koppla bort bank",
    BankFeed_Manage_GetStatementsFrom: "Hämta transaktioner från och med",
    BankFeed_Manage_InvalidCombination: "Både betalkonto och hämta transaktioner från och med datum måste vara satt",
    BankFeed_Manage_LinkToDomesticPaymentMethodOnCreatedInvoicePrompt_Title: "Vill du använda ditt Bokio Företagskonto på dina kundfakturor?",
    BankFeed_Manage_LinkToDomesticPaymentMethodOnCreatedInvoiceToggle_Description: "Fakturor som du skickar från Bokio kommer framöver att få {0} som betalningsalternativ och därmed kunna bli automatiskt avprickade. Även OCR-nummer kommer läggas till på fakturorna. Detta kan ändras senare under fakturainställningarna.",
    BankFeed_Manage_LinkToDomesticPaymentMethodOnCreatedInvoiceToggle_Title: "Vill du använda ditt nya {0} på dina kundfakturor?",
    BankFeed_Manage_NavigationText: "Hantera",
    BankFeed_Manage_NavigationText_Extended: "Hantera kopplade banker",
    BankFeed_Manage_Reconnected: "Återanslöt bank {0}",
    BankFeed_Manage_SameBookkeepingAccountWarning: "Det går inte att länka flera konton till ett betalkonto. Ta bort en eller välj ett annat konto.",
    BankFeed_Manage_Title: "Hantera företagskonton",
    BankFeed_ManageConfirm_Text: "Vi kommer importera transaktioner från det datum du angett och till det betalkonto du valt. Vill du ändra datumet efter detta är bekräftat, koppla då bort banken och koppla den sedan igen.",
    BankFeed_ManageConfirm_Title: "Bekräfta import",
    BankFeed_SyncMessage: "Dina banktransaktioner har hämtats och är redo att bokföras. {Öppna i Att göra}",
    BankImport_Action_DisconnectJournalEntry: "Koppla bort från verifikat",
    BankImport_Status_NewItem: "Ny import",
    BankImport_Status_Processing: "Bearbetas av bank",
    BankImport_Status_Removed: "Borttagen manuellt",
    BankImport_StatusLink_UndoRemove: "ångra",
    BankImport_Step1: "Import",
    BankImport_Step2: "Importalternativ",
    BankImport_Step3: "Bekräftelse",
    BankImportUpload_Body: "Vi kunde inte läsa innehållet i det du klistrade in.\n\nDu kan hjälpa oss genom att skicka detta till oss så att vi kan förbättra funktionen.",
    BankImportUpload_DoNotSend: "Skicka ingen data",
    BankImportUpload_DontAskAgain: "Kom ihåg mitt val",
    BankImportUpload_DoSend: "Skicka data",
    BankImportUpload_Title: "Hoppsan!",
    BankParser_AddedRows_Message: "De sparade raderna kan nu bokföras från Att göra",
    BankParser_AmountPaid: "Betalat",
    BankParser_AmountReceived: "Mottaget",
    BankParser_AutoDetect: "Låt Bokio gissa",
    BankParser_BankName: "Bankens namn",
    BankParser_Duplicate_ColHeading: "Finns redan",
    BankParser_Duplicate_Deleted: "Raderad",
    BankParser_Duplicate_InQueueOrBookkept: "Bokförd eller i kö",
    BankParser_Duplicate_Tooltip: "Denna rad har redan importerats",
    BankParser_Error_FoundNoTableInPaste: "Vi lyckades inte hitta en tabell i texten",
    BankParser_Error_MustBeOneToOneMapping_Date: "Exakt en kolumn ska anges som Datum.",
    BankParser_Error_MustBeOneToOneMapping_Message: "Exakt en kolumn ska anges som Meddelande.",
    BankParser_Error_MustBeOneToOneMapping_Sum: "Exakt en kolumn ska anges som Belopp.",
    BankParser_Error_RowsOfDifferentLength: "Vi hittade en tabell men raderna var av ojämn längd",
    BankParser_FailedToParseExcel: "Misslyckades med att läsa Excel-filen",
    BankParser_FromWhichBank: "Vilken bank importerar du ifrån?",
    BankParser_Heading: "Läs in från bank",
    BankParser_ImportXRowsWhereofYDuplicates: "Lägg till {0} rader, varav {1} redan har importerats",
    BankParser_Include_ColHeading: "Lägg till",
    BankParser_NextStep: "Nästa",
    BankParser_NoChangeColumns: "Nej, ändra kolumner",
    BankParser_OpenTodo: "Öppna Att göra",
    BankParser_OtherBank: "Annan bank",
    BankParser_PasteBankStatementInfo: "Kopiera och klistra in ett urklipp av ditt kontoutdrag från din internetbank. Är du osäker på hur du gör urklippet? {Titta på ett exempel}",
    BankParser_PleaseFixMappingErrors: "Var snäll och fixa dessa {0} problem med mappningarna",
    BankParser_ReportProblem: "Rapportera ett fel",
    BankParser_ReportProblem_Comment: "Kommentar (frivillig)",
    BankParser_UseNewColumns: "Använd nya kolumner",
    BankParser_ViewAccount: "Visa konto",
    BankParser_XRowsImported: "{0} rader har lagts till",
    BankParser_YesImport: "Ja, importera",
    BankReconciliation_AmountLeftToRecord: "Belopp kvar att bokföra",
    BankReconciliation_AmountLeftToRecord_Tooltip: "Detta är det totala beloppet för de transaktioner som importerats från din bank som ännu inte har bokförts.",
    BankReconciliation_BalanceInBank: "Balans på bank",
    BankReconciliation_BalanceInBank_Tooltip: "Den här är balansen som hämtades från ditt bankkonto på senaste uppdaterade datumet ovan. Den kommer att stämma med ditt kontoutdrag.",
    BankReconciliation_BalanceInBokio: "Kontobalans på Bokio",
    BankReconciliation_BalanceInBokio_Tooltip: "Denna är balansen i Bokio från de bokförda transaktionerna på detta konto. Den kommer att vara samma balans som visas i Huvudbok och Balansrapporten på dagens datum.",
    BankReconciliation_BankStatement: "Kontoutdrag",
    BankReconciliation_ConnectAccount_Header: "Det här betalkontot är inte kopplat till banken!",
    BankReconciliation_ConnectedToBank: "Anslutet till bank",
    BankReconciliation_ItemsToReconcile: "Verifikat att stämma av",
    BankReconciliation_ItemsToReconcile_Tooltip: "Detta är antalet verifikat som är bokförda på kontot {0} som ännu inte är kopplade till en importerad banktransaktion.",
    BankReconciliation_NotConnected: "Ej anslutet",
    BankReconciliation_NotConnectedToBank: "Ej anslutet till bank",
    BankReconciliation_NotRecorded: "Inte bokförd",
    BankReconciliation_Reconcile: "Stäm av",
    BankReconciliation_Reconciled: "Avstämd",
    BankReconciliation_RunningBalance: "Saldo",
    BankReconciliation_ShowDeletedTransactions: "Visa borttagna transaktioner ({0})",
    BankReconciliation_State_BalacesAreCorrect: "Well done! You have nothing left to record and your balance in Bokio agrees with your balance in your bank!",
    BankReconciliation_State_BookkeepingAndBankNotConnected: "You have manually recorded journal entries to this account but have not connected your bank to Bokio. Do so below and then make sure to reconcile those journal entries with the imported transactions.",
    BankReconciliation_State_DoesNotBalance: "Your bank statement does not agrees with the balance in Bokio. Make sure you have added your opening balances and look for any missing transactions in your bank as well as any wrongly recorded bank transactions in the general ledger.",
    BankReconciliation_State_ItemsToReconcile: "You have recorded some journal entries to this account manually that are not yet linked to any imported bank transactions. You should reconcile them with the imported transactions from your bank.",
    BankReconciliation_State_NotSynced: "If you would like to reconcile it with your bank account, connect it first to your bank under Bank feeds.",
    BankReconciliation_State_OpeningBalanceUncertainForPeriod: "You need to close older fiscal years in order for the bank reconciliation to determine the correct balances for the bank accounts.",
    BankReconciliation_State_TransactionsToRecordAndItemsToReconcile: "You have imported bank transactions to be recorded and you have also manually recorded journal entries to this account that are not yet linked to any imported bank transactions. We suggest that you first record all transactions and then review the journal entries left to reconcile.",
    BankReconciliation_State_TransactionsToRecordWillAgree: "You have transactions to record, once these are recorded your balance in Bokio will agree to the balance in your bank.",
    BankReconciliation_State_TransactionsToRecordWontAgree: "You have transactions to record, once these are recorded your balance in Bokio will not agree to your bank balance. Look for any missing transactions in your bank statement or wrongly recorded ones in the general ledger.",
    BankReconciliation_ThingsToResolve: "Saker att lösa",
    BankTransactions_Confirmation_Description_MatchInvoice: "Din banktransaktion har matchats med en betalning av en faktura i Bokio. Du kan se detaljerna om verifikatet nedan.",
    BankTransactions_Confirmation_Description_MatchJournalEntry: "Verifikatet nedan var redan bokfört så vi har nu kopplat det till den valda banktransaktionen.",
    BankTransactions_Confirmation_Description_MatchSupplierInvoice: "Din banktransaktion har matchats med en betalning av en leverantörsfaktura i Bokio. Du kan se detaljerna om verifikatet nedan.",
    BankTransactions_Confirmation_Title_MatchInvoice: "Betalning matchad och registrerad",
    BankTransactions_Confirmation_Title_MatchJournalEntry: "Matchad med verifikat",
    BankTransactions_Empty_Import: "Du har inga banktransaktioner att bokföra just nu. För att bokföra dina banktransaktioner kan du importera dem",
    BankTransactions_Empty_Sync: "Du har inga banktransaktioner att bokföra just nu. För att bokföra dina banktransaktioner kan du ansluta din bank till Bokio",
    BankTransactions_EverythingRecorded: "Du har bokfört alla dina banktransaktioner och kommer att bli notifierad när det finns fler att bokföra.",
    BankTransactions_HowDoesBankImportWork: "Hur fungerar bankimport?",
    BankTransactions_HowToSetupBankFeeds: "Hur du kommer igång med banksynk",
    BankTransactions_ManualBulk: "Du kan inte bokföra mer än en transaktion åt gången om du bokför manuellt.",
    BankTransactions_MatchInvoice: "Matcha och registrera betalning",
    BankTransactions_MatchItems: "Matcha {0} transaktioner",
    BankTransactions_MatchJournalEntry: "Matcha med verifikat",
    BankTransactions_MatchSalaryPeriod: "Koppla och bokför skatter",
    BankTransactions_MatchVatPeriod: "Koppla och bokför skatter",
    BankTransactions_MultipleTemplatesNotSupported: "Flera mallar stöds inte när du väljer mer än en transaktion.",
    BankTransactions_MultipleTemplatesNotSupported_Description: "För närvarande kan du bara använda flera mallar när du bokför en transaktion i taget. Genom att välja mer än en transaktion kommer dina val att gå förlorade.",
    BankTransactions_TemplateHasNoBulkSupport: "Du kan för tillfället inte använda den här mallen för att bokföra mer än en banktransaktion.",
    Bookkeep: "Bokför",
    Bookkeep_AllTemplates_TabTitle: "Alla mallar",
    Bookkeep_Manual_TabTitle: "Manuell",
    Bookkeep_Search_TabTitle: "Sök",
    BookkeepCategory_NonMoney_Description: "Verifikat som inte involverar flytt av pengar",
    BookkeepCategory_NonMoney_Header: "Annat",
    BookkeepCategoryDesc_Income_text: "Ersättning som du erhåller efter att ha sålt en vara eller tjänst, ladda upp ett kvitto eller en kundfaktura.",
    BookkeepCategoryDesc_Other_text: "Ladda upp allt som inte hamnar under ovanstående kategorier, exempelvis betalningar till skattekontot, överföringar bankkonton, uttag, insättning mm.",
    BookkeepCategoryDesc_Receipt_text: "Kvitton och utlägg vid ett direkt inköp, dvs man betalar direkt på plats och erhåller ett kvitto.",
    BookkeepCategoryDesc_Supplier_text: "Inköpsfakturor som du erhåller efter att ha köpt en vara eller tjänst. Har oftast en kredittid om ca 1 månad innan fakturan ska betalas.",
    BookkeepCategoryStep_NextButton: "Nästa",
    BookkeepIncome: "Bokför inkomst",
    Bookkeeping_AddCategory_action: "Lägg till mall",
    Bookkeeping_Amount: "Belopp",
    Bookkeeping_CategoryStep_Billing: "Bokio tjänster",
    Bookkeeping_CategoryStep_Billing_Description: "Betalning av Bokios tjänster",
    Bookkeeping_CategoryStep_Income: "Inkomst",
    Bookkeeping_CategoryStep_Other: "Annat",
    Bookkeeping_CategoryStep_ReceiptOrExpense: "Kvitto eller utgift",
    Bookkeeping_CategoryStep_SaveAndDoLaterButton: "Spara och fortsätt senare",
    Bookkeeping_CategoryStep_SupplierInvoice: "Leverantörsfaktura",
    Bookkeeping_CategoryStep_WhatIsYourUploadedMaterial: "Vilken typ av händelse vill du bokföra?",
    Bookkeeping_CompanyInfo_NeedMoreInfo: "Vi behöver lite mer information om ditt företag för att kunna börja bokföra.",
    Bookkeeping_CompanyInfo_UpdateAndContinue: "Spara och fortsätt",
    Bookkeeping_CompanyInfo_WhatTypeOfCompany: "Vilken typ av bolag har du?",
    Bookkeeping_CompanyInfo_WhichMethod: "Vilken bokföringsmetod använder du?",
    Bookkeeping_Completed: "Klar",
    Bookkeeping_CreateSupplierInvoice_Header: "Skapa leverantörsfaktura",
    Bookkeeping_Due: "Förfaller",
    Bookkeeping_Issued: "Skapad",
    Bookkeeping_SumCategoryMissing: "Tyvärr kan denna bokföringsmall för närvarande inte användas tillsammans med bankimporter eller utlägg. För att bokföra detta kan du använda den manuella bokföringen.",
    Bookkeeping_SupplierInvoiceCreateAndExit: "Skapa enbart faktura",
    Bookkeeping_TotalAmount: "Total summa: {0}",
    Bookkeeping_TotalAmountMustAddUpTo: "Den sammanlagda summan måste bli: {0}",
    BookkeepingCashSupplierInvoice_BookkeepingPrepared: "Bokföring förberedd!",
    BookkeepingCompleted_FeedbackText: "Vi vill att din bokföring ska vara så enkel som möjligt. Om du har någon feedback så skulle vi vara tacksamma att få höra den. Kunde du hitta rätt mall, var det enkelt att göra manuell bokföring eller fanns det någonting som du vill att vi ska förbättra?",
    BookkeepingMenu_CategoryBookkeep: "Bokföring",
    BookkeepingMenu_CategoryCreate: "Skapa",
    BookkeepingMenu_CategoryImport: "Import",
    BookkeepingMenu_ImportFromBank: "Importera från bank",
    BookkeepingMenu_ImportFromFile: "Importera från fil",
    BookkeepingMenu_Invoice: "Faktura",
    BookkeepingMenu_Receipt: "Kvitto",
    BookkeepingMenu_Salary: "Lön",
    BookkeepingMenu_UploadMaterial: "Ladda upp underlag",
    BookkeepingMenu_WithoutReceipt: "Utan underlag",
    BookkeepingSchool_Instructions_Title: "Instruktioner",
    BookkeepingSchool_LearningOutcome: "I Bokföringsskolan lär du dig att bokföra uppladdade underlag som du finner i Saker att göra. Varje exempel är specifikt utvalt för att representera ett varierat utbud av underlag som gör dig redo för alla typer av bokföringar.",
    BookkeepingSchool_LookForGreen: "Leta efter gröna markeringar i appen för att navigera genom övningarna.",
    BookkeepingSchool_Start_DeclineButton: "Avböj erbjudandet",
    BookkeepingSchool_Start_Description: "Med Bokföringsskolan kommer du igång med grunderna i bokföring så att du tryggt och enkelt sedan skapa ditt riktiga bolag.",
    BookkeepingSchool_Start_Description_2: "Du kan alltid hitta guiden igen under vår hjälpmeny.",
    BookkeepingSchool_Start_StartButton: "Starta bokföringsskolan",
    BookkeepingSchool_Start_Title: "Välkommen till Bokio, {name}",
    BookkeepingSchool_Title: "Bokföringsskolan",
    BookkeepingSuccess_JournalEntryRecorded: "Verifikation bokförd!",
    BookkeepingSupplierInvoice_Ocr: "OCR nummer",
    BookkeepingSupplierInvoice_PaymentMessage_Tooltip: "Meddelandet till mottagaren som används för att identifiera källan för betalningen.",
    BookkeepingSupplierInvoice_PaymentReference: "Betalningsmeddelande",
    BookkeepingSupplierInvoiceSum: "Summa",
    BookkeepManual_PreviewAndBookkeep_Action: "Granska och bokför",
    BookkeepManually: "Bokför manuellt",
    BookkeepReceipt: "Bokför kvitto",
    BookkeepSupplierInvoice: "Bokför leverantörsfaktura",
    BookkeepSupplierInvoiceStep_CreateAndContinue_Action: "Skapa och fortsätt",
    BookkeepSupplierInvoiceStep_SaveAndExit_Action: "Spara och avsluta",
    Bookkept_CreatedBy: "Skapad av",
    Bookkept_SourceFeature: "med {0}",
    Brexit_BrexitChanges: "Brexit changes",
    Brexit_ChangesDescription: "We need to know which country in the United Kingdom your business is located in so we can make sure your bookkeeping is correct following Brexit.\n\nThere will be new rules for importing and exporting goods to and from EU countries for English, Scottish and Welsh businesses. Northern Irish businesses will continue to apply the rules of the EU when selling and purchasing goods from the EU.\n\nOnce you have answered this question, we will handle the bookkeeping automatically based on the date selected and whether it is before or after Brexit for both invoicing and bookkeeping.",
    Brexit_DueToBrexit_Description: "Due to Brexit, we need to know which country your company is located in. This will let us apply new rules so your accounting is correct.",
    Brexit_LearnMore: "Learn more",
    Brexit_SaveAndContinue_Action: "Spara och fortsätt",
    Brexit_SelectLocation_Header: "Select company location",
    Brexit_ThisInformationAlsoInCompanySettings: "This information can also be found in company settings",
    ChartOfAccounts_VatMapping_Header: "Momskoppling",
    CheckVerifications_Title: "Kontrollera verifikat",
    ChooseSupplier: "Välj leverantör",
    ChooseUploadedReceipt: "Välj bland uppladdade kvitton",
    ClosingBalance: "Utgående balans",
    Closure_NothingToRecord: "Du har ingen ingående balans att bokföra då förra årets resultat är noll.",
    ConfirmEditImportedNotice_Body: "Om du tar bort eller ändrar importerad bokföring kommer du inte lägre att kunna ångra importen. Om du vill ångra importen gå till {importerade verifikat}. Därefter importera din justerade fil på nytt.",
    ConfirmEditImportedNotice_Edit_Checkbox_Label: "Jag kommer endast ändra på detta verifikat",
    ConfirmEditImportedNotice_Remove_Checkbox_Label: "Jag kommer endast ta bort detta verifikat",
    ConfirmEditImportedNotice_Title: "Redigera/ta bort importerat verifikat",
    Continue_ButtonText: "Fortsätt",
    ConvertReceiptToExpense: "Flytta till utlägg",
    CopyVerification_CopyAction: "Kopiera",
    CopyVerification_ModalTitle: "Kopiera verifikat",
    CopyVerification_VerificationCopied: "Verifikat kopierat!",
    CreatedByXOnY: "Skapad av {0} den {1}",
    CreateYear: "Skapa år",
    Credit: "Kredit",
    CreditNoteTemplateNotAvailable: "Kreditfakturamallar är för närvarande inte tillgängliga. Vänligen bokför det manuellt.",
    CustomBookkeepingTemplates: "Egna bokföringsmallar",
    CustomTemplate_CreateNew_action: "Skapa ny mall",
    CustomTemplate_Rating_Description: "Har du några tankar som kan hjälpa oss att förbättra din upplevelse när du skapar egna bokföringsmallar?",
    CustomTemplate_Rating_Title: "Hur var din upplevelse av att skapa en egen bokföringsmall?",
    CustomTemplateCategory_Cost: "Kostnad",
    CustomTemplateCategory_Income: "Inkomst",
    CustomTemplateCategory_Transfer: "Överföring",
    CustomTemplates_AreaChoice_Domestic: "Inrikes",
    CustomTemplates_AreaChoice_OutsideEU: "Från icke EU-land",
    CustomTemplates_AreaChoice_WithinEU: "Från EU-land",
    CustomTemplates_CreateCustomTemplateModal_heading: "Skapa anpassad bokföringsmall",
    CustomTemplates_CreateNewTemplate_description: "Här kan du förhandsgranska ett exempel på hur verifikat kommer se ut när du använder mallen. Notera att dessa värden inte sparas utan istället fylls i när bokföringen görs.",
    CustomTemplates_DeleteTemplate_heading: "Ta bort anpassad bokföringsmall",
    CustomTemplates_DeleteTemplate_message: "Är du säker på att du vill ta bort denna bokföringsmall \"{0}\"?",
    CustomTemplates_ExampleAreaChoice_label: "Säljarens land",
    CustomTemplates_GetStarted_heading: "Kom igång med egna bokföringsmallar",
    CustomTemplates_GetStarted_message: "Anpassa Bokio efter dina behov och snabba upp repetitiva bokföringsuppgifter genom att skapa egna bokföringsmallar.",
    CustomTemplates_HelpArticleUrl: "https://www.bokio.se/hjalp/bokforing/bokforingsmallar/skapa-egna-bokforingsmallar/",
    CustomTemplates_PreviewJournalEntry_heading: "Exempelverifikat",
    CustomTemplates_PreviewVerification_Button: "Förhandsgranska",
    CustomTemplates_SaveAnyway: "Spara ändå",
    CustomTemplates_SaveTemplate: "Spara mall",
    CustomTemplates_ShareCustomTemplate_Checkbox: "Delad",
    CustomTemplates_ShareCustomTemplate_CopyMessage: "Den delbara länken till din anpassade bokföringsmall är nu kopierad till urklipp.",
    CustomTemplates_ShareCustomTemplate_Description: "Du kan dela den här anpassade bokföringsmallen med andra användare i Bokio genom att kryssa i kryssrutan och dela länken. Dessa användare kan sedan skapa en kopia av bokföringsmallen genom att klistra in den delade länken i webbläsaren. Om du inte vill att fler kopior skapas med länken kan du kryssa ur kryssrutan.",
    CustomTemplates_ShareCustomTemplate_ModalTitle: "Dela anpassad bokföringsmall",
    CustomTemplates_TabOption_Advanced: "Avancerat läge",
    CustomTemplates_TransactionDetails_EmptyState_Notice: "Du måste fylla i typ av mall innan du kan fylla i transaktionsdetaljer",
    CustomTemplates_TransactionDetails_label: "Transaktionsdetaljer",
    CustomTemplates_TransactionPlaceholder: "t.ex. Total * 0,8",
    CustomTemplates_TypeOfTemplate_label: "Typ av mall",
    CustomTemplates_UpdateCustomTemplateModal_Heading: "Uppdatera anpassad mall",
    CustomTemplates_VATRate_label: "Momssats",
    CustomTemplates_VATRate_tooltip: "Detta anger den momssats som används i mallen",
    CustomTemplates_VATUnitType_label: "Momstyp",
    CustomTemplates_VATUnitType_tooltip: "Detta anger den momstyp som kommer användas i mallen",
    CustomTemplates_VerificationAccount_tooltip: "Detta anger kontot kostnaden eller inkomsten kommer bokföras på",
    Dashboard_Account_Title: "Bokförda kontotillgångar",
    Dashboard_MissingRequirements_ConfigureNow: "Ställ in detta nu",
    Dashboard_MissingRequirements_Info: "Vi behöver lite mer information om ditt företag för att kunna visa översikten",
    Dashboard_Profit_Title: "Årets resultat",
    Dashboard_Taxes_Dividend: "Planera din utdelning",
    Dashboard_Taxes_Title: "Årets skatt för företaget",
    DateCreated: "Skapandedatum",
    DateCreatedNewestToOldest: "Skapandedatum (nyast till äldst)",
    DateCreatedOldestToNewest: "Skapandedatum (äldst till nyast)",
    Debit: "Debet",
    DeleteEntireReceipt: "Radera hela kvittot",
    DeleteMaterial: "Ta bort underlag",
    DeleteMaterials: "Ta bort underlag",
    DeleteThisPage: "Radera denna sida",
    DeleteVerificationReason_RecordedTwice: "Det har bokförts två gånger",
    DeleteVerificationReason_WrongBookkeeping: "Bokföringen är fel",
    DeleteVerificationReason_WrongReceipt: "Det bifogade kvittot är fel",
    DownloadPDF: "Ladda ned PDF",
    DraftCompleted_DraftCreated: "Bokföring schemalagd",
    DraftCompleted_Information: "Du kan hitta din schemalagda bokföring i Att göra-listan. När det är dags att bokföra finns allt förberett så du enbart behöver godkänna med ett klick.",
    DraftCompleted_WhenCanYouRecord: "När kan jag bokföra det?",
    DraftVerification_CreateDraft_Action: "Schemalägg",
    DraftVerification_DeleteDescription: "Är du säker på att du vill ta bort den schemalagda bokföringen?",
    DraftVerification_ItWillBeScheduled: "Bokföringen kommer att schemaläggas till det angivna datumet. När verifikatet schemalagts är det tillgängligt i {Att-Göra} listan.",
    DraftVerification_PreviewAndSchedule: "Granska och schemalägg",
    DraftVerification_PreviewDraft: "Förhandsgranska",
    DraftVerification_PublishDescription: "När du bokför detta utkast kommer följande verifikat bokföras",
    DraftVerification_UpcommingTodoModal_Description: "Du kan bokföra verifikatet på eller efter datumet nedan",
    DraftVerification_YouCanSchedule: "Du kan nu schemalägga verifikationer genom att välja ett framtida datum nedan",
    EditVerification_AlreadyChanged: "{0} har redan ändrats eller annulerats",
    EditVerification_ConfirmChanges: "Bekräfta ändringar",
    EditVerification_CorrectionType_Asset: "Visa tillgångar",
    EditVerification_CorrectionType_Closure: "Redigera bokslut",
    EditVerification_CorrectionType_Disbursement: "Hantera utläggsbetalning",
    EditVerification_CorrectionType_Expense: "Hantera utlägg",
    EditVerification_CorrectionType_Invoice: "Redigera faktura",
    EditVerification_CorrectionType_Salary: "Redigera lön",
    EditVerification_CorrectionType_SupplierInvoice: "Redigera leverantörsfaktura",
    EditVerification_CorrectionType_VatReport: "Redigera momsrapport",
    EditVerification_PageTitle: "Redigera verifikat",
    EditVerification_Preview_Label_Original: "{0} är den nuvarande verifikationen",
    EditVerification_Preview_Label_XWillBeTheNewVerification: "{0} kommer bli det nya verifikatet",
    EditVerification_Preview_Label_XWillCancelY: "{0} kommer ta bort effekten av {1}",
    EditVerification_RemoveVerification: "Ta bort verifikat",
    EditVerification_RemoveVerification_Permanently_Description: "Du kan permanent ta bort detta verifikat eftersom den är den senaste verifikatet i bolaget och det är skapat de senaste 30 dagarna.",
    EditVerification_RemoveVerification_Permanently_Label: "Ta bort helt ",
    EditVerification_RemoveVerification_Title: "Ta bort {0}",
    EditVerification_RemoveVerificationAnyway: "Ta bort verifikat ändå",
    Error_AccountBalanceLimitExceeded: "Det finns en gräns som överskridits för balansen på något konto som ingår i verifikatet",
    Error_AccountMustBeSelected: "Konto måste väljas",
    Error_AccountNotInChartOfAccounts: "Kontot ({0}) existerar inte i vår kontoplan.",
    Error_AccountNotValid: "Inte ett giltigt konto",
    Error_BookkeepingAmountsCannotBeNegative: "Kredit- och debetbelopp får inte vara negativa.",
    Error_DuplicateDetected: "En kopia av verifikatet var redan bokförd. Vill du spara ändå?",
    Error_EmptyVerification: "Verifikatet är tomt!",
    Error_EnterCorrectFormat: "Var god och ladda upp en fil med formatet .jpg, .jpeg eller .png istället",
    Error_FiscalYearDoesntExist: "Datumet ligger inte i något existerande räkenskapsår, skapa upp detta räkenskapsår under &lt;a href=\"/{0}/settings-r/fiscals\" target=\"_self\"&gt;Inställningar -&gt; Räkenskapsår&lt;/a&gt;",
    Error_FutureDate: "Datumet får inte vara i framtiden",
    Error_HasLaterConversionBalances: "",
    Error_InvalidAccount: "En eller fler rader hade ett ogiltigt konto",
    Error_InvalidAmount: "En eller flera rader hade ett ogiltigt belopp",
    Error_InvalidDateForConnectedEntity: "Datumet på verifikatet stämmer inte överens med datumet som krävs",
    Error_InvalidFile: "Ogiltigt filformat",
    Error_InvalidTransactionsForConnectedEntity: "Ogiltiga värden på obligatoriska konton",
    Error_IsAutoVerificationRowsPendingOrInconsistent: "Transaktionen/transaktionerna är fortfarande reserverade eller datumen för transaktionen/transaktionerna och verifikatet stämmer inte överens.",
    Error_NoFiscalYear: "Räkenskapsår saknas!",
    Error_PaymentBeforeInvoiceDate: "Förfallodatum är före fakturadatum",
    Error_ReachedMaximumVerificationLimitForTestCompanies: "Maxantalet verifikat för ett testföretag har nåtts",
    Error_ReceiptAlreadyBookkept: "Kvittot är redan bokfört!",
    Error_ReceiptDeleted: "Kvittot är raderat!",
    Error_RegisterReceiptAlreadyBookkept: "Kvittot är redan bokfört!",
    Error_SumDoesNotAddUp: "Totala debet och kredit måste vara lika stora",
    Error_UnableToRegisterPaymentOnJournalEntry: "Du kan inte registrera en betalning på denna verifikation",
    Error_VatPeriodClosed: "Momsperioden för datumet är stängd och verifikatet kan påverka momsen. Du bör öppna den aktuella momsperioden eller bokföra verifikatet i början av nästa öppna momsperiod.",
    Error_VatPeriodClosed_BankTransaction: "The VAT period for the date is reconciled but not closed. Make sure you are on the right period.",
    Error_VatPeriodClosed_SupplierInvoice: "Genom att ta bort denna leverantörsfaktura kommer du även ta bort verifikationen som är bokförd i en nu stängd momsperiod. Du bör öppna den aktuella momsperioden eller bokföra leverrantörsfakturan början av nästa öppna momsperiod.",
    Error_VatPeriodStarted: "Momsperioden för datumet är avstämd men inte stängd. Kontrollera att du är på rätt momsperiod.",
    Error_VerificationWasNotFinished: "Det måste finnas minst två rader där debet eller kredit är större än 0",
    Error_YearClosed: "Räkenskapsåret är stängt",
    Error_YearDoesntExist: "Datumet ligger inte i något existerande räkenskapsår, skapa upp detta räkenskapsår under &lt;a href=\"/{0}/settings-r/fiscals\" target=\"_self\"&gt;Inställningar -&gt; Räkenskapsår&lt;/a&gt;",
    ETR_AutomationRule_DontUseCTA: "Använd inte regel",
    ETR_AutomationRule_Heading: "Bokföringsregel",
    ETR_AutomationRule_UseCTA: "Bokför enligt regel",
    ETR_Completed_Heading: "Bokfört som",
    ETR_ItemAlreadyRecorded: "Redan bokfört",
    ETR_LockedVatPeriodWarning: "Momsperioden för datumet är låst. Du kan bara bokföra detta om det inte påverkar momsen. Annars behöver du låsa upp momsperioden först.",
    ETR_Matches_CIMatch_NoAutoRecordWarning: "Automatisk bokföring av denna fakturan är inte aktiverat. Du måste bokföra detta sedan.",
    ETR_Matches_CIMatch_SettleLabel: "Vad vill du göra med differensen?",
    ETR_Matches_DontMatch: "Matcha inte",
    ETR_Matches_Match: "Matcha",
    ETR_Matches_SalaryIncomeTaxMatch_Description: "Matcha med inkomstskatterna för månad {0}. Detta bokför skatterna och stänger månaden.",
    ETR_Matches_SalarySocialFeesMatch_Description: "Matcha med arbetsgivaravgifterna för månad {0}. Detta bokför skatterna och stänger månaden.",
    ETR_Matches_SIMatch_CurrencyWinAmount: "{0} valutavinst kommer bokföras",
    ETR_Matches_SIMatch_RecordAsCurrencyWin: "Bokför skillnaden som valutavinst/förlust",
    ETR_Matches_SIMatch_RemainingAmount: "Återstående summa komma vara {0}",
    ETR_Matches_SIMatch_WillClose: "Leverantörsfakturan stängs",
    ETR_Matches_SIMatch_WillNotClose: "Leverantörsfakturan kommer lämnas öppen",
    ETR_Matches_SIMatch_WillRegisterPayment: "En betalning kommer registreras ({0}/{1}) och bokföras",
    ETR_Matches_VatMatch_Description: "Matcha med momsrapporten {0}. Detta bokför skatterna och stänger momsperioden.",
    ETR_Matches_VerMatch_Description: "Du kommer koppla denna bankraden till {0}. Bankraden klarmarkeras utan att bokföra något nytt.",
    ETR_MatchingReceipts: "Matchar kvitton",
    ETR_NoBookkeepingAccount: "Bokföringskontot är inte inställt för detta bankkonto",
    ETR_NoMatchingReceipts: "Inga matchande underlag hittades",
    ETR_Title: "Händelser att bokföra",
    ETR_XMatchingReceipts: "{0} matchande underlag hittades",
    ETR_XMatchingReceipts_CTA: "Välj underlag",
    FaultyVerifications: "Felaktiga verifikat",
    FillInTheDetails: "Fyll i detaljer",
    FiscalYear: "Räkenskapsår",
    FiscalYear_CouldNotDelete_VatReportExists: "Räkenskapsåret kan inte tas bort då det finns kopplade momsrapporter.",
    FiscalYear_CouldNotDelete_VerificationExists: "Räkenskapsåret kan inte tas bort då det inte var det senaste, det finns verifikat eller inkommande balans.",
    FiscalYear_QuickCloseError_EndDateWithinTwoBankingDays: "Det går inte att stänga räkenskapsåret förrän två bankdagar efter ert räkenskapsårs slutdatum har passerat. Detta är för att försäkra oss om att alla bankrelaterade händelser bokförts.",
    FiscalYear_Status_CalculatedResultNotZero: "Beräknat resultat är inte noll",
    FiscalYear_Status_OutgoingIngoingBalanceDiff: "Utgående balans problem",
    FiscalYear_Status_ShouldBeClosed: "Bör stängas",
    FiscalYearListScene_ActionsDisabledWarning: "Du har inte rättigheter att ändra på något från denna sidan. Vänligen kontakta administratören för detta företag.",
    FiscalYearListScene_YearHasImbalancedOutoingBalances: "Dina tillgångar och skulder balanserar inte. Det beror troligtvis på att något av dina räkenskapsår har ett sparat \"Beräknat Resultat\" som inte är noll. Lär dig mer om {hur du åtgärdar problemet i vår guide}.",
    FiscalYearListScene_YearHasOutgoingIngoingBalanceDiff: "Din beräknade utgående balans stämmer inte överens med den sparade. Du åtgärdar detta enkelt genom att öppna och stänga det angivna räkenskapsåren.",
    FiscalYears: "Räkenskapsår",
    FiscalYears_SaveAndApprove_Button: "Spara och godkänn",
    FiscalYears_SaveAndApprove_Tooltip: "Detta räkenskapsår har genererats i samband med automatisk bokföring. Inställningarna utgår från föregående räkenskapsår. Vänligen se över detta och när alla inställningar är rätt, tryck på spara och godkänn.",
    FiscalYearsIssueNotice_ImbalancedOutgoingBalances: "Dina tillgångar och skulder balanserar inte. Det beror troligtvis på att något av dina räkenskapsår har ett sparat \"Beräknat Resultat\" som inte är noll. {Se över dina räkenskapsår} eller lär dig mer om {hur du åtgärdar problemet i vår guide}.",
    FiscalYearsIssueNotice_OutgoingIngoingBalanceDiff: "Din beräknade utgående balans stämmer inte överens med den sparade. Du åtgärdar detta enkelt genom att öppna och stänga {de tidigare räkenskapsåren}.",
    FiscalYearStatus_NeedsReview: "Behöver ses över",
    HintAboutFullImport: "Här importerar du löpande bokföring från ett annat system. Vill du importera tidigare bokföring när du byter till Bokio ska du istället gå till {0}.",
    Hmrc_AuthError_Connect: "Connect",
    Hmrc_AuthError_ConnectText: "You are not currently connected to HMRC. In order to retrieve or update the company's VAT information you will first have to connect to HMRC.",
    Hmrc_AuthError_ConnectTitle: "Connect to HMRC",
    Hmrc_AuthError_Reconnect: "Reconnect",
    Hmrc_AuthError_ReconnectText: "We are sorry but we were unable to access HMRC. You need to reconnect to HMRC to grant Bokio authority to access the company's VAT information.",
    Hmrc_AuthError_ReconnectTitle: "Reconnect to HMRC",
    Hmrc_BookkeepPayment_AlreadyBookkept: "I have already recorded this",
    Hmrc_BookkeepPayment_BookkeepAndComplete: "Record and complete",
    Hmrc_BookkeepPayment_BookkeptAs: "Recorded as",
    Hmrc_BookkeepPayment_Content: "When your payment is complete you have to record the amount in order to finish the VAT return process.",
    Hmrc_BookkeepPayment_Header: "Record VAT payment",
    Hmrc_Cancel: "Cancel",
    Hmrc_EmptyState_Text: "There are no VAT returns to show or the company haven’t connected to HMRC yet. You can update the VAT list at any time to get the latest updates.",
    Hmrc_EmptyState_UpdateList: "Update VAT list",
    Hmrc_ExternalSubmitContent: "We are unable to show additional information for this VAT report. This is likely due to it being recorded outside of Bokio. For more detailed information of this period please visit [HMRC](https://www.gov.uk/log-in-register-hmrc-online-services) or the tool you submitted it from.",
    Hmrc_ExternalSubmitHeader: "VAT return information",
    Hmrc_ExternalSubmitJournalEntryHeading: "VAT journal entry",
    Hmrc_ExternalSubmitManualJournalEntry: "VAT journal entry needs to be recorded manually for this report",
    Hmrc_ExternalSubmitManualJournalEntryRecorded: "VAT journal entry manually recorded",
    Hmrc_LastSynced: "Last synced with HMRC: {0}",
    Hmrc_List_DueDate: "Due date",
    Hmrc_List_SubmittedOn: "Submitted on",
    Hmrc_List_UpdateVatList: "Update VAT list",
    Hmrc_PayOrReclaim_Content: "Before you can record the VAT return you will either have to pay or reclaim money from HMRC.",
    Hmrc_PayOrReclaim_Header: "Pay or reclaim money",
    Hmrc_ReportSummary_AmountToGet: "Amount to receive",
    Hmrc_ReportSummary_AmountToPay: "Amount to pay",
    Hmrc_ReportSummary_PaymentDue: "Payment due date",
    Hmrc_ReportSummary_SubmitDue: "Submission due date",
    Hmrc_ReviewReport_BookkeptAs: "Momsperioden bokförd som",
    Hmrc_ReviewReport_Description: "Review your VAT return before submitting it to HMRC and make sure:\n  *   All your customer and supplier invoices have been recorded for the period\n  *   All other income and expenses have been recorded for the period\n  *   Your bank statements matches the balance in account 1200\n  *   All balance sheet accounts are correct\n  *   Run the VAT checker to ensure that everything is posted correctly. [Check report](bokio:{0})",
    Hmrc_ReviewReport_ReviewAndSubmit: "Review and Submit",
    Hmrc_ReviewReport_Title: "Review and submit VAT",
    Hmrc_ReviewReport_ZeroVatNotice: "Your VAT for this period is zero but you still have to submit your VAT return to HMRC.",
    Hmrc_Status: "Status",
    Hmrc_StatusLabel_Completed: "Completed",
    Hmrc_StatusLabel_Current: "Current",
    Hmrc_StatusLabel_Incomplete: "Incomplete",
    Hmrc_StatusLabel_Overdue: "Overdue",
    Hmrc_StatusLabel_Submitted: "Submitted",
    Hmrc_Submitted: "Submitted",
    Hmrc_SubmittedOn: "Submitted on {0}",
    Hmrc_SumbitReportModal_ConfirmAndSubmit: "Confirm and submit",
    Hmrc_SumbitReportModal_ConfirmText: "I confirm that the information I am submitting is true and complete",
    Hmrc_SumbitReportModal_Legal: "When you submit this VAT information you are making a legal declaration that the information is true and complete. A false declaration can result in prosecution.",
    Hmrc_SumbitReportModal_NoVatContent: "You are about to submit your VAT return to HMRC. Bokio will also close the period for you and you should not record anything else for this period.",
    Hmrc_SumbitReportModal_Title: "Are you sure you want to submit and lock the period?",
    Hmrc_SumbitReportModal_WithVatContent: "You are about to submit your VAT return to HMRC. Bokio will also close the period for you and you should not record anything else for this period. Your journal entry will look like this:",
    Hmrc_UnableToSubmit_PeriodOngoing: "You cannot submit your VAT return to HMRC until your VAT period has finished",
    Hmrc_UnlockConfirmation_WithoutVerification: "Are you sure you want to unlock this step? The VAT return will then be marked as incomplete.",
    Hmrc_UnlockConfirmation_WithVerification: "Are you sure you want to unlock this step? The connected journal entry will be deleted and the VAT return will be marked as incomplete.",
    Hmrc_UnlockStep: "Unlock step",
    Hmrc_VrnError_IncorrectText: "The VAT registration number you have entered is incorrect",
    Hmrc_VrnError_IncorrectTitle: "VAT registration number is incorrect",
    Hmrc_VrnError_MissingText: "You need to enter your VAT registration number to get information from HRMC",
    Hmrc_VrnError_MissingTitle: "VAT registration number is missing",
    Hmrc_VrnError_Submit: "Submit",
    Hmrc_ZeroVat_CompleteButton: "Complete VAT return",
    Hmrc_ZeroVat_Description: "Your VAT return for this period was zero. This means that you have no payment to record. Complete the VAT return to mark it as done.",
    ImportAccountReceivables: "Kundfordringar",
    ImportAsset_InfoBar: "Importera tillgångar som inte finns i Bokios anläggningsregister. Det kan vara tillgångar som du redan bokfört men som hanteras och spåras utanför Bokio.",
    ImportBookkeeping: "Importera bokföring",
    ImportedVerification_Notice_Body: "Om du lagt in en import som du vill justera kan du gå till {importerade verifikat}. Du kan sedan justera importfilen och importera på nytt.",
    ImportSi_Errors_Title: "Fel hittades vid validering av verifikaten",
    ImportSi_ErrorsFound: "Fel hittade i dina filer",
    ImportSi_Header: "Importera verifikat",
    ImportSi_ParseErrors_Title: "Fel hittades vid läsning av filerna",
    ImportSuppliers: "Importera leverantörer",
    ImportSuppliers_NumberOfImportedArticle: "{ok} av {total} leverantörer importerades",
    ImportSuppliers_NumberOfImportedArticle_Description: "Du kan hitta dessa i leverantörslistan",
    ImportValidationFatal_ImportYearIsBeforeClosedYears: "Det importerade räkenskapsåret som börjar {0} är före redan stängda år. Öppna de senare åren innan du importerar.",
    ImportValidationFatal_ImportYearMismatchesSetting: "överlappar det redan existerande räkenskapsåret",
    ImportValidationFatalFiscalYearClosedHelpDetailsText: "Några av de befintliga räkenskapsåren är stängda och denna import skulle kunna påverka deras balanser. Gå till inställningarna för räkenskapsåren som listas nedan och säkerställ att de är öppna.",
    ImportValidationFatalHelpDetailsText: "Denna import skulle skapa ett glapp mellan nedanstående räkenskapsår. Vanligtvis beror detta på att företaget ändrat till eller från ett brutet räkenskapsår. Om så är fallet kan du prova skapa eller ändra de räkenskapsår företaget haft under Inställningar så att de matchar de som importeras och sedan försöka importera igen.",
    ImportValidationFatalHelpText: "Dessa fel gör det omöjligt att importera din bokföring. Kontakta supporten så hjälper vi dig",
    ImportValidationFatalSettingMismatchHelpDetailsText: "Importerade år överlappar räkenskapsår redan skapade i Bokio. För att kunna importera filen måste start och slutdatum för räkenskapsåren stämma överrens. Datumen för ett existerande räkenskapsår kan justeras under Inställningar.",
    ImportValidationFatalTitle: "Allvarliga fel hittades",
    ImportValidationWarningHelpText: "Några av dessa fel kan göra att din bokföring blir felaktig. Var snäll och granska problemen. Behöver du hjälp så kontakta supporten.",
    ImportValidationWarningTitle: "Potentiella fel hittade",
    IncomingBalanceShort: "Ing. balans",
    IngoingPayments: "Ingående betalningar",
    Journal_Annulled: "Annullerad",
    Ledger: "Huvudbok",
    ManageBankRules: "Hantera bankregler",
    ManualTab_CustomTemplates_Notice_heading: "Bokför du ofta manuellt?",
    ManualTab_CustomTemplates_Notice_Message: "Då kan du istället skapa {en egen bokföringsmall} och förenkla bokföringen.",
    MapCustomAccounts: "Koppla konton",
    Matches: "Matchningar",
    MathematicalExpressionTip_Desc: "Ange bara din önskade beräkning så räknar vi ut svaret åt dig!",
    MathematicalExpressionTip_Example: "Till exempel, om du vill beräkna 25% moms på 100, kan du helt enkelt ange 0.25*100.",
    MathematicalExpressionTip_Heading: "Använd vår inbyggda miniräknare",
    MathematicalExpressionTip_Tip: "Tips",
    MergeReceipts: "Slå ihop kvitton",
    MissingVerifications: "Saknad bokföring",
    MoneyAccount_Account_Column: "Betalkonto",
    MoneyAccount_AddAndManagePaymentAccounts: "Lägg till och hantera betalkonton",
    MoneyAccount_Bookkeeping: "Bokföring",
    MoneyAccount_Cancel: "Avbryt",
    MoneyAccount_EditAccounts: "Redigera betalkonto",
    MoneyAccount_Invoices: "Fakturor",
    MoneyAccount_ManageAccountsDescription: "Ställ in de konton som du vill ha tillgängliga när du bokför. Du kan endast lägga till de konton som ingår i din kontoplan.",
    MoneyAccount_Name_Column: "Namn",
    MoneyAccount_NewAccount_Button: "Lägg till betalkonto",
    MoneyAccount_NewAccount_ModalTitle: "Lägg till betalkonto",
    MoneyAccount_PaymentAccounts: "Betalkonton",
    MoneyAccount_Preselect_ModalDescription: "Välj om du vill att det här kontot ska vara förvalt inom någon av nedanstående områden när du bokför. Du kan endast ha ett förvalt konto inom varje område.",
    MoneyAccount_Preselect_ModalHeader: "Förval",
    MoneyAccount_PreselectedIn_Column: "Förvalt inom",
    MoneyAccount_RemoveAccount: "Radera betalkonto",
    MoneyAccount_Salary: "Lön",
    MoneyAccount_Save: "Spara",
    MoneyAccount_YourPaymentAccounts: "Dina betalkonton",
    MoneyAccounts_AccountType: "Kontotyp",
    MoneyAccounts_CurrentBalance: "Nuvarande saldo",
    MoneyAccounts_PaymentAccount: "Betalkonto",
    MoneyGoingIn: "Ingående",
    MoneyGoingOut: "Utgående",
    MostUsedTemplates: "Dina vanligaste mallar",
    MultipleVerifications: "Flera verifikat",
    NewBalance_Heading: "Ny balans",
    Next: "Nästa",
    NMaterialsWillBeDeleted: "{0} underlag kommer tas bort",
    NoSupplierFound: "Ingen leverantör hittades",
    NrOfMaterialsSelected: "{0} underlag valda",
    OkConfirm_ButtonText: "Ok, bekräfta",
    Onboarding_ChangeBG_Step4: "Använd nytt bankgironummer",
    Onboarding_DontChangeBG_Step4: "Ändra inte",
    OpeningBalance: "Ingående balans",
    OpeningBalances: "Ingående balanser",
    Or: "Eller",
    OtherIncomeAnnually: "Inkomst från övrig tjänst ({units} per år)",
    OutgoingBalanceShort: "Utg. balans",
    OutgoingPayments: "Utgående betalningar",
    PaymentAccounts: "Betalkonton",
    PaymentDate: "Betaldatum",
    PaymentDateNewestToOldest: "Betaldatum (nyast till äldst)",
    PaymentDateOldestToNewest: "Betaldatum (äldst till nyast)",
    PaymentDirection: "Betalningsflöde",
    PlanYourSalary: "Planera din lön",
    PostReceiptUpload_InfoTooltip: "Om du har fått kvittot eller fakturan på papper så behöver du också spara originalet i 3 år.",
    PreBookkeep_ChooseCategory: "Välj kategori",
    Prediction_ShowMostUsed: "Visa dina vanligaste mallar istället för förslag",
    Prediction_Supplier_Income_Title: "Inkomst från {0} bokförs ofta som:",
    Prediction_Supplier_Purchase_Title: "Inköp från {0} bokförs ofta som:",
    Prediction_TextMatch_Title: "{0} bokförs ofta som:",
    PrepareBookkeeping_SupplierInvoice: "Förbered bokföring",
    PreviewAndEditTransaction: "Förhandsgranska och redigera verifikat",
    PreviewTransactions: "Förhandsgranska verifikat",
    PreviewTransactionsModa_RecordTransactions_Plural: "Bokför transaktioner",
    PreviewTransactionsModa_RecordTransactions_Singular: "Bokför transaktion",
    PreviousBalance_Heading: "Föregående balans",
    ProprietorshipSettings: "Övrig inkomst och skatt",
    RBMM_Balances_Empty: "Det fanns inga ingående balanser för denna rutan",
    RBMM_Balances_Heading: "Ingående balanser",
    RBMM_Heading: "Mappingar för rutan",
    RBMM_MagicMappingsInfo: "Denna ruta tillåter automatiska mappningar för vissa konton utöver de som nämns ovan. Det betyder att vi tittar på resten av verifikatet för att avgöra om det ska mappas i denna rutan eller i annan ruta.",
    RBMM_MagicMappingTooltip: "Denna automappades",
    RBMM_Mappings_Heading: "Mappade konton",
    RBMM_SumCellInfo: "Denna rutan är en summering av andra rutor. Formel: {0}",
    RBMM_Table_Account: "Konto",
    RBMM_Verification_Empty: "Det fanns inget innehåll i rutan för denna perioden",
    RBMM_Verification_Heading: "Verifikationer",
    Receipt: "Kvitto",
    Receipt_GoToReport_Salary: "Gå till arbetsgivardeklaration",
    Receipt_PreviewNotAvailable_Salary: "Löneunderlag kan tyvärr inte förhandsgranskas",
    Receipt_PreviewNotAvailable_SalaryTax: "Arbetsgivardeklarationer förhandsvisas inte.",
    Receipts: "Kvitton",
    ReceiptSupportTicketModal_Title_ExistingTicket: "Bokföringssupport ",
    ReceiptSupportTicketModal_Title_NewTicket: "Skicka till granskning",
    RecordNTransactions: "Bokför {0} transaktioner",
    RecordTransactions_AddReceipt_Label: "Lägg till kvitto",
    RecordTransactions_AllTemplates_Tab: "Alla mallar",
    RecordTransactions_Amount_Label: "Summa",
    RecordTransactions_BrowseMatch_Label: "Bläddra bland matchningar",
    RecordTransactions_BrowseMatch_Title: "Bläddra bland matchningar",
    RecordTransactions_Date_Label: "Datum",
    RecordTransactions_DeleteTransaction_Label: "Radera transaktion",
    RecordTransactions_DisableMatching_Label: "Ta bort matchning",
    RecordTransactions_EmptyState_Description: "För att bokföra en banktransaktion, välj en från listan till vänster.",
    RecordTransactions_Manual_Tab: "Manuell",
    RecordTransactions_MatchItem_CTA: "Matcha transaktion",
    RecordTransactions_MatchTransaction_Title: "Matcha transaktion",
    RecordTransactions_Message_Label: "Meddelande",
    RecordTransactions_MultipleTransactionsMatchedToOneVerification_Desc: "Verifikatet nedan var redan bokfört så vi har nu kopplat det till de valda banktransaktionerna.",
    RecordTransactions_NoTransactionSelected_Header: "Ingen transaktion vald",
    RecordTransactions_NrOfHits: "Visar {0} av {1} transaktioner",
    RecordTransactions_NrOfMatches_M: "{0} föreslagna matchningar för den valda transaktionen",
    RecordTransactions_NrOfMatches_S: "En föreslagen matchning för den valda transaktionen",
    RecordTransactions_PageTitle: "Transaktioner att bokföra",
    RecordTransactions_Record_CTA: "Bokför",
    RecordTransactions_RecordAndComplete_CTA: "Bokför transaktion",
    RecordTransactions_RecordAndCompleteAnyways_CTA: "Bokför ändå",
    RecordTransactions_SearchTemplates_Placeholder: "Sök mall",
    RecordTransactions_SearchTransactions_Placeholder: "Sök transaktion",
    RecordTransactions_SplitTransaction_Label: "Dela upp transaktion",
    RecordTransactions_Suggestions_Tab: "Förslag",
    RecordTransactions_TransactionDate_Label: "Transaktionsdatum",
    RecordTransactions_TransactionMatchedPlural_Header: "Matchade {0} transaktioner",
    RecordTransactions_TransactionMatchedPlural_Text: "Bra jobbat, fortsätt så. Du kan se detaljerna för dina matchade banktransaktioner nedan.",
    RecordTransactions_TransactionRecorded_Header: "Transaktion bokförd",
    RecordTransactions_TransactionRecorded_Text: "Bra jobbat, fortsätt så. Du kan se detaljerna för din bokförda banktransaktion nedan.",
    RecordTransactions_TransactionRecordedPlural_Header: "Transaktioner bokförda",
    RecordTransactions_TransactionRecordedPlural_Text: "Bra jobbat, fortsätt så. Du kan se detaljerna för dina bokförda banktransaktioner nedan.",
    RecordTransactions_UndoSplitTransaction_Label: "Ångra dela upp transaktion",
    RecordTransactions_WhatDidYouGetPaidFor_Title: "Vad fick du betalt för?",
    RecordTransactions_WhatDidYouPayFor_Title: "Vad betalade du för?",
    RecordWithoutMatch: "Bokför utan matchning",
    RegisterAndRecordPayment: "Registrera och bokför betalning",
    RegisterBookkeepAnother: "Bokför en till",
    RegisterBookkeepAnyway: "Bokför ändå",
    RegisterBookkeepAsInvoice: "Bokför som faktura",
    RegisterBookkeepingAccount: "Bokföringskonto",
    RegisterBookkeepNext: "Bokför nästa",
    RegisterEditVerification: "Redigera verifikat",
    RegisterNewRow: "Ny rad",
    RegisterReceipt_ConfirmSave_CashMethodWarning: "Bokföringen kommer sparas när du registrerar betalningen av fakturan.",
    RegisterSearchEmptyResult: "Sökning på {0} gav inga resultat. Sök igen eller bläddra bland",
    RemoveImport_ConfirmModal_Body_Balances: "Om detta räkenskapsår har låsts upp och det fanns ingående balanser i importen så har dessa balanser tagits bort när räkenskapsåret låstes upp. Se till att balanserna är korrekta innan du förtsätter att bokföra i detta räkenskapsår i Bokio.",
    RemoveImportedVerification_RemoveSe: " .se import",
    RemoveImportedVerification_RemoveSi: ".si import",
    ReportWarnings_OpeningBalanceUncertainForPeriod_Instructions: "Rapporten använder ingående balanser från ett räkenskapsår som är för långt tillbaka i tiden. För att säkerställa att de korrekta värdena visas var god stäng de räkenskapsår som indikeras i listan över räkenskapsår.",
    RescueMoneyAccount: "Vilket betalkonto gäller detta?",
    RescuePastePlaceholder: "Klistra in här",
    RescueStep2: "Har vi förstått din bank korrekt?",
    ResetAccountDefinition_AccountProperties: "Bokios kontoegenskaper",
    ResetAccountDefinition_BaseAccountDetails: "Kontoegenskaper från Bokios kontoplan",
    ResetAccountDefinition_Change: "Byt",
    ResetAccountDefinition_CustomAccountDetails: "Nuvarande kontoegenskaper",
    ResetAccountDefinition_Message: "Byter du till Bokios kontoplan för konto {0} kommer det namn och mappningar som bestämts av Bokio att används för kontot. Detta gäller både nya och existerande verifikat.",
    ResetAccountDefinition_ResetAccount: "Kontoegenskaper - {0}, {1}",
    ResetEquityInfo: "Detta verifikat är skapat för omföring/nollställning av eget kapital för enskild firma {Vad är det här?}",
    ResultCurrentYear: "Resultat",
    ResultReport: "Resultatrapport",
    Review: "Granska",
    SelectReceipt: "Välj uppladdat underlag",
    SelectTemplate: "Välj mall",
    ShowBankRules: "Visa bankregler",
    ShowRecordedMaterials: "Visa bokförda underlag",
    ShowVerification: "Visa verifikat",
    SourceFeature_Closure: "bokslutsfunktionen",
    SourceFeature_Salary: "lönefunktionen",
    SourceFeature_Vacation: "semesterfunktionen",
    Supplier: "Leverantör",
    Supplier_AddInvoicePayment_Action: "Lägg till betalning",
    Supplier_PaymentCreator_MarkAsFullyPaid_CashMethod: "Markera fakturan som fullt betald",
    Supplier_PaymentCreator_MarkAsFullyPaid_InvoiceMethod: "Markera fakturan som betald och bokför skillnaden som valutaförlust/vinst",
    Supplier_PaymentCreator_PaymentType: "Betalningstyp",
    SupplierDeleteInvoice_CreateNewFromReceipt_label: "Skapa ny leverantörsfaktura baserad på underlaget",
    SupplierDeleteInvoiceConfirmation: "Vill du radera leverantörsfakturan? Även tillhörande bokföring kommer raderas.",
    SupplierDeleteMessage: "Är du säker på att du vill ta bort leverantören?",
    SupplierDeleteSupplierInvoice: "Radera leverantörsfakturan",
    SupplierDeleteTitle: "Ta bort leverantör",
    SupplierInvoice: "Leverantörsfaktura",
    SupplierInvoice_NeedPaymentInformation: "För att lägga till en betalning för leverantörsfakturan behöver du först lägga till betalningsinformation för leverantören",
    SupplierInvoice_VerificationDescription_PreviewCashMethod: "Detta förbereder enbart bokföringen för denna faktura. Ett verifikat kommer skapas först när du registrerar en betalning. Det verifikatet kommer se ut så här om du använder {0} som betalkonto.",
    SupplierInvoiceCurrencyInput_Description: "Valutan är kopplad till din leverantör och beloppet konverteras till {0} i de kommande stegen.",
    SupplierInvoiceCurrencyInput_Header: "Ange fakturabelopp i {0}",
    SupplierInvoiceDate: "Fakturadatum",
    SupplierInvoiceDueDate: "Förfallodatum",
    SupplierInvoiceHereYouCanEnterManual: "Här kan du bokföra manuellt och du kan läsa mer om hur debet och kredit fungerar {här}.",
    SupplierInvoiceHereYouCanEnterManual_Link: "https://www.bokio.se/hjalp/komma-igang/bokforing/vad-ar-debet-och-kredit/",
    SupplierInvoicePayment: "Betalning av leverantörsfaktura",
    SupplierInvoiceRemainingSum: "Återstående skuld",
    SupplierInvoices_ManagePayments: "Hantera betalning",
    SupplierInvoiceSum: "Summa",
    SupplierPayment_Payment_Label: "Betalning till leverantör",
    SupplierPayment_Repayment_Label: "Återbetalning från leverantör",
    SupportTicket_Comment_Label: "Ytterligare kommentar",
    SupportTicket_PrefilledComment_NoResponse: "Hej!\n\nDå vi inte har mottagit någon återkoppling från dig kommer vi att stänga ärendet. Om du önskar att vi öppnar det på nytt är det bara att återkoppla.\n\nÖnskar dig en fin dag!",
    SupportTicket_PrefilledComment_SupportQuestion: "Hej,\n\nTack för att du har skickat in en fråga till vår Bokföringssupport.\n\nDen här frågan lämpar sig dock bättre till vår vanliga support då detta handlar mer om hur du ska navigera och vad du ska göra i själva systemet - inte hur själva bokföringen ska konteras.\n\nDu når oss på support@bokio.se\n\nDu kan läsa mer om bokföringssupporten och vad den innebär [här](https://www.bokio.se/hjalp/bokio-medlemskap/tillaggstjanster/bokforingssupport/)\n\nÖnskar dig en fin dag!",
    SupportTicket_PrefilledComment_TooComplex: "Hej,\n\nTack för att du har skickat in ett ärende till vår bokföringssupport.\n\nVi har undersökt ert ärende och kommer dessvärre behöva neka denna förfrågan då vi behöver sätta oss in i din bokföring för att kunna svara, eller för att den är av en mer komplex karaktär. Mer om bokföringssupporten och hur den fungerar kan du läsa [här](https://www.bokio.se/hjalp/bokio-medlemskap/tillaggstjanster/bokforingssupport/)\n\nI dessa fall kan du få hjälp av våra samarbetspartners via **Tilläggstjänster &gt;Anlita en konsult** inne i Bokio istället.\n\nAllt gott!",
    SupportTicket_Response_Label: "Svar",
    SupportTicket_Status_CloseTicket: "Avsluta",
    SupportTicket_Status_Label_FoundSolution: "Har du hittat en lösning själv?",
    SupportTicket_Status_ReopenCheckbox: "Öppna ärendet",
    SupportTicket_Status_ReopenCheckbox_Label: "Behöver du mer hjälp med bokföringen av detta?",
    SupportTicket_StatusField_SelectLabel: "Status",
    SupportTicket_SubmitComment_Placeholder: "Har du ytterligare information om det som ska bokföras?",
    SupportTicket_SubmitComment_TooltipHelpText: "Var god ange så mycket information som möjligt om det som ska bokföras och inkludera även andledningen för det som bokföringen handlar om",
    SupportTicket_SubmitCommentButton: "Lägg till kommentar",
    SupportTicketButton_GetRecordingHelp_AskBokioToReview: "Begär bokföringssupport",
    SupportTicketButton_GetRecordingHelp_FollowYourTicket: "Följ ditt ärende",
    SupportTicketButton_GetRecordingHelp_Tooltip: "Om du har ett kvitto uppladdat kan du be Bokio om hjälp kring bokföringen",
    SupportTicketButton_GetRecordingHelp_UnsureHowToRecord: "Osäker på bokföringen?",
    SupportTicketInfo_Status_Closed: "Stängd",
    SupportTicketInfo_Status_InProgress: "Påbörjad",
    SupportTicketInfo_Status_Invalid: "Ogiltig",
    SupportTicketInfo_Status_MarkedForBilling: "Kommer faktureras",
    SupportTicketInfo_Status_Pending: "Ej påbörjad",
    SupportTicketInfo_Status_Resolved: "Färdig",
    SupportTicketResponse_SubmitComment_Placeholder: "Detta bör bokföras med mallen...",
    SupportTicketsCloseReason: "Anledning",
    SupportTicketsCloseReasonNoResponse: "Ingen återkoppling",
    SupportTicketsCloseReasonNotSet: "Ej vald",
    SupportTicketsCloseReasonSupportQuestion: "Support fråga",
    SupportTicketsCloseReasonTooComplex: "För avancerad",
    SupportTicketSubmissionForm_Comment_Placeholder: "Var god beskriv underlaget. Om du gjort ett inköp ange hur det kommer användas i din verksamhet.",
    SupportTicketSubmissionForm_Info: "Bokio kan hjälpa dig se över en faktura, ett kvitto eller andra dokument som du ska bokföra (eller som du redan bokfört). Du kommer få ett svar som beskriver vilken mall som ska användas eller hur du bokför det manuellt. {Läs mer om bokföringssupport här.}",
    SupportTicketSubmissionForm_Info_ContactSupport: "Var detta inte den typ av hjälp du letade efter? Vår vanliga support svarar på mer produktrelaterade frågor och tar emot buggrapporter, {skriv till dem här}.",
    SupportTicketSubmissionForm_Info_HireAnAccountant: "Eller behöver du hjälp med ett mer komplext bokföringsärende? {Ta hjälp av en redovisningskonsult}.",
    SupportTicketSubmissionForm_PurchaseInfo: "Beloppet kommer att debiteras från din befintliga betalningsmetod när granskningen är klar. Vi debiterar dig endast om vi kan hjälpa dig.",
    SupportTicketSubmissionForm_SubmitButton: "Skicka till granskning{0}",
    SupportTicketSubmissionForm_SubmitComment_TooltipHelpText: "Var god ange så mycket information som möjligt om det som ska bokföras och inkludera även andledningen för det som bokföringen handlar om",
    SupportTicketSubmissionForm_SuccessInfo: "Ett nytt bokföringssupport ärende har skapats. Du kommer få en notifikation när vi har svarat.",
    SupportTicketSubmissionForm_SuccessInfo_DoneButton: "Klar",
    SupportTicketSubmissionForm_SuccessInfo_Heading: "Bokföringssupport ärende skapat!",
    SupportTicketSubmissionForm_SuccessInfo_ShowTicketButton: "Visa ärende",
    TaxAccount_ErrorMessageWithReason: "Skatteverket svarade med felkoden: {0}",
    TaxAccount_GeneralErrorMessage: "Något gick fel vid synken. Var vänlig kontakta support.",
    TaxAccount_NoAccessMessage: "Du är inte behörig att läsa Skattekontot hos Skatteverket för detta företaget. Någon annan behöver synka kontot eller så behöver du få dessa rättigheter.",
    TaxDeadline: "Skatt senast",
    TaxDeadlineWithoutEUTrade: "Skatt senast utan EU-handel",
    Taxes_IncomeTax: "Inkomstskatt",
    Taxes_LocalTaxRate: "Skattesats",
    Taxes_SocialFees: "Social avgifter",
    Taxes_TaxProfitAdjustment: "Skattemässiga justeringar",
    Taxes_TaxReduction: "Jobbskatteavdrag",
    Taxes_YearProfit: "Årets vinst",
    TaxOnIncomeAsPercentage: "Inkomstskatt (%)",
    TaxPeriod_DeclareHere: "Öppna deklaration (Skatteverket)",
    ThereAreNErrors: "Det finns {n} fel",
    ThereAreNWarnings: "Det finns {n} varningar",
    ThereIsOneError: "Det finns ett fel",
    ThereIsOneWarning: "Det finns en varning",
    ThisIsHowYourVerificationWillLook: "Så här kommer ditt verifikat se ut:",
    Todo_ConfirmDelete_Title: "Vill du verkligen radera?",
    Todo_FirstCustomRating_Description: "Har du några tankar som kan hjälpa oss att förbättra din upplevelse när du skapar egna uppgifter?",
    Todo_FirstCustomRating_Title: "Hur var din upplevelse av att skapa egna uppgifter?",
    TransactionStepper_Header: "Lägg till ett kvitto per transaktion",
    TransactionStepper_Of: "av",
    TransactionStepper_transaction: "Transaktion",
    TransferEquity: "Nollställning av eget kapital",
    TTR_Salary_PeriodIsDone: "Denna löneperioden var redan klarmarkerad. Vi föreslår att du öppnar upp sista steget i löneflödet för perioden och sedan kommer tillbaks hit och kopplar raden.",
    TTR_Salary_PeriodIsNotMarkedAsReady: "Du behöver klarmarkera några steg för denna månaden innan du kan bokföra detta.",
    TTR_Salary_SumIsIncorrect: "Summan matchar inte vad Bokio hade beräknat för månaden. Om du manuellt ändrade något i AGI-filen eller laddade upp mer än en fil till skatteverket för en period, behöver du bokföra detta manuellt istället. Välj då Bokför utan matchning och välj mallen Avdragen skatt eller Arbetsgivaravgift. Gå sedan till löneflödet och markera sista steget som manuellt bokfört.",
    TTR_Vat_PeriodIsDone: "Denna momsperioden var redan klarmarkerad. Vi föreslår att du öppnar upp sista steget i momsflödet för perioden och sedan kommer tillbaks hit och kopplar raden.",
    TTR_Vat_PeriodIsNotMarkedAsReady: "Du behöver klarmarkera några steg för denna perioden innan du kan bokföra detta.",
    TTR_Vat_SumIsIncorrect: "Summan matchar inte vad Bokio hade beräknat för perioden.",
    Type: "Typ",
    Type_Cooperative: "Ekonomisk förening",
    Type_LimitedCompany: "Aktiebolag",
    Type_LimitedPartnership: "Kommanditbolag",
    Type_Partnership: "Handelsbolag",
    Type_Proprietorship: "Enskild firma",
    UpdatedAccountBalances_Heading: "Uppdaterade kontosaldon",
    Upload_ChooseAnUploadedReceipt_button: "Välj uppladdat kvitto",
    UploadAdditionalFiles: "Ladda upp övriga filer",
    UploadImage: "Ta/ladda upp ett foto",
    UploadItem: "Ladda upp underlag",
    UploadMaterials: "Ladda upp underlag",
    UploadNewReceipt: "Ladda upp nytt material",
    UploadSelectReceipt: "Ladda upp eller välj ett underlag",
    UploadSelectReceipt_Description: "till exempel ett kvitto eller en faktura",
    UploadsEmptyState_Content: "Ladda upp alla underlag här. Du kan också använda {kvittoinkorgen} eller vår specialanpassade {mobilapp}.",
    UploadsEmptyState_Title: "Det finns inga underlag att visa här",
    UploadsPage_ShowMaterials_label: "Visar {0} av {1} underlag",
    ValueRemaining: "{0} kvar",
    Vat_Debug_ControlCellError_Info: "Detta kan bero på att momskontot inte matchade försäljnings/kostnadskontot. Det kan också vara för fel summa har registrerats. Dubbelkolla bokföringen, om allt ser bra ut kan du ignorera denna varning.",
    Vat_Debug_Header: "Kontrollera momsrapport",
    Vat_Debug_HideDeletedAndVat: "Dölj annullerade verifikat",
    Vat_Debug_HideOk: "Dölj ok verifikat",
    Vat_Debug_List_Header: "Granska {0} av {1} verifikat",
    Vat_Debug_Status_BookkeeptAfterClose: "Saknas i rapport",
    Vat_Debug_Status_BookkeeptAfterClose_Description: "Detta verifikat bokfördes efter att rapporten slutfördes och innehåller transaktioner som borde vara i momsrapporten. Om detta inte var med mening bör du korrigera detta.",
    Vat_Debug_Status_FailedToRemap: "Kunde inte mappas",
    Vat_Debug_Status_FailedToRemap_Description: "Detta verifikat innehåller ett försäljningskonto som kan användas för både försäljning med och utan moms. Vi kunde inte automatiskt mappa detta till A05 eller E42. Du kan antingen ändra bokföringen eller så du kan manuellt lägga till detta belopp i A05 eller E42 när du lämnar in rapporten.",
    Vat_Debug_Status_PossibleError: "Möjligt fel",
    Vat_Debug_Status_Remapped: "Auto-mappad",
    Vat_Debug_Status_Remapped_Description: "Detta verifikat innehåller ett försäljningskonto som kan användas för både försäljning med och utan moms. Vi mappade automatiskt detta till A05 eller E42 baserat på vilka momskonton som användes. Vänligen kontrollera att vi valde rätt.",
    VAT_Debug_Tooltip: "Denna period har möjligen ett fel. Klicka på meddelandet för att gå till kontroll av momsraport.",
    VAT_DeclarationBlockTitle: "Deklarera moms till Skatteverket",
    VAT_DeclarationInfo: "Som momsregisterad behöver du deklarera moms för varje momsperiod. Även om perioden saknar händelser.",
    Vat_Details_UnconnectedAccounts_InfoBox: "Vi kollar automatisk på konton i grupperna 2, 3 och 4. Har du egna konton i andra kontogrupper som borde vara med i momsrapporten så behöver du mappa dem också.",
    Vat_Details_UnconnectedAccounts_ListTitle: "Följande omappade konton hittades",
    Vat_Details_UnconnectedAccounts_Title: "Konton som måste kopplas innan rapporten kan visas!",
    Vat_MapAccountsAction: "Koppla konton",
    VAT_MustCompletePreviousStep: "Föregående steg måste göras klart först",
    Vat_Report_QuickCloseNotice_Text: "Om du behöver rapportera denna perioden via Bokio kan du öppna den igen via ikonen med de tre prickarna",
    Vat_Report_QuickCloseNotice_Title: "Perioden är stängd och markerad som manuellt hanterad",
    Vat_Report_SaveBlocker_NotYetClosable: "Rapporten kan ännu inte sparas. Du måste vänta till periodens sista dag innan du ska spara rapporten.",
    Vat_Report_SaveBlocker_PeriodClosed: "Bokslutet är redan gjort för detta år. Året är stängt och momsrapporten kan då inte skapas. Om du sedan tidigare hanterat momsrapporten manuellt kan du stänga momsperioden via de tre prickarna uppe till höger.",
    Vat_UnlockReport_Action: "Lås upp rapport",
    VAT_VatInfo: "Du deklarerar enklast genom att ladda upp moms-filen vi har genererat för denna perioden till Skatteverket.",
    VatDeclaration_MarkAsDeclared_Action: "Markera som redovisad",
    VatDeclaration_MarkAsNotDeclared_Action: "Markera som ej redovisad",
    VatDeclaration_MarkAsPaid_Action: "Markera skattebetalning som gjord",
    VatFlow_FeedbackTitle: "Har du någon feedback på momsflödet?",
    VatListingError_FiscalYears: "Vi kan inte visa dina momsrapporter eftersom dina inställningar för räkenskapsår är felaktiga. Du behöver se över dessa inställningar under [Räkenskapsår](bokio:/companyid/settings-r/fiscals) och sedan återvända hit",
    VatListingError_Generic: "Något gick fel när vi skulle ladda dina momsrapporter - var vänlig kontakta Bokio support på [support@bokio.se](mailto:support@bokio.se)",
    VatPeriod: "Momsperiod",
    VATReport: "Momsrapport",
    VatReport_Check_Info: "Momskontrollen hjälper dig att hitta eventuella fel i din bokföring. Den kan dock ge några falska varningar. T.ex kommer den varna för felaktiga verifikat även om de var rättade. Den varnar dessutom för rättningen i det läget. Är momsrapporten sedan sparad varnar den falskt för det verifikat som genererades där.",
    VatReport_NotRegistered: "**Du har angivit att du inte momsregistrerad för det nuvarande räkenskapsåret**\nFör att läsa mer om moms och ansöka om momsregistrering, besök [verksamt.se](https://www.verksamt.se)\n\nDu kan se och ändra dina inställningar för moms i sektionen för [räkenskapsår](bokio:/companyid/settings-r/fiscals)",
    VatReport_PeriodSettings: "Inställningar för momsperioder",
    VatReport_TaxPayoutInfoText: "Du kommer få {0} momsåterbäring insatt på ditt skattekonto. Om det beloppet sedan betalas ut från Skattekontot till ditt företag så ska den utbetalningen bokföras.",
    VatReport_TaxPayoutInfoText_WithoutTaxAccount: "Du kan bokföra detta från Transaktioner att bokföra. Använd mallen 'Uttag från skattekonto'",
    VatReport_TaxPayoutInfoText_WithTaxAccount: "Eftersom du har skattekonto-synken så kommer detta bokföras enkelt från Transaktioner att bokföra",
    VATReports: "Momsrapporter",
    VatReportSE_AmountExplanation_ToolTip: "Summan baseras på skillnaden mellan ingående och utgående moms. Om du har mer ingående moms får du pengar tillbaka. Om du har mer utågende moms behöver du betala tillbaka. Beräkning:",
    VatReportSE_BalanceReport_Checklist: "Se till att du har bokfört momsen för föregående period.",
    VatReportSE_BookkeepAndLock_Action: "Bokför och lås",
    VatReportSE_Cancel_Action: "Avbryt",
    VatReportSE_CheckListDescription: "Din momsrapport fylls kontinuerligt i när du bokför på perioden. Det är därför viktigt att du stämmer av följande:",
    VatReportSE_CheckListHelpLink: "Läs mer om momsflödet.",
    VatReportSE_Close_Action: "Stäng",
    VatReportSE_ClosePeriod_Description: "Din momsperiod är nu stängd och du kommer behöva redovisa momsen till Skatteverket. Du kan se exakt vad du har kvar att göra under redovisa moms.",
    VatReportSE_CloseThePeriod_StepLabel: "Stäng perioden",
    VatReportSE_CompletePeriod_Action: "Slutför perioden",
    VatReportSE_ConfirmAndContinue_Action: "Bekräfta och fortsätt",
    VatReportSE_ConfirmAndUnlock_Action: "Bekräfta och lås upp",
    VatReportSE_CostAndIncomesBookkept_Checklist: "Alla utgifter och inkomster för perioden är bokförda.",
    VatReportSE_CurrentPeriod_Status: "Nuvarande period",
    VatReportSE_Declare_Deadline: "Deadline för momsdeklaration",
    VatReportSE_Declare_StepHeader: "Deklarera moms till Skatteverket",
    VatReportSE_DeclareVat_StepLabel: "Redovisa moms",
    VatReportSE_DownloadDisabled_NoSavedReport: "När du gjort klart steg 2 så kan du ladda ner och skicka in filen till Skatteverket",
    VatReportSE_DownloadFile_Action: "Ladda ner",
    VatReportSE_DownloadOrEnterVat_Info: "Här är momsfilen vi har genererat för dig. Nu kan du ladda upp den på {Skatteverket} när du deklarerar moms för den här perioden.",
    VatReportSE_DownloadReport_StepHeader: "Ladda ner momsrapport",
    VatReportSE_EventsAreMappedCorrectly_Checklist: "Se till att alla bokföringshändelser är kopplade till rätt ruta i momsrapporten. {Kontrollera rapporten här.}",
    VatReportSE_FullyDeleteReport: "Ta bort rapport helt",
    VatReportSE_GreatJob: "Utmärkt, detta är vad du har kvar att göra",
    VatReportSE_HowToSubmit: "När det blir dags att skicka in momsrapporten kan du använda filen vi tillhandahåller nedan. Du kan också manuellt skriva in summorna som visas i det föregående steget. Du kan alltid komma tillbaka hit senare och ladda ner filen.",
    VatReportSE_IncomingVatToDeduct: "Ingående moms att dra av",
    VatReportSE_InvoicesBookkept_Checklist: "Alla fakturor skapade i Bokio är bokförda.",
    VatReportSE_List_Description: "När en momsperiod är slut behöver du stänga den för att kunna redovisa momsen till Skatteverket.\nDu rapporterar moms {period} för ditt nuvarande räkenskapsår.\nOm detta inte stämmer med vad som är angivet på skatteverket bör du ändra periodinställning via {räkenskapsår}.",
    VatReportSE_List_Period_Monthly: "Varje månad",
    VatReportSE_List_Period_Quarterly: "Varje kvartal",
    VatReportSE_List_Period_Yearly: "Årligen",
    VatReportSE_LockAndContinue_Action: "Stäng perioden och fortsätt",
    VatReportSE_LockPeriod_Action: "Lås perioden",
    VatReportSE_LockPeriod_ModalDescription: "Du är på väg att stänga den här perioden, vilket innebär att du inte kommer kunna bokföra något mer med moms på perioden. Ditt verifikat kommer se ut så här:",
    VatReportSE_MarkAsComplete: "Markera perioden som stängd",
    VatReportSE_MarkAsComplete_Undo: "Markera perioden som öppen",
    VatReportSE_MarkAsComplete_WarningInfo: "Tänk på att när du stänger momsperioden bör du inte bokföra något mer på perioden som innehåller moms.\n\n  **När ska du markera perioden som stängd?**\n\n  - När du har importerat bokföring med färdiga momsperioder\n  - Färdigställt och bokfört momsrapporten manuellt",
    VatReportSE_Pay_StepHeader: "Betala moms",
    VatReportSE_Pay_StepHeader_GetPaid: "Hantera momsåterbäring",
    VatReportSE_PaymentAccountsMatchBank_Checklist: "Betalningskontona i bokföringen stämmer överens med bankens kontoutdrag vid varje datum.",
    VatReportSE_PeriodClosed_StepLabel: "Period stängd",
    VatReportSE_Periodisations_Checklist: "Alla obetalda fakturor vid årets slut är bokslutsjusterade, ifall du har kontantmetoden.",
    VatReportSE_Reconciliation_StepHeader: "Avstämning",
    VatReportSE_Record_StepHeader: "Bokför moms på skattekontot",
    VatReportSE_ReviewReturn: "Innan du fortsätter och stänger momsperioden se till att rapporten ser korrekt ut genom att granska den nedan",
    VatReportSE_SubmitAndComplete_StepHeader: "Granska rapport och stäng perioden",
    VatReportSE_ToDeclareVat_Action: "Till redovisa moms",
    VatReportSE_TotalOutgoingVAT: "Summa utgående moms",
    VatReportSE_UnlockConfirmation_Singular: "Är du säker på att du vill låsa upp det här steget? Genom att fortsätta kommer det här steget att låsas upp och verifikatet nedan kommer att tas bort.",
    VatReportSE_UnlockStep: "Lås upp steg",
    VatReportSE_VatFile: "Momsfil",
    VatReportSE_VatToDeduct: "Moms att dra av",
    VatReportSE_VatToPay: "Moms att betala",
    VatReportSE_VatToReceive: "Moms att få tillbaka",
    VatReportSE_ZeroReport_Info: "Din moms för den här perioden är noll. Du behöver fortfarande rapportera detta till Skatteverket.",
    VerificaitonCommentField_Label: "Kommentar",
    VerificaitonCommentField_Placeholder: "Lägg till en kommentar på verifikatet",
    Verification: "Verifikat",
    Verification_DeleteEntireReceipt: "Ta bort hela kvittot",
    Verification_DeleteEntireReceipt_Warning: "Är du säker på att du vill ta bort hela kvittot? Ladda ner en kopia om du vill använda kvittot i framtiden.",
    Verification_DeleteSinglePageFromReceipt: "Ta bort sida",
    Verification_DeleteSinglePageFromReceipt_ModalTitle: "Ta bort sida från kvittot",
    Verification_DeleteSinglePageFromReceipt_Warning: "Är du säker på att du vill ta bort denna sida från kvittot? Den valda sidan kommer tas bort, se till att du laddat ner en kopia om du behöver den i framtiden",
    Verification_DetachReceiptFromTransaction: "Koppla ifrån kvitto",
    Verification_DetachReceiptFromTransaction_Warning: "Är du säker på att du vill ta bort kopplingen mellan kvittot och transaktionen? Du kan fortfarande hitta kvittot i {0} efter att det kopplats ifrån.",
    Verification_Remove: "Ta bort",
    VerificationAccount: "Konto",
    VerificationCompleted_GreatJob: "Bra jobbat! Du kan se detaljer om din bokförda transaktion nedan.",
    VerificationDate: "Datum",
    VerificationDuplicate_Description: "Liknande transaktioner som den du bokför hittades. Se till att du inte bokför den två gånger.",
    VerificationDuplicate_ModalDescription: "Här är de liknande transaktionerna som den du försöker bokföra:",
    VerificationDuplicate_ModalTitle: "Granska transaktioner",
    VerificationDuplicate_Review_CTA: "Granska liknande transaktioner",
    VerificationEditor_Date_Label: "Datum",
    VerificationEditor_DifferenceInBalance: "Raderna balanserar ej",
    VerificationEditor_NoDifferenceInBalance: "Debet och kredit balanserar",
    VerificationEditor_ShowAccountBalance: "Visa kontosaldon",
    VerificationEditor_Title_Label: "Titel",
    WhatDidYouPayFor: "Vad betalade du för?",
    WhatDidYouSell: "Vad fick du betalt för?",
    WhichVatPeriodDoYouUse: "Hur ofta redovisar företaget moms?",
    WhyAreYouRemovingJournalEntry: "Vad är anledningen till att du tar bort verifikatet?",
    WithMatches: "Med matchningar",
    WithoutMatches: "Utan matchningar",
  };
}