/* eslint-disable */

import type { ClosuresLang } from "../types/ClosuresLang";
import { translateTempImpl } from "../TranslatableLanguage";

export function ClosuresLangDef(): ClosuresLang {
  return {
    translateTemp: (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB),
    
    AccountBalance_Description: "Du har säkerställt att saldot på ditt bankkontoutdrag överensstämmer med saldot på konto 1930 den sista dagen av ditt verksamhetsår (t.ex. 2017-12-31 där bokfört värde på konto 1930 matchar saldot på bankkontoutdraget för samma dag).",
    AccountTax_Description: "Du har säkerställt att saldot på skattekontot, vilket går att hitta på skatteverkets hemsida, matchar med saldot på konto 1630 sista dagen på verksamhetsåret.",
    AccrualAndPrepayment_Description: "Periodisering krävs när en faktura och prestationen kopplat till denna intäkt/kostnad sker under olika räkenskapsår. Exempelvis om du har sålt en tjänst eller vara under verksamhetsåret men fakturerar i kommande räkenskapsår. Ett annat exempel är om du mottagit en tjänst eller vara under räkenskapsåret men du mottog fakturan för denna tjänst eller vara i kommande verksamhetsår. För mindre enskilda belopp (under 5000kr) och återkommande fakturor (exempelvis försäkringar) finns det undantag för vad som behöver periodiseras, {läs mer om det här}.",
    AccrualAndPrepayment_headling: "Förutbetalda intäkter och upplupna kostnader",
    AnnualGeneralMeeting_Download_WithAuditor: "Årsstämma med revisor",
    AnnualGeneralMeeting_Download_WithoutAuditor: "Årsstämma utan revisor",
    AnnualGeneralMeetingDescription: "Ta del av guide och exempelprotokoll",
    AO_AccountCreatedViaEmail: "Ett konto kommer att skapas hos Årsredovisning Online. Om du redan har ett konto så kommer du bli tillfrågad att ge Bokio tillgång till det. Kontot skapas för följande e-post:",
    AO_BookkeepResult_description: "Det sista steget i bokslutet är att bokföra årets resultat och årets skatt från Årsredovisning Online i Bokio genom att trycka på ”bokför resultat”.",
    AO_BookkeepResult_heading: "Bokför årets resultat i Bokio",
    AO_Complete_description: "Du har nu ett konto på Årsredovisning Online och kan börja med att upprätta årsredovisningen. När du är klar med dina justeringar i årsredovisningen så kan du spara förändringarna och gå vidare till nästa steg genom att klicka på knappen ”Uppdatera”. Om du har gjort några ändringar i din bokföring efter att du påbörjat processen via Årsredovisning Online behöver du skicka över bokföringen på nytt.",
    AO_Complete_description_download: "När du är klar med årsredovisningen behöver du inte ladda ner en SIE-fil för att bokföra årets resultat, istället bokför du resultatet här i Bokios flöde med en enkel knapptryckning i nästa steg.",
    AO_Complete_heading: "Färdigställ Årsredovisning Online",
    AO_Connect_Heading: "Anslut till Årsredovisning Online",
    AO_ConnectAccount_Action: "Anslut",
    AO_GotoWebsite: "Gå till Årsredovisning Online",
    AO_Information: "Din bokföring för verksamhetsåret kommer att exporteras och överföras till Årsredovisning Online. Årsredovisning Online kommer att guida dig igenom bokslutsprocessen, upprättandet av årsredovisningen, inkomstdeklarationen och protokoll för årsstämman. Betalning sker först efter du färdigställt samtliga rapporter. Läs mer {om villkor}.",
    AO_Reconnect_Heading: "Uppdatera anslutning till Årsredovisning Online",
    AO_ReconnectAccount_Action: "Uppdatera anslutning",
    AO_ReconnectViaEmail_Message: "Anslutningen till ditt konto hos Årsredovisning Online har löpt ut. Vänligen uppdatera anslutningen för att fortsätta med årsredovisningen. Anslutningen sker för e-post:",
    AO_Status_NotYetStarted: "Ej påbörjat. Gå till Årsredovisning Online för att komma igång med årsredovisningen!",
    AO_YearEndResultAndTaxAreAlreadyRecorded: "Bokslutet är bokfört.",
    AOClosure_CreateAnnualReport: "Skapa din årsredovisning",
    AOClosure_Descriptions: "Du färdigställer din årsredovisning för räkenskapsåret hos Årsredovisning Online.",
    AOClosure_Notice_TestCompany: "Du behöver ha ett riktigt företag för att göra ditt bokslut med Årsredovisning Online",
    AOClosure_SupportIncluded: "Support från Årsredovisning Online ingår",
    AOClosure_ToDoAtAO: "Att göra hos Årsredovisning Online",
    AOClosure_ToDoInBokio: "Att göra hos Bokio",
    AOClosureModal_InsturctionText: "Vi ber dig att fylla i de kommande sidorna så noggrant som möjligt innan du laddar upp dina uppgifter till Årsredovisning Online via Bokio. Du kommer sedan att skapa din årsredovisning och skattedeklaration hos Årsredovisning Online som endast stödjer K2.",
    AOClosureModal_Payment: "Betalning",
    AOClosureModal_PaymentTerms: "Årsredovisning Online har en avgift på {0}. Du kan testa deras tjänst och du betalar endast om du väljer att skapa din årsredovisning och skattedeklaration. Betalningen kommer då ske via Årsredovisning Online.",
    AOClosureModal_Thanks: "Tack för att du väljer Årsredovisning Online",
    AreYouSure_UndoRecording: "Är du säker på att du vill ångra bokföringen?",
    AreYouUnsureHowToDoItYourself: "Är du osäker på hur du ska göra det själv?",
    Arsredovisning_Purchase_Clarify: "Du behöver fylla information innan vi skickar din bokföring till Årsredovisning Online där du sedan färdigställer ditt bokslut. Du kan prova tjänsten gratis innan du bestämmer dig för att använda dig av den. Denna tjänst gäller dock endast för K2.",
    ArsredovisningOnline: "Årsredovisning Online",
    ArsredovisningOnline_Description: "Vi skickar din bokföring till Årsredovisning Online (ÅO) där du kan färdigställa din Årsredovisning för räkenskapsåret. Du kan prova tjänsten gratis tills dess att du bestämmer dig för att använda den. Denna tjänst kan enbart användas för K2-bolag.",
    ArsredovisningOnline_ErrorFromAo_Label: "Felmeddelande från Årsredovisning Online:",
    BalanceReportExplainIncome: "Observera att du är ansvarig för att den bokföring som vi upprättar ditt bokslut på är korrekt. Vi uppmanar dig således att kontrollera samtliga konton innan du går vidare. Det finns möjlighet att du, efter det att du ha lagt din bokslutsbeställning, kan beställa hjälp av oss med detta.",
    BokioClosure: "Bokio Bokslut",
    BokioClosure_AlmostFinished_CheckList: "**Du är nästan klar**\nInnan vi kan påbörja ditt bokslut ber vi dig att slutföra detta och nästkommande steg. ",
    BokioClosure_AlmostFinished_Form: "**Du är nästan klar**\nInnan vi kan påbörja ditt bokslut ber vi dig att slutföra detta steg. ",
    BokioClosure_AlternateAgencies: "Visa alternativa redovisningsbyråer",
    BokioClosure_Buy_Action: "Bekräfta köp",
    BokioClosure_Description: "Våra redovisningsexperter kan hjälpa dig med att färdigställa bokslutet. För att vi ska kunna göra detta behöver du förse oss med viss information kring ditt företag, sedan sker följande steg:",
    BokioClosure_PayIn6Month: "* Betala räntefritt om 6 månader",
    BokioClosure_Purchase_Clarify: "Vi kommer ge dig support genom bokslutsprocessen, men först skulle vi behöva information kring ditt företag för att färdigställa bokslutet. Var vänlig fyll i följande sidor så genomgående som möjligt för att vi ska kunna färdigställa bokslutet så bra som möjligt.",
    BokioClosure_Purchase_Thank: "Tack för ditt val av Bokio Bokslut!",
    BokioClosure_Unavailable: "Ej tillgänglig just nu",
    BokioClosure_YearEndAccountsByAccountant: "Bokslut av redovisningskonsult",
    BokioClosureCooperativeManual_CreateAnnualFinancialStatementNotSupported: "Skapa årsredovisning stöds ej",
    BokioClosureK2K3_BookkeepClosure: "Bokför övriga bokningar kring bokslutet",
    BokioClosureK2K3_BookkeepTax: "Bokför årets skatt",
    BokioClosureK2K3_BookkeepYear: "Bokför årets resultat",
    BokioClosureK2K3_CompleteTaxReturn: "Skapa din inkomstdeklaration",
    BokioClosureK2K3_CreateAnnualReport: "Skapa din årsredovisning",
    BokioClosureK2K3_Description: "En redovisningskonsult från en av våra samarbetsbyråer hjälper dig med att slutföra ditt bokslut.",
    BokioClosureK2K3_OfferExiresIn: "Erbjudandet löper ut om {0} dagar",
    BokioClosureK2K3_SupportIncluded: "Support från Bokio",
    BokioClosureK2K3_Supports: "Stödjer endast",
    BokioClosureK2K3_WhatOurAccountsDo: "Vad våra redovisningexperter gör åt dig",
    BokioClosureLimitedManual_BookkeepClosure: "Bokför årets skatt och resultat",
    BokioClosureLimitedManual_CloseYear: "Stänga ditt räkenskapsår",
    BokioClosureLimitedManual_CreateAnnualReports: "Skapa årsredovisning",
    BokioClosureLimitedManual_CreateTaxReturn: "Skapa inkomstdeklaration",
    BokioClosureLimitedManual_Description: "Med manuellt bokslut har du endast möjlighet att bokföra årets skatt och resultat samt stänga ditt räkenskapsår. Om du har behov av att skapa en årsredovisning och inkomstdeklaration, föreslår vi att du väljer ett av de andra paketen.",
    BokioClosureLimitedManual_Support: "Support enligt din nuvarande Bokio plan",
    BokioClosureManual_BookkeepClosure: "Bokför alla bokslutsbokningar, skatt och resultat",
    BokioClosureManual_CloseYear: "Stänga ditt räkenskapsår",
    BokioClosureModal_DividendSupport: "**Utdelning:** Du kan välja att lägga till denna tjänst när du fyller i bokslutsformuläret i nästkommande steg.",
    BokioClosureModal_InstructionText: "Vi kommer att hjälpa dig med att slutföra ditt bokslut. Vi ber dig att fylla i de kommande sidorna så noggrant som möjligt så att vi kan hjälpa dig att slutföra ditt bokslut på bästa sätt och få all din nödvändiga företagsinformation.",
    BokioClosureModal_Payment: "Betalning",
    BokioClosureModal_PaymentAdjustments: "\n\n*Om du redan har betalt för det här bokslutspaketet kan du bortse från meddelandet.*",
    BokioClosureModal_PaymentTerms: "Genom att fortsätta accepterar du betalningsvillkoren. Det finns möjlighet att ändra ditt paket I efterhand. Vi debiterar sig först efter du har slutfört steg 5 och vi upprättat ditt bokslut.",
    BokioClosureModal_ThankYou: "Tack för att du väljer Bokslut via redovisningskonsult",
    BokioClosureModal_Title: "Bokslut av redovisningskonsult",
    BokioClosurePackage_DigitalClosure_Body: "Du kan skapa din årsredovisning, signera med BankID och skicka in till Bolagsverket, helt digitalt för endast {price}",
    BokioClosurePackage_DigitalClosure_Heading: "Vill du prova vår nya digitala bokslutstjänst?",
    BokioClosurePartnershipManual_CreateNEBilaga: "Skapa handelsbolagets NE-bilaga",
    BokioClosureProprietorship_CreateNEBilaga: "Skapar och går igenom din NE-bilaga",
    BokioClosureProprietorship_Description: "Våra redovisningsexperter granskar din bokföring och slutför ditt bokslut.",
    BokioClosureProprietorship_ExpertSupport: "Support från våra redovisningsexperter",
    BokioClosureProprietorshipManual_BookkeepResult: "Bokföra årets resultat och skatt",
    BokioClosureProprietorshipManual_CreateNEBilaga: "Skapa din NE-bilaga",
    BokioClosureProprietorshipManual_Description: "Du kommer manuellt slutföra ditt bokslut i Bokio.",
    BokioClosureProprietorshipManual_Title: "Manuellt bokslut",
    BokioClosureProprietorshipManual_WhatYouHaveToDo: "Vad du måste göra manuellt",
    BokioClosures_ServiceProvidedByPartners: "Tjänsten tillhandahålls av våra samarbetspartners genom Bokio.",
    BookkeepAndContinue: "Bokför och fortsätt",
    BookkeepAndContinueToNextStep: "Bokför &amp; fortsätt",
    BookkeepingIncomeExpenses_Description: "För att färdigställa ditt bokslut behöver du ha bokfört och redovisat alla dina intäkter och kostnader tillhörande verksamhetsåret. För detta behövs att leverantörsfakturor, kundfakturor, utlägg, kvitton, avskrivningar och lönespecifikationer hamnar på rätt verksamhetsår. Om du har intäkter eller kostnader du inte bokfört eller vars faktura du ej mottagit vid årets slut kan du justera detta i steg 3.",
    BookkeepingNothingInPeriod: "Du har inte bokfört mot ett bankkonto detta räkenskapsåret",
    BookkeepingYourIncomeAndExpenses: "Bokför alla dina intäkter och kostnader",
    BookkeepPeriodisation_heading: "Bokföra förutbetalda intäkter och upplupna kostnader",
    BookkeepResult: "Bokför årets resultat",
    BookkeepResultAndTax: "Bokför årets resultat och årets skatt",
    BookkeepResults_description: "Som en del av bokslutet bokförs årets resultat om till eget kapital i balansräkningen som ökar det egna kapitalet om bolaget går med vinst, men även minskar eget kapital om bolaget visar ett negativt resultat.",
    BookkeepResults_description_dividend: "Som en del av bokslutet bokförs årets resultat om till eget kapital (om ej utdelning sker till aktieägarna) i balansräkningen som ökar det egna kapitalet om bolaget går med vinst, men även minskar eget kapital om bolaget visar ett negativt resultat.",
    BookkeepResults_heading: "Bokför vinsten eller förlusten för verksamhetsåret",
    BookkeepTax: "Bokför skatter",
    BookkeepTax_description: "En del av bokslutsprocessen är att bokföra årets skatt för ditt verksamhetsår. Årets skatt beräknas genom årets resultat för verksamhetsåret. Vänligen kontrollera uträknat belopp enligt nedan och justera vid behov genom att klicka på knappen “Så här räknade vi”",
    BookkeepTax_heading: "Bokför årets skatt",
    ChangeDate_ButtonText: "Ändra datum",
    CheckFiscalYear_StatusTitle: "Kontrollera räkenskapsår",
    CheckFiscalYears_NoticeTitle_Error: "Räkenskapsåret angivet i Bokio är felaktigt",
    CheckFiscalYears_NoticeTitle_Warning: "Kontrollera att räkenskapsåret är korrekt angivet i Bokio",
    ChoosePackage: "Välj paket",
    ChoosePackage_Description_WithNe: "När du har slutfört bokslutet kommer du kunna hämta siffror för din NE bilaga.",
    ChoosePackage_Description_WithoutNe: "Nu har du slutfört och bekräftat den första checklistan, nästa steg är att bestämma hur du vill gå vidare med ditt bokslut. Följande alternativ är tillgängligt för ditt företag.",
    ChooseTypeOfClosure: "Välj typ av bokslut",
    CloseYear: "Stäng året",
    CloseYearAndShowClosure: "Stäng året &amp; avsluta",
    Closure: "Bokslut",
    Closure_Cancel_Button: "Avbryt",
    Closure_Rating_Description: "Har du några tankar som kan hjälpa oss att förbättra din upplevelse av bokslutet?",
    Closure_Rating_Title: "Hur var din upplevelse av att genomföra ditt bokslut?",
    ClosureCooperativeManual_Description: "Med manuellt bokslut har du endast möjlighet att bokföra årets skatt och resultat samt stänga ditt räkenskapsår. En ekonomisk förening ska alltid upprätta årsredovisning och ha revisor. Beroende på företagets storlek ska årsredovisningen upprättas enligt K2 eller K3 regelverket och detta är något som sker utanför Bokio. Vad du kan göra i Bokio:",
    ClosureDefaultManual_Description: "Med manuellt bokslut har du endast möjlighet att bokföra årets skatt och resultat samt stänga ditt räkenskapsår.",
    ClosureDescription: "Stäm av, periodisera och bokför årets skatt och resultat",
    ClosureFeedback_Checkbox1_label: "Jag gör mitt bokslut utanför Bokio",
    ClosureFeedback_Checkbox2_label: "Det är någon annan i bolaget som gör bokslutet",
    ClosureFeedback_Checkbox3_label: "Jag betalar någon för att göra bokslutet åt mig",
    ClosureFeedback_Checkbox4_label: "Det är inte relevant för mig just nu, jag gör bokslutet vid ett senare datum",
    ClosureFeedback_Feedback_Message1: "Vi skulle uppskatta om du vill svara på några korta frågor innan du forsätter.",
    ClosureFeedback_Feedback_Message2: "Vad är anledningen till att du vill dölja informationen?",
    ClosureFeedback_Feedback_Title: "Dölj bokslutsinformation",
    ClosureLimitedPartnershipManual_Description: "Manuellt bokslut tillåter dig att endast bokföra årets skatt och resultat samt stänga ditt räkenskapsår i Bokio. De flesta kommanditbolag behöver inte skicka in någon årsredovisning till bolagsverket om inte det räknas som ett större bolag eller har en eller flera juridiska personer som delägare. Vad du kan göra i Bokio:",
    ClosureNoYearsToClosureText: "När ditt räkenskapsår är slut kommer det komma upp här.",
    ClosureNoYearsToClosureTextCompleted: "När ditt första bokslut avslutas det komma upp här.",
    ClosureNoYearsToClosureTextUpcoming: "När ditt nästa räkenskapsår har skapats det komma upp här.",
    ClosureNoYearsToClosureTitle: "Inga bokslut att visas ",
    ClosurePackage_CloseYear: "Stäng ditt räkenskapsår",
    ClosurePackage_GetStarted_Button: "Fortsätt",
    ClosurePackageSelection_OtherPackageButton_GoToPrevYearEndSol: "Gå till tidigare bokslutslösning",
    ClosurePackageSelection_OtherPackageDescription: "För att välja någon av Bokios andra bokslutspaket nedan behöver du använda den tidigare bokslutslösningen.",
    ClosurePackageSelection_OtherPackageHeader: "Andra bokslutspaket",
    ClosurePackageSelection_OtherPackageOne_Desc: "En redovisningskonsult från en av våra sammarbetsbyråer hjälper dig med att slutföra ditt bokslut.",
    ClosurePackageSelection_OtherPackageOne_Info: "Stödjer endast K3",
    ClosurePackageSelection_OtherPackageOne_Price: "14 990 kr",
    ClosurePackageSelection_OtherPackageOne_Title: "Bokslut av redovisningskonsult",
    ClosurePackageSelection_OtherPackageThree_Price: "0 kr",
    ClosurePackageSelection_OtherPackageThree_Title: "Manuellt bokslut",
    ClosurePackageSelection_OtherPackageTwo_Desc: "En redovisningskonsult från en av våra sammarbetsbyråer hjälper dig med att slutföra ditt bokslut.",
    ClosurePackageSelection_OtherPackageTwo_Info: "Stödjer endast K2",
    ClosurePackageSelection_OtherPackageTwo_Price: "7 990 kr",
    ClosurePackageSelection_OtherPackageTwo_Title: "Bokslut av redovisningskonsult",
    ClosurePartnershipManual_Description: "Med manuellt bokslut har du möjlighet att bokföra årets skatt och resultat, stänga ditt räkenskapsår samt skapa NE-bilagan. Tänk på att vi endast skapar en NE-bilaga och denna måste delas mellan dig och de andra delägarna. Vad du kan göra i Bokio:",
    ClosuresTodoReminder_ErrorMessage: "Ett fel inträffade när din påminnelse i att göra listan skapades.",
    CompanyInformation_IHaveSubmitted: "Jag har skickat in formuläret",
    CompleteCompanyInformation_description: "Var vänlig fyll i följande information som vi inte kan läsa ut från din bokföring men som är nödvändig för att vi ska kunna göra klart din årsredovisning.",
    CompleteCompanyInformation_heading: "Företagsinformation",
    CompleteCompanyInformation_Status: "Status på formuläret:",
    CompleteVATReport: "Färdigställ momsrapporter",
    CompleteVATReport_Description: "Innan du kan färdigställa ditt bokslut måste du gå igenom och klarmarkera alla momsrapporter tillhörande verksamhetsåret. För att kunna färdigställa din sista momsrapport måste du stänga alla tidigare momsrapporter i rätt ordning, där du börjar med den äldsta rapporten först.",
    ConfirmAndContinue: "Bekräfta och fortsätt",
    ConfirmDividend_description: "Om företaget har beslutat om att utdelning till aktieägarna ska ske, måste detta vara dokumenterat i årsstämmoprotokollet och bokföras i det kommande verksamhetsåret. {Läs mer}",
    ConfirmDividend_heading: "Bekräfta utdelning",
    ConfirmToUnlockStep_description: "Är du säker på att vill låsa upp detta steg?",
    ConfirmToUnlockStep_heading: "Lås upp steg",
    ContinueToNextStep: "Fortsätt till nästa steg",
    ContinueWithAO: "Fortsätt med Årsredovisning Online",
    ContinueWithBokioClosure: "Köp Bokio Bokslut",
    ContinueWithManualClosure: "Fortsätt med Manuellt bokslut",
    Cost_heading: "Räntekostnader",
    CreateTaxReturn: "Skapa din inkomstdeklaration",
    CreateYearEndBookkeeping: "Bokför årets skatt och resultat",
    CreateYourClosure: "Skapa ditt bokslut",
    CreateYourClosure_Description: "I slutet av varje år behöver du stänga verksamhetsåret genom att upprätta ett bokslut. Syftet är att färdigställa all bokföring under verksamhetsåret för att kunna återge en sann och korrekt bild av din verksamhet. Detta är ett lagstadgat krav och är ett bra sätt för dig att säkerställa att din bokföring är korrekt per bokslutsdatum.",
    CreatingYourDocuments_description: "Vi skapar nu filerna som du behöver till ditt bokslut. Vi kommer skicka filerna till din e-post när de är klara. På denna sida kommer det då finnas ytterligare steg du behöver genomföra",
    CreatingYourDocuments_heading: "Luta dig tillbaka, vi skapar nu filer till ditt bokslut",
    DatesAndEvents_ChangePackage: "Ändra paket",
    DatesAndEvents_ClosureDateAndEvents: "Bokslutsdatum och händelser",
    DepositionsStep_Info: "*TRANSLATE*",
    DepositionsStep_InfoTitle: "Vad är avsättningar?",
    DepositionsStep_Title: "Avsättningar",
    Depreciation_Action: "Om du inte har bokfört det här måste du göra det innan du går vidare till nästa steg.",
    Depreciation_Button: "Registrera avskrivning",
    Depreciation_Description_InfoBar: "Det finns {0} tillgång(ar) i Bokios anläggningsregister som behöver skrivas av",
    Depreciation_Description_InfoBar_NoAssetsToDepreciate: "Det finns inga tillgångar i Bokios anläggningsregister som behöver skrivas av",
    Depreciation_Description1: "Om ditt företag har tillgångar som kostar mer än ett halvt \"Prisbasbelopp\" och med en livslängd på mer än 3 år måste de skrivas av. Det innebär att du sprider ut kostnaden under flera år genom att bara bokföra en del av kostnaden varje år.\n\nFör närvarande måste både inköp och avskrivningar bokföras manuellt. {Läs mer}.",
    Depreciation_heading: "Avskrivningar",
    Depreciation_ProvideInformation: "Var vänlig informera oss om de tillgångar som behöver skrivas av och relevant information, såsom typ, avskrivningsmetod, ackumulerad avskrivning, avskrivningsperiod, verifikationsnummer och liknande. {Läs mer}",
    Depreciations_ConfirmModal_Text: "Du har tillgångar i Bokios tillgångsregister som inte är avskrivna, men som behöver skrivas av. Om du fortsätter utan att skriva av tillgångarna kommer bokföringen och registret vara ur synk. Om du har bokfört avskrivningarna manuellt kan du arkivera tillgången så kommer den inte längre vara en del av ditt tillgångsregister i Bokio. Vill du i fortsättningen använda dig av Bokios anläggningsregister kan du skapa nya tillgångar och alltid göra avskrivningarna där.\n\nVill du fortsätta?",
    Depreciations_ConfirmModal_Title: "Du har tillgångar i registret utan avskrivningar",
    DepreciationStep_Info: "Maskiner, kontorsinventarier och byggnader som köpts till verksamheten ska skrivas av. Om beräknad livslängd är högst 3 år eller om värdet är högst ett halvt prisbasbelopp (22 200kr, PBB = 44 400kr 2015) så får detta göras direkt.",
    DepreciationStep_InfoTitle: "Vad är avskrivningar?",
    DepreciationStep_Title: "Avskrivningar",
    DigitalArchive_Backup_Content: "Om du vill hämta filerna som skapats vid ditt bokslut kan du ladda ner dem här nedan.",
    DigitalArchive_Backup_Heading: "Säkerhetskopiera årsredovisningsdata",
    DigitalArchive_ConfirmUnlockYearContent: "Eftersom Bokio stängt den Digitala Årsredovisnings tjänsten kan du ej längre använda den för att göra korrigeringar i ditt bokslut. Ladda gärna ner de befintliga filerna innan du fortsätter. Låser du upp ett räkenskapsår kommer du även låsa upp senare räkenskapsår.",
    DigitalArchive_Download: "Ladda ner alla",
    DigitalArchive_Files: "Filer",
    DigitalArchive_HowToFile: "{Hur du skickar in årsredovisningnen till Bolagsverket}",
    DigitalArchive_HowToFile_Link: "https://bolagsverket.se/en/sjalvservice/etjanstersjalvservice/lamnainarsredovisningendigitalt/saharlamnarduinarsredovisningendigitalt.1667.html",
    DigitalArchive_Info_Content_CloseYear: "Bokio har stängt den Digitala Årsredovisnings tjänsten. Det betyder att du inte längre kan skapa en ny rapport, göra ändringar eller skicka in dokument genom tjänsten. Du kan fortfarande stänga året samt ladda ner de filer som redan skapats via tjänsten.",
    DigitalArchive_Info_Content_DownloadFiles: "Bokio har stängt den Digitala Årsredovisnings tjänsten. Det betyder att du inte längre kan skapa en ny rapport, göra ändringar eller skicka in dokument genom tjänsten. Du kan fortfarande ladda ner de filer som redan skapats via tjänsten.",
    DigitalArchive_Info_Heading: "Information om Bokios Digitala Årsredovisnings tjänst",
    DigitalArchive_LockYear_Content: "Du kan ej göra några ändringar i detta årets bokslut, men du kan fortfarande stänga året. Om du ej redan gjort det behöver du skicka in årsredovisningen till Bolagsverket inom sju månader från slutet av räkenskapsåret.",
    DigitalArchive_UnlockYear_Accept: "Jag har laddat ner filerna och förstår att jag ej kan göra ändringar i bokslutet.",
    DigitalArchive_UnlockYear_Content: "Eftersom Bokio stängt den Digitala Årsredovisnings tjänsten kan du ej längre använda den för att göra korrigeringar i ditt bokslut. Om du gör några ändringar i bokföringen för räkenskapsåret kan du behöva skapa ett nytt bokslut. Låser du upp ett räkenskapsår kommer du även låsa upp senare räkenskapsår.",
    DigitalArchive_UnlockYearEnd: "Lås upp räkenskapsår",
    Download_AGMMinutes_heading: "Ladda ner årsmötesprotokoll",
    DownloadAllFiles_ButtonText: "Ladda ner alla",
    FeatureToggle_ActivateNow_Action: "Aktivera nu",
    FeatureToggle_TryNewClosures: "Vi har nyligen lanserat en helt ny och förbättrad Bokslut upplevelse. Aktivera och testa den idag!",
    FillYourAnswer: "Fyll i ditt svar",
    FinishStep_Title: "Stäng året",
    FinishStep_TitleFinished: "Bokslutet är klart. Bra jobbat!",
    FiscalYear_QuickCloseError_FiscalYearNotBalanced: "Räkenskapsåret är inte balanserat",
    FiscalYearMismatch_ErrorMsg: "Det räkenskapsår som du angivit i Bokio stämmer inte överens med det räkenskapsår som är registrerat hos Bolagsverket.",
    FiscalYearMismatch_WarningMsg: "Det räkenskapsår som du angivit i Bokio stämmer inte överens med det räkenskapsår som finns registrerat hos Bolagsverket. Denna differens kan uppstå om ditt bolag har ett förlängt första räkenskapsår men väljer att lämna in årsredovisningen innan räkenskapsårets slut.\n\nOm du är medveten om denna differens, och har för avsikta att lämna in årsredovisningen tidigare, kan du bortse från denna varning.\nOm du däremot inte har ett förlängt räkenskapsår, och denna varning kom oväntat, rekommenderar vi dig att ändra räkenskapsåret i Bokio så att det stämmer överens med det som finns registrerat hos Bolagsverket.",
    FreeOfCharge: "Gratis",
    GetHelpByOneOfOurPartners: "Få hjälp av någon av våra samarbetspartners.",
    Goods_heading: "Inköp av varor för dig som bedriver varuhandel",
    GoToMyPagesAtBolagsverket_action: "Gå direkt till {mina sidor} hos Bolagsverket.",
    HireAConsultant: "Stäm av med en redovisningskonsult",
    HowWeCounted: "Så här räknade vi",
    IAlreadyBookkept: "Jag har redan bokfört detta",
    ImportBookkeeping: "Importera bokföring",
    Income_heading: "Intäkter",
    IncomeFromAccrualFunds: "Schablonintäkt på periodiseringsfonder",
    IncomeFromInvestmentFunds: "Schablonintäkt på investeringsfonder",
    InitialChecklist_NotOpenYet: "Bokslutet för räkenskapsåret {0} kan du göra efter {1}",
    InvalidOrgNumberChecksum: "Organisationsnumret för ditt företag verkar ha skrivits av fel (ogiltig kontrollsiffra). {Justera här}.",
    InvalidOrgNumberFormat: "Organisationsnumret för ditt företag har felaktigt format. {Justera här}.",
    K2Tooltip_Text: "Mindre företag använder vanligtvis K2, vilket är ett förenklat och standardiserat regelverk.",
    K3Tooltip_Text: "K3 är mer omfattande och passar bättre för större företag",
    LimitedCompanyPackage_Description: "K2 och K3 är två olika redovisningsstandarder som kan användas av aktiebolag. Mindre företag använder vanligtvis K2, vilket är ett förenklat och standardiserat regelverk.  K3 är ett mer omfattande och passar bättre för större företag. För mer information kring regelverken hittar du information under {hjälp-sidan} läs mer",
    LockableState_CanNotCloseYear: "Du kan påbörja ditt bokslut efter {0}, när ditt räkenskapsår är avslutat.",
    LockableState_ClosedYearCannotChangePackage: "För att byta bokslutstjänst måste du först låsa upp detta räkenskapsår i det sista steget i den nu valda bokslutstjänsten.",
    LockableState_MustLockPreviousYearsFirst: "Du måste göra bokslut för tidigare bokföringsår innan du kan fortsätta. Om du redan har gjort bokslut för föregående år kan du läsa mer om hur du stänger dina tidigare bokföringsår i Bokio [här](https://www.bokio.se/hjalp/bokslut/bokslut/stang-bokslut-och-rakenskapsar-utan-att-bokfora/).",
    LockYear_description: "När verksamhetsåret är låst går det inte att bokföra ytterligare transaktioner. Dock kan du alltid låsa upp verksamhetsåret om något behöver läggas till eller rättas. Låses ett räkenskapsår upp innebär det att samtliga efterföljande år kommer låsas upp.",
    LockYear_heading: "Lås år",
    LockYearTooSoon_Info: "Du är på väg att stänga ett räkneskapsår som slutade för mindre än 2 veckor sedan. Vi rekommenderar dig att vänta till 2 veckor efter att det slutat då det fortfarande kan finnas händelser som inte är bokförda.",
    LockYearTooSoon_Title: "Räkenskapsåret slutade för mindre än 2 veckor sedan",
    LockYearWithUnbalancedReport_Info: "Dina tillgångar och skulder för det här räkenskapsåret balanserar inte. Det kan bero på utgifter eller inkomster som har bokförts utan att beräkna dina skatter och resultat på nytt. Att låsa räkenskapsåret nu kommer att resultera i en obalans i nästkommande räkenskapsårs balansrapport. Är du säker på att du vill fortsätta?",
    ManualClosure: "Manuellt bokslut",
    ManualClosure_Description: "Om du vill upprätta ett manuellt bokslut – använd detta alternativet.",
    ManualClosureModal_FreeMessage: "Det manuella bokslutet är gratis.",
    ManualClosureModal_InstructionText: "Det här alternativet är lämpligt om du vill slutföra ditt bokslut själv. I följande steg kommer du behöva fylla i och bokföra alla nödvändiga transaktioner som avser ditt bokslut. Kom ihåg att stänga ditt räkenskapsåret när du har slutfört alla steg.",
    ManualClosureModal_ThankYou: "Tack för att du väljer manuellt bokslut",
    ManualClosureModal_Title: "Manuellt bokslut",
    ManualYearEndClosureDescription: "Gör bokslutstransaktioner och stäng året",
    MissingOrgNumber: "Organisationsnumret saknas för ditt företag. {Fyll i här}.",
    NEAppendix: "NE-bilaga",
    NeAppendixWarning_NegativeCells: "Det finns celler med negativa värden, vilket inte accepteras av Skatteverket. Det kan bero på ett bokföringsfel. Det kan också vara så att värdet ska flyttas till en cell på motsatt sida, till exempel ska en negativ tillgång flyttas till skulder. {Läs mer om varningen}.",
    NEBilaga: "NE-bilaga-{year}",
    NEBilaga_DeclarationHelp: "Deklarationshjälp",
    NEBilaga_Description: "Den här sidan visar hur NE-bilagan på Skatteverket kommer att se ut. Denna ska inte skrivas ut. Den är här för att hjälpa dig att fylla i din deklaration på Skatteverket.se. NE-bilagan är ifylld utifrån din bokföring. Du kan behöva justera siffrorna när du fyller i dem hos Skatteverket. Typiska exempel på detta är om du har haft bil eller bostadsförmån.",
    NEPurchaseInitiationError_GenericMessage: "Misslyckades med att starta NE Bilaga köpet. Var god försök senare.",
    NEPurchaseInitiationError_InvalidRecipientInfoDetails: "Nödvändig kontaktinformation om företaget saknas. Var god ange [alla uppgifter om företaget](bokio:/companyid/settings-r/company-settings) så som företagsnamn, personnummer, adress, kontaktperson och e-post.",
    NEPurchaseInitiationError_InvalidRecipientInfoTitle: "Ofulständig kontaktinformation",
    NextStep: "Nästa steg",
    NonBokioUser_Completed_Description: "Årsredovisningen för {0} är färdig.",
    NonBokioUser_Completed_Title: "Klart!",
    NonBokioUser_OfferBody: "Upptäck våra planer",
    NonBokioUser_OfferHeading: "Fortsätt förenkla din bokföring!",
    NonBokioUser_OfferText: "Erbjudande",
    NonRefundable: "Ej avdragsgilla kostnader",
    NonTaxableIncome: "Ej skattepliktiga intäkter",
    NotSureBookeepingIsCorrect: "Är du osäker på om din bokföring är korrekt?",
    OneReport: "Endast",
    OpenFiscalYear_Action: "Open year",
    OpenTheForm: "Öppna formuläret",
    Others_heading: "Övriga kostnader",
    Overview_ClosuresNotice_Paragraph: "Slipp stressen med ditt bokslut genom att planera och börja i god tid. Vi har satt ihop guider för att hjälpa dig att lättare komma igång och förstå alla delar kring ett bokslut.",
    Overview_ClosuresNotice_Title: "Kom igång med ditt bokslut",
    OverviewOfEssentialAccounts: "Översikt av konton",
    Periodisation_Cost_OutstandingInterestLosses: "Finns det några räntekostnader som kommer att betalas i nästa räkenskapsår?",
    Periodisation_Cost_OutstandingInterestLosses_InputLabel: "Kommande räntekostnader",
    Periodisation_Cost_PrepaidInterestLosses: "Betalade du några räntekostnader som tillhör nästa räkenskapsår?",
    Periodisation_Cost_PrepaidInterestLosses_InputLabel: "Betalda räntekostnader",
    Periodisation_Goods_GoodsInventory: "Skiljer sig ditt lagervärde från vad som var bokfört sista dagen på räkenskapsåret?",
    Periodisation_Goods_GoodsInventory_Hint: "Till exempel kanske du hittar trasiga varor när du gör din inventering, då skulle du få ett negativt värde här eftersom lagervärdet är lägre än vad bokföringen säger.",
    Periodisation_Goods_GoodsInventory_InputLabel: "Skillnad i lagervärde",
    Periodisation_Goods_GoodsOutstandingExpenses: "Erhöll du några varor som kommer faktureras i nästa räkenskapsår?",
    Periodisation_Goods_GoodsOutstandingExpenses_InputLabel: "Kommande betalning (ex moms)",
    Periodisation_Goods_GoodsPrepaidExpenses: "Blev du fakturerad för varor som du kommer erhålla i nästa räkenskapsår?",
    Periodisation_Goods_GoodsPrepaidExpenses_InputLabel: "Betalt belopp",
    Periodisation_Income_OutstandingInterest: "Fanns det några räntor du skulle ha mottagit men som du kommer få betalt för i nästa räkenskapsår?",
    Periodisation_Income_OutstandingInterest_InputLabel: "Kommande ränteinbetalningar",
    Periodisation_Income_OutstandingSales: "Sålde du några varor eller tjänster som kommer faktureras i nästa räkenskapsår?",
    Periodisation_Income_OutstandingSales_InputLabel: "Summa att fakturera (ex moms)",
    Periodisation_Income_PrepaidInterest: "Erhöll du räntor som avser nästa räkenskapsår?",
    Periodisation_Income_PrepaidInterest_InputLabel: "Mottagen ränta",
    Periodisation_Income_RecievedAdvances: "Fakturerade du för några varor eller tjänster som kommer levereras i nästa räkenskapsår?",
    Periodisation_Income_RecievedAdvances_InputLabel: "Mottagen betalning (ex moms)",
    Periodisation_Others_OtherOutstandingExpenses: "Erhöll du varor eller tjänster som kommer faktureras i nästa räkenskapsår?",
    Periodisation_Others_OtherOutstandingExpenses_InputLabel: "Kommande betalning (ex moms)",
    Periodisation_Others_OtherPrepaidExpenses: "Blev du fakturerad för varor eller tjänster som du kommer erhålla i nästa räkenskapsår?",
    Periodisation_Others_OtherPrepaidExpenses_InputLabel: "Fakturabelopp (ex moms)",
    Periodisation_Salary_PrepaidSalaries: "Betalade du förskottslöner för nästa räkenskapsår? Om du använder Bokio Lön kan du välja Nej då detta hanteras automatiskt",
    Periodisation_Salary_PrepaidSalaries_InputLabel: "Summa löner",
    Periodisation_Salary_UnpaidSocials: "Finns det några arbetsgivaravgifter eller sociala avgifter som kommer betalas i nästa räkenskapsår? Om du använder Bokio lön kan du välja nej då detta hanteras automatiskt",
    Periodisation_Salary_UnpaidSocials_InputLabel: "Sociala avgifter att betala",
    PeriodisationStep_Info: "Periodiseringar görs när fakturan och uppkomsten till kostnaden/intäkten annars skulle hamnat på olika räkenskapsår.\n\nEtt exempel på en periodisering kan vara en vara eller tjänst som du levererat men inte skickat fakturan eller en vara eller tjänst som du fått men inte fått någon faktura för. Du behöver bara periodisera utgifter/inkomster som är på 5000kr och uppåt. Två köp på 4000kr behöver alltså inte periodiseras.",
    PeriodisationStep_InfoTitle: "Vad är periodiseringar?",
    PeriodisationStep_Title: "Periodiseringar",
    PeriodizationAccount_OutgoingBalance_HelpArticle: "Om du känner dig osäker så spana gärna in vår guide.",
    PeriodizationAccount_OutgoingBalance_Warning: "Följande periodiseringskonton har en utgående balans: {0}. Försäkra dig om att periodiseringskontona har korrekta namn och att de följer BAS standard. Du kan uppdatera namnet på periodiseringskonton under {Kontoplan.}",
    Progress_ClosureChecklist: "Bokslutsbokningar",
    Progress_ClosurePackage: "Välj bokslutspaket",
    Progress_CompanyInformation: "Bokslutsformulär",
    Progress_CompleteClosure: "Färdigställ bokslut",
    Progress_InitialChecklist: "Avstämning",
    Progress_SubmittedToBokio: "Inskickat till Bokio",
    Provision_Description1: "Avsättningar är förpliktelser som är sannolika eller säkra beträffande sin förekomst men som är obestämda i fråga om belopp eller tidpunkt då de skall infrias. {Läs mer}",
    Provision_Description2: "Om du behöver bokföra avsättningar, var vänliga fyll i typ och belopp nedan.",
    Provision_heading: "Avsättningar",
    Provisions_Action: "Om du inte har bokfört det här måste du göra det innan du går vidare till nästa steg.",
    ReadyToDownload: "Klar för nedladdning",
    RecordedAs_VerificationNumber: "Bokförd som",
    ResendBookkeeping: "Skicka uppdaterad bokföring till ÅO",
    Resources_NEBilaga: "Visa NE-bilaga",
    ResultBeforeTax: "Resultat före skatt",
    ResultStep_Title: "Föra över årets resultat",
    Salary_heading: "Löner",
    SavedUnderskott: "Sparat underskott",
    SelectClosurePackage_HowToPayModal_DescOne: "En bekräftelse av att du godkänner betalningsvillkoren och att Bokio får fakturera dig {price} för tjänsten sker i steg tre. Du kan med andra ord göra klart steg ett och två i bokslutsflödet utan att faktureras.",
    SelectClosurePackage_HowToPayModal_DescTwo: "I sin helhelt tar vi betalt för att erbjuda en digitaliserad bokslutslösning där du slipper pappersarbete och allt samlas på ett ställe. Det innebär att vi skapar din årsredovisning, inkomstdeklaration 2 och tillhörande SRU-filer, möjliggör signering med BankID och digital inlämning av årsredovisningen.",
    SelectClosurePackage_HowToPayModal_HeaderOne: "I vilket steg fakturerar vi för tjänsten?",
    SelectClosurePackage_HowToPayModal_HeaderTwo: "Vad tar vi betalt för?",
    SelectClosurePackage_HowToPayModal_SelectPackageAndCont_Button: "Välj paket och forsätt",
    ShowAllClosures: "Visa alla bokslut",
    ShowInfo: "Visa info",
    SignAnnualGeneralMeetting_description: "Du behöver färdigställa och signera protokollet för årsstämman. Protokollet måste vara daterat till samma datum som årsredovisningen. Detta dokument behöver inte skickas till Bolagsverket.",
    SignAnnualGeneralMeetting_description_llc: "Du behöver färdigställa och signera protokollet för årsstämman. Protokollet måste vara daterat efter årsredovisningen. Detta dokument behöver inte skickas till Bolagsverket.",
    SignAnnualGeneralMeetting_heading: "Signera protokollet för årsstämman",
    SignSendCertificateFinancialStatementToBolagsverket_description: "En styrelseledamot eller VD ska fastställa att årsredovisningen har godkänts på en årsstämma. Fastställelseintyget ska innehålla datum för årsstämma och att vinstdispositionen har fastställts",
    SignSendCertificateFinancialStatementToBolagsverket_heading: "Signera och skicka in fastställelseintyget tillsammans med Årsredovisningen till Bolagsverket",
    SignSendCertificateFinancialStatementToBolagsverket_subdescription1: "Fastställelseintyget:",
    SignSendCertificateFinancialStatementToBolagsverket_subdescription2: "ska vara i original",
    SignSendCertificateFinancialStatementToBolagsverket_subdescription3: "ska signeras av en styrelseledamot eller VD",
    SignSendCertificateFinancialStatementToBolagsverket_subdescription4: "ska innehålla datum för årsstämman",
    SignSendFinancialStatementToBolagsverket_description: "En kopia av årsredovisningen behöver skickas till Bolagsverket tillsammans med fastställelseintyget. Se nästa avsnitt om fastställelseintyget och om årsstämmoprotokollet. Adressen till Bolagsverket är Bolagsverket, Årsredovisningar, 851 98 Sundsvall.",
    SignSendFinancialStatementToBolagsverket_description1: "Om bolaget klassificera som ett större bolag eller om ägarna utgör juridiska personer ska Årsredovisningen skickas in till Bolagsverket. Adressen till Bolagsverket är Bolagsverket, Årsredovisningar, 851 98 Sundsvall.",
    SignSendFinancialStatementToBolagsverket_description2: "Om bolaget klassificera som ett större bolag eller om ägarna utgör juridiska personer ska en kopia av Årsredovisningen skickas in till Bolagsverket. Adressen till Bolagsverket är Bolagsverket, Årsredovisningar, 851 98 Sundsvall.",
    SignSendFinancialStatementToBolagsverket_heading: "Signera och skicka in Årsredovisningen till Bolagsverket",
    SignSendIncomeTaxLimitedPartnershipToSkatteverket_description: "Du behöver fylla i inkomstdeklaration 4 innan tidsfristen löper ut, vilket varierar beroende på datum för bokslut. Läs mer på ditt företags sida på Skatteverket.",
    SignSendIncomeTaxLimitedPartnershipToSkatteverket_heading: "Fyll i inkomstdeklaration 4 för handelsbolaget på skatteverket.se",
    SignSendIncomTaxToSkatteverket_description: "Du behöver fylla i din deklaration på skatteverket.se innan sista datum för det. Sista datum för ditt bolag kan du hitta på skatteverket.se.",
    SignSendIncomTaxToSkatteverket_description1: "I din personliga skattedeklaration ska beloppen från din NE-bilaga fyllas i din privata skattedeklaration och skickas in till Skatteverket före tidsfristen löper ut, vilket varierar beroende på datum för bokslut. Läs mer på ditt företags sida på Skatteverket.",
    SignSendIncomTaxToSkatteverket_description2: "Varje ägare deklarerar sin del av vinsten. I din personliga skattedeklaration ska beloppen från din N3A eller N3B-bilaga fyllas i din privata skattedeklaration och skickas in till Skatteverket före tidsfristen löper ut, vilket varierar beroende på datum för bokslut. Läs mer på ditt företags sida på Skatteverket",
    SignSendIncomTaxToSkatteverket_heading: "Fyll i din inkomstdeklaration på skatteverket.se",
    SignSendPrivateIncomeSkatteverket_description: "I din personliga skattedeklaration ska beloppen från din N3A eller N3B-bilaga fyllas i din privata skattedeklaration och skickas in till Skatteverket före tidsfristen löper ut, vilket varierar beroende på datum för bokslut. Läs mer på ditt företags sida på Skatteverket.",
    SignSendPrivateIncomeSkatteverket_heading: "Fyll i din personliga deklaration på Skatteverket.se",
    Skip_Button: "Hoppa över",
    State_Active: "Aktiva",
    State_Completed: "Avslutat",
    State_Incomplete: "Ofullständig",
    State_Ongoing: "Nuvarande år",
    State_Upcoming: "Kommande",
    StatusAtBolagsverket_label: "Status hos Bolagsverket",
    StatusOfProgress: "Status: ",
    Step: "Steg",
    SubmissionStatus_Accepted_ApprovedAndSubmitted: "Årsredovisningen är godkänd och inlämnad",
    SubmissionStatus_Deleted: "Bortaget från ditt egna utrymmet",
    SubmissionStatus_Draft_AwaitingSignature: "Inväntar signering av faställelseintyg",
    SubmissionStatus_InjunctionSent: "Föreläggande skickat",
    SubmissionStatus_NotRegistered: "Årsredovisningen kunde inte laddas upp då det redan finns en signerad årsredovisning hos Bolagsverket",
    SubmissionStatus_NotSubmitted: "Ej inlämnad",
    SubmissionStatus_Registered_Signed: "Faställelseintyg signerat och årsredovisningen inväntar godkännande",
    SubmissionStatus_SupplementReceived: "Tillägg mottaget",
    SubmissionStatus_Unknown: "Okänd",
    SubmissionStatusFailed_ErrorMessage: "Det gick inte att hämta status från Bolagsverket",
    SubmitReports: "Skicka in årsredovisningen digital med BankID till Bolagsverket",
    SuitableForSmallBusinesses: "Passar bra för enmansföretag",
    SWE_TaxResultRounding: "Avrundning",
    SWE_TaxResultRounding_Text: "Avrundat enligt Skatteverkets regler",
    Task_HelpFromExperts: "Du får tillgång till support från våra redovisningsexperter",
    Task_SupportFromAO: "Du får tillgång till support från Årsredovisning Online",
    Task_WeBookkeepOtherYearEndResult: "Vi bokför alla övriga bokningar kring årsbokslutet",
    Task_WeBookkeepYearEndResult: "Vi bokför årets resultat och skatt",
    Task_WeBookkeepYearEndResultAndTax: "Vi bokför årets resultat och årets skatt",
    Task_WeBookkeepYourResultToghether: "Vi hjälper dig att bokföra årets resultat",
    Task_WeCloseFinalYear: "Vi stänger bokslutet i Bokio",
    Task_WeCompleteIncome: "Vi skapar din inkomstdeklaration (INK 2)",
    Task_WeCreateAndReviewBilaga: "Vi skapar och går igenom din NE-bilaga",
    Task_WeCreateBilga: "Vi skapar din NE-bilaga",
    Task_WeCreateFinancialStatment: "Vi skapar din Årsredovisning och stänger ditt bokslut",
    Task_YouCloseFinancialInBokio: "Du stänger ditt räkenskapsår i Bokio",
    Task_YouCompleteChecklist: "Du godkänner och klarmarkerar checklistan",
    Task_YouCompleteClosureChecklist: "Du godkänner och klarmarkerar checklistan",
    Task_YouCompleteIncomeThroughAO: "Du färdigställer din inkomstdeklaration via Årsredovisning Online",
    Task_YouCompleteYear: "Du bokför alla övriga bokningar kring årsbokslutet",
    Task_YouCompleteYearEndThroughAO: "Du bokför alla övriga bokningar kring årsbokslutet",
    Task_YouCreateFinancialStatement: "Du skapar din Årsredovisning",
    Task_YouCreateFinancialThroughAO: "Du skapar din årsredovisning via Årsredovisning Online",
    Task_YouCreateIncome: "Du skapar din inkomstdeklaration",
    Task_YouProvideCompanyInformation: "Du förser oss med information om ditt företag",
    Task_YouSendDocumentsToErket: "Du skickar Årsredovisning till Bolagsverket",
    Task_YouSendK1DocumentsToSkaket: "Du skickar Inkomstdeklarationen till Skatteverket",
    Task_YouSendK2DocumentsToSkaket: "Du skickar filer och dokument till myndigheterna",
    TaxAndResults: "Skatt och resultat",
    TaxAndResultsError_InvalidIncomeAdjustmentValue: "Ogiltlig manuell justering i fältet \"{0}\"",
    TaxAndResultsError_PeriodisationFundWithdrawalOverLimit: "Avsättning till periodiseringsfond är större än maxgränsen",
    TaxAndResultsError_ValidatonError: "Validation error in field {0}",
    TaxAndResultsField_AllocationToAccrualFund: "Avsättning till periodiseringsfond",
    TaxAndResultsField_IncomeForRemainingAccrualFunds: "Schablonintäkt på kvarvarande periodiseringsfonder",
    TaxAndResultsField_IncomeForRemainingInvestmentFunds: "Schablonintäkt på kvarvarande investeringsfonder",
    TaxAndResultsField_OtherNonRecordedCosts: "Andra ej bokförda kostnader",
    TaxAndResultsField_OtherNonRecordedIncome: "Andra ej bokförda intäkter",
    TaxAndResultsField_UnusedLossFromPreviousYear: "Outnyttjat underskott från föregående år",
    TaxDeclaration: "Inkomstdeklaration",
    TaxDeclarationDescription: "Generera SRU-filer för inlämning av blankett INK2 till Skatteverket",
    TaxResult: "Skattemässigt resultat",
    TaxStep_Info: "Årets skatt är 'årets vinst * vinstskatten'. Vi bokför detta åt dig och räknar även bort icke-avdragsgilla utgifter så att ni betalar skatt på rätt belopp.",
    TaxStep_InfoTitle: "Vad är årets skatt?",
    TaxStep_Title: "Bokföra skatter",
    TheEntireYearEndProcess: "Hela bokslutsprocessen på ett ställe",
    Tip_InviteAccountant: "Did you know that you can invite multiple users to your company, e.g. an accounting experts that can help you with your year-end closure? [Invite users]({0})",
    TransferOfYearsResult: "Bokför förra årets resultat",
    TransferOfYearsResult_description: "Vid start på ett nytt räkenskapsår ska du bokföra om resultatet från föregående år. Detta görs för att konto 2099 enbart ska innehålla det aktuella räkenskapsårets resultat. Föregående räkenskapsårs resultat ska därför flyttas till konto 2098 när nytt räkenskapsår påbörjas.",
    UK_CloseYear_LimitedCompany_Body: "Before you close the year, ensure that you have filed your year-end accounts with Companies House and that the balance sheet in Bokio agrees to the year-end accounts filed.",
    UK_CloseYear_SoleTrader_Body: "Before you close the year, ensure that you submit your self assessment tax return to HMRC and ensure this agrees to Bokio.",
    UK_CloseYear_Title: "Close year",
    UK_ReviewChecklist_Body: "To prepare for your year-end closure in Bokio, we recommend that you review the checklist below:\n\n- Review fixed assets and ensure that all depreciation and disposals have been recorded\n- Complete a bank reconciliation to verify that your bank statements matches the balance in account 1200\n- Make sure all your VAT returns have been submitted for the current fiscal year\n- All your customer and supplier invoices have been recorded\n- All other income and expenses have been recorded\n- Check that all balance sheet accounts are correct\n- Record corporation tax liability and ensure it agrees to Corporation Tax Return (applies to limited companies)",
    UK_ReviewChecklist_Title: "Review checklist",
    UndoPart: "Lås upp",
    UndoRecording_TransferEquity: "Ångra bokföring",
    UndoSkip_Button: "Ångra hoppa över",
    UnlockStep: "Lås upp steg",
    UnlockToMakeChanges: "För att göra ändringar i detta steg behöver du låsa upp det",
    UnlockYear: "Lås upp år",
    UnlockYear_TransferEquityVerification_Info: "Är du säker på att vill låsa upp räkenskapsåret? Detta kommer att ta bort verifikatet skapat för nollställning av eget kapital om du redan bokfört verifikatet.",
    UpdateContactInformation_action: "Uppdatera kontaktuppgifter",
    UpdateStatus: "Uppdatera status",
    UsedUnderskott: "Använt underskott",
    UtdelningsSupport: "Utdelnings support",
    VatNotCompletedWarningContent: "Innan du gör bokslut måste moms redovisas. Har du ofullständiga momsrapporter så se till att göra klart dem innan du fortsätter. Har du genom importerad eller manuell bokföring redan gjort detta (eller om du har momsfri verksamhet) kan du ignorera rapporterna i Bokio.\n\nVill du fortsätta?",
    VatNotCompletedWarningTitle: "Du har inte färdigställt alla momsrapporter för räkenskapsåret",
    VATReport: "Momsrapporter",
    WhatToDoNext_heading: "Vad du behöver göra härnäst",
    WhatToDoNext_Link: "https://www.bokio.se/hjalp/bokslut/bokslut/bokfora-foregaende-ars-resultat-i-aktiebolag-vid-nytt-rakenskapsar/",
    WhatToDoNextContent: "Nu när bokslutet och årsredovisningen är klar är det dags att bokföra föregående års eventuella utdelning. Läs mer hur du går till väga {här}. ",
    YearEndClosurePeriod: "Bokslutsperiod {0}",
    YearEndClosureServices: "Bokslutstjänster",
    YearEndReport_BankIdSignatureMessage: "Signera årsrapporten för \"{0}\" med organisationsnummer {1}",
    YearEndReportDescription: "Signera med BankID och lämna in digitalt till Bolagsverket",
    YearEndResult: "Årets resultat",
    YouChooseArsredovisningOnline: "Du har valt Årsredovisning Online(ÅO)",
    YouChooseManualClosure: "Du har valt manuellt bokslut",
    YourBalanceAssistance: "Om dina konton i huvudboken inte matchar din bokföring kan du vända dig till {hjälp-sektionen} för mer information läs mer.",
    YourFiles: "Dina dokument",
    YourFilesBeenEmailed: "Dina dokument har nu skickats till din e-post",
    YourFilesReady_heading: "Dina dokument är klara",
    YourResults: "Ditt resultat",
    YourYearlyTax: "Din årliga skatt",
  };
}